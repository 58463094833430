import { Sex } from 'Shared/types';

interface IUser {
    id: string;
    userName: string;
    name: string;
    surname: string;
    age: number;
    sex: Sex;
}

class User implements IUser {
    public static fromJsonObject(json: any) {
        return new User(json.id, json.userName, json.name, json.surname, json.age, Sex[json.sex] as Sex);
    }

    public id: string;
    public userName: string;
    public name: string;
    public surname: string;
    public age: number;
    public sex: Sex;

    constructor(id: string, userName: string, name: string, surname: string, age: number, sex: Sex) {
        this.id = id;
        this.userName = userName;
        this.name = name;
        this.surname = surname;
        this.age = age;
        this.sex = sex;
    }

    public getFullName() {
        if (this.name) {
            if (this.surname) {
                return `${this.name} ${this.surname}`;
            }
            return this.name;
        }

        return this.surname ? this.surname : '';
    }
}

export default User;
