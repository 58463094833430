import { sendWorker } from 'Src';
import { UploadType } from 'Src/shared/workers/SessionUpload';

export const getRecorderReactionUploadName = (stepId: string, uploadType: UploadType = UploadType.reaction) =>
    uploadType === UploadType.reaction ? `${stepId}-reaction` : `${stepId}-screenshare`;

export const initRecording = async (
    stepSessionId: string,
    scenarioSessionId: string,
    stepId: string,
    uploadType: UploadType = UploadType.reaction,
) => {
    await sendWorker.addUpload(
        { stepSessionId, scenarioSessionId, stepId },
        getReactionUploadName(stepId, uploadType),
        uploadType,
    );
};

const getReactionUploadName = (stepId: string, uploadType: UploadType = UploadType.reaction) => {
    return getRecorderReactionUploadName(stepId, uploadType);
};

export const handleWebcamDataAvailable = async (
    stepId: string,
    event: BlobEvent,
    uploadType: UploadType = UploadType.reaction,
) => {
    if (event.data.size <= 0) {
        return;
    }
    await sendWorker.recordBlob(getReactionUploadName(stepId, uploadType), event.data);
    return;
};

export const completeRecording = async (stepId: string, uploadType: UploadType = UploadType.reaction) => {
    return await sendWorker.completeRecording(getReactionUploadName(stepId, uploadType));
};
export const getMediaRecorder = (stream: MediaStream, handleRecorderDataAvailable: (...args: any[]) => any) => {
    const mimeType = 'video/webm;';
    const recorder = new MediaRecorder(stream, { mimeType });
    /* eslint-disable-next-line no-console */
    recorder.onerror = console.error;
    recorder.ondataavailable = handleRecorderDataAvailable;
    return {
        recorder,
        isWebcamReady: true,
    };
};

export const initScreenRecorder = (stream: MediaStream, handleRecorderDataAvailable: (...args: any[]) => any) => {
    const mimeType = 'video/webm;';
    const recorder = new MediaRecorder(stream, { mimeType });
    /* eslint-disable-next-line no-console */
    recorder.onerror = console.error;
    recorder.ondataavailable = handleRecorderDataAvailable;
    return recorder;
};
