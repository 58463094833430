import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Button, Grid, MenuItem, Typography } from '@mui/material';
import { FinalFormInput, SelectInput } from 'Features/manager/shared/components/form-inputs';
import { FieldArray } from 'react-final-form-arrays';
import { ScreenerQuestion, ScreenerQuestionType } from 'Features/manager/shared/queries';

interface ScreenerQuestionEditorProps {
    currentQuestion: ScreenerQuestion | null;
    questionIdx?: number;
    onSave: (question: ScreenerQuestion) => void;
}

function getActions(type: ScreenerQuestionType) {
    if (type === ScreenerQuestionType.radioPickOneQuestion || type === ScreenerQuestionType.pickOneQuestion) {
        return ['qualify', 'disqualify'];
    }

    if (type === ScreenerQuestionType.pickManyQuestion) {
        return ['maySelect', 'mustSelect', 'mustSelectAtLeastOne', 'disqualify'];
    }

    return [];
}

const ScreenerQuestionEditor = ({ currentQuestion, questionIdx, onSave }: ScreenerQuestionEditorProps) => {
    const [question, setQuestion] = useState<ScreenerQuestion | null>(
        currentQuestion || {
            id: '',
            position: 0,
            isOptionsRandomized: false,
            type: ScreenerQuestionType.radioPickOneQuestion,
            text: '',
            options: [],
        },
    );

    useEffect(() => {
        setQuestion(
            currentQuestion || {
                id: '',
                position: 0,
                isOptionsRandomized: false,
                type: ScreenerQuestionType.radioPickOneQuestion,
                text: '',
                options: [],
            },
        );
    }, [currentQuestion, questionIdx]);

    if (!question) {
        return <div>Loading...</div>;
    }
    return (
        <Form
            key={questionIdx}
            onSubmit={(values) => {
                setQuestion({
                    id: '',
                    position: 0,
                    isOptionsRandomized: false,
                    type: ScreenerQuestionType.radioPickOneQuestion,
                    text: '',
                    options: [],
                });
                typeof onSave === 'function' && onSave(values);
            }}
            initialValues={question}
            mutators={{ ...arrayMutators }}
            render={({
                handleSubmit,
                values,
                form: {
                    mutators: { push },
                },
            }) => (
                <form onSubmit={handleSubmit}>
                    <Typography variant="h4">Edit Question</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Field name="text" component={FinalFormInput} label="Question text" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <Field name="type" component={SelectInput} fullWidth>
                                <MenuItem value={ScreenerQuestionType.radioPickOneQuestion}>Single Choice</MenuItem>
                                <MenuItem value={ScreenerQuestionType.pickManyQuestion}>Multiple Choice</MenuItem>
                                <MenuItem value={ScreenerQuestionType.pickOneQuestion}>Dropdown</MenuItem>
                            </Field>
                        </Grid>
                        <FieldArray name="options">
                            {({ fields }) =>
                                fields.map((name, index) => (
                                    <Grid container item xs={12} spacing={2} key={index}>
                                        <Grid item xs={6}>
                                            <Field
                                                name={`${name}.text`}
                                                component={FinalFormInput}
                                                label="Option text"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Field name={`${name}.selectedAction`} component={SelectInput} fullWidth>
                                                {getActions(values.type).map((action) => (
                                                    <MenuItem key={action} value={action}>
                                                        {action}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button onClick={() => fields.remove(index)}>Delete</Button>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </FieldArray>
                        <Grid item xs={12}>
                            <Button
                                onClick={() =>
                                    push('options', {
                                        id: `${values.options.length + 1}`,
                                        position: `${values.options.length + 1}`,
                                        text: '',
                                        selectedAction: 'qualify',
                                    })
                                }
                            >
                                Add Option
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary">
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            )}
        />
    );
};

export default ScreenerQuestionEditor;
