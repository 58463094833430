import SparkMD5 from 'spark-md5';

export async function etag(blob: Blob) {
    return new Promise<string>((resolve, reject) => {
        const a = new FileReader();
        a.readAsArrayBuffer(blob);
        a.onloadend = function () {
            if (a.result) {
                resolve(SparkMD5.ArrayBuffer.hash(a.result as ArrayBuffer));
            } else {
                reject(a.result);
            }
        };
    });
}

export const addDomainName = (link: string) => `${window.location.origin}/${link}`;
