import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EditAgeQuota, { AgeQuota, AgeQuotaRule } from './components/EditAgeQuota';
import { AudienceBucket, managerMutations, managerQueries } from 'Features/manager/shared/queries';
import ManagerLayout from 'Pages/manager/ManagerLayout';
import EditGenderQuota, {
    GenderQuota,
    GenderQuotaRule,
} from 'Pages/manager/scenarios/ManagerEditQuotas/components/EditGenderQuota';
import EditQuestionQuota, {
    QuestionQuota,
} from 'Pages/manager/scenarios/ManagerEditQuotas/components/EditQuestionQuota';
import QuestionQuotasTable from 'Pages/manager/scenarios/ManagerEditQuotas/components/QuestionQuotasTable';
import Typography from '@mui/material/Typography';
import { Divider, Grid } from '@mui/material';

export type Quota = AgeQuota | GenderQuota | QuestionQuota;
const ManagerEditQuotasPage: React.FC = () => {
    const { bucketId } = useParams<{ bucketId: string }>();
    const { scenarioId } = useParams<{ scenarioId: string }>();
    const [questionQuotas, setQuestionQuotas] = useState<QuestionQuota[]>([]);
    const [editingQuota, setEditingQuota] = useState<QuestionQuota | undefined>(undefined);
    const [quotas, setQuotas] = useState<Quota[]>([]);
    const { mutateAsync: putQuotas } = managerMutations.usePutQuotas();
    const { data: screener } = managerQueries.useScreener(bucketId || '');
    const quotaQuestions =
        screener?.screenerQuestions.filter(
            (question) => question.type === 'radioPickOneQuestion' || question.type === 'pickOneQuestion',
        ) || [];
    const { data: audienceBucket, isLoading: isBucketLoading } = managerQueries.useAudienceBucket(bucketId || '', {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: 'always',
        onSuccess: (data) => {
            setQuotas(data.quotas);
            setQuestionQuotas(data.quotas.filter((q) => q.type === 'byScreeningQuestion') as QuestionQuota[]);
            return data;
        },
    });

    const handleSaveAgeQuota = (quota: AgeQuota) => {
        const updatedQuotas = quotas.filter((q) => q.type !== 'byAge');
        setQuotas([...updatedQuotas, quota]);

        if (bucketId) putQuotas({ urlParams: { bucketId }, body: { quotas: [...updatedQuotas, quota] } });
    };

    const handleSaveGenderQuota = (quota: GenderQuota) => {
        const updatedQuotas = quotas.filter((q) => q.type !== 'byGender');
        setQuotas([...updatedQuotas, quota]);

        if (bucketId) putQuotas({ urlParams: { bucketId }, body: { quotas: [...updatedQuotas, quota] } });
    };

    const handleSaveQuestionsQuota = (quota: QuestionQuota) => {
        const newQuestionQuotas = quotas.filter(
            (q) => q.type == 'byScreeningQuestion' && q.questionId !== quota.questionId,
        ) as QuestionQuota[];
        const newQuotas = quotas.filter((q) =>
            q.type == 'byScreeningQuestion' ? q.questionId !== quota.questionId : true,
        );
        setQuestionQuotas([...newQuestionQuotas, quota]);
        setEditingQuota(undefined);
        setQuotas([...newQuotas, quota]);

        if (bucketId) putQuotas({ urlParams: { bucketId }, body: { quotas: [...newQuotas, quota] } });
    };

    const handleEditQuota = (quota: QuestionQuota) => {
        setEditingQuota(quota);
    };

    if (isBucketLoading) {
        return <div>Loading...</div>;
    }

    if (!audienceBucket) {
        return <div>No bucket</div>;
    }

    return (
        <ManagerLayout>
            <div>
                <Typography variant="h4">Quotas for audience bucket - {audienceBucket.name}</Typography>
                <Divider variant="middle" color="white" sx={{ margin: '16px' }} />
                <Grid spacing={2} container>
                    <Grid item xs={12}>
                        <EditAgeQuota
                            initialQuota={quotas.find((quota) => quota.type === 'byAge') as AgeQuota}
                            onSave={handleSaveAgeQuota}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <EditGenderQuota
                            initialQuota={quotas.find((quota) => quota.type === 'byGender') as GenderQuota}
                            onSave={handleSaveGenderQuota}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <QuestionQuotasTable
                            quotas={questionQuotas}
                            questions={quotaQuestions}
                            onEdit={handleEditQuota}
                            onDelete={(quota) => {
                                const newQuestionQuotas = quotas.filter(
                                    (q) => q.type == 'byScreeningQuestion' && q.questionId !== quota.questionId,
                                ) as QuestionQuota[];
                                const newQuotas = quotas.filter((q) =>
                                    q.type == 'byScreeningQuestion' ? q.questionId !== quota.questionId : true,
                                );
                                setQuestionQuotas([...newQuestionQuotas]);
                                setEditingQuota(undefined);
                                setQuotas([...newQuotas]);

                                if (bucketId) putQuotas({ urlParams: { bucketId }, body: { quotas: [...newQuotas] } });
                            }}
                        />
                        <EditQuestionQuota
                            onSave={handleSaveQuestionsQuota}
                            questions={quotaQuestions}
                            currentQuota={editingQuota}
                            existingQuotas={
                                quotas.filter((quota) => quota.type === 'byScreeningQuestion') as QuestionQuota[]
                            }
                        />
                    </Grid>
                </Grid>
            </div>
        </ManagerLayout>
    );
};

export default ManagerEditQuotasPage;
