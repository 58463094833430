import React, { FC } from 'react';
import { ProgressWrapper, StyledProgress } from './LinearProgress.styles';
import { ILinearProgressProps } from './LinearProgress.types';
import { Typography } from '@watched-tech/wantent-ui';

export const LinearProgress: FC<ILinearProgressProps> = ({ value, name, sx }) => {
    return (
        <ProgressWrapper sx={sx?.wrapper}>
            <Typography variant="subtitle" color="textPrimary" sx={{ gridArea: 'name', ...sx?.name }}>
                {name}
            </Typography>
            <Typography
                variant="subtitle"
                color="textPrimary"
                sx={{ gridArea: 'percents', textAlign: 'end', ...sx?.percents }}
            >
                {value}%
            </Typography>
            <StyledProgress variant="determinate" value={value} sx={sx?.progress} />
        </ProgressWrapper>
    );
};
