import { createRootReducer } from './reducers';
import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware, compose, Store } from 'redux';
import { History } from 'history';

export const configureStore = (history: History<any>, preloadedState?: any): Store => {
    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        createRootReducer(history),
        preloadedState,
        composeEnhancers(applyMiddleware(thunkMiddleware)),
    );

    return store;
};
