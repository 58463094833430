import { styled } from '@mui/material/styles';
import { getScrollbarStyles } from '@watched-tech/wantent-ui';

export const DesktopBackground = styled('div')(({ theme }) => ({
    height: '100%',
    background: theme.palette.gradient.background,
    color: theme.palette.common.white,
}));

export const FixedHeightContent = styled('div')(({ theme }) => ({
    height: `calc(100% - ${theme.headerHeights.l}px - ${theme.footerHeights.l}px)`,
    [theme.breakpoints.down('lg')]: {
        height: `calc(100% - ${theme.headerHeights.m}px - ${theme.footerHeights.m}px)`,
    },
    [theme.breakpoints.down('md')]: {
        height: `calc(100% - ${theme.headerHeights.m}px - ${theme.footerHeights.mobile}px)`,
        padding: `0 ${theme.spacing(12)}`,
        overflow: 'auto',
        ...getScrollbarStyles(theme, 'transparent', theme.palette.gray.x7),
    },
}));

export const MobileBackground = styled('div')(({ theme }) => ({
    width: '100%',
    minHeight: '100%',
    boxSizing: 'border-box',
    background: theme.palette.gradient.background,
    display: 'flex',
    flexDirection: 'column',
}));

export const Container = styled('div')(({ theme }) => ({
    padding: `${theme.spacing(8)} ${theme.spacing(12)}`,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    boxSizing: 'border-box',
    [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
        paddingBottom: 48,
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
        paddingBottom: 16,
    },
}));

export const MobileImageContainer = styled('div')(({ theme }) => ({
    margin: 'auto',
    [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
        marginTop: 36,
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
        marginTop: 4,
    },
}));

const imageHeights = {
    xl: 482,
    lg: 292,
    md: 420,
    sm: 262,
};

export const Image = styled('img')<{ showMobileView: boolean }>(({ theme, showMobileView }) => ({
    margin: 'auto',
    height: 482,
    [theme.breakpoints.down('lg')]: {
        height: showMobileView ? imageHeights.md : 292,
    },
    [theme.breakpoints.down('md')]: {
        height: 420,
    },
    [theme.breakpoints.down('sm')]: {
        height: 262,
    },
}));

export const DesktopImageWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    background: theme.palette.gray.x1,
    borderRadius: theme.spacing(8),
}));
