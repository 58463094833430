import { Button, Modal, Typography } from '@watched-tech/wantent-ui';
import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { REGISTRATION_MODAL_WIDTH } from 'Features/participant/register/constants';
import * as Sentry from '@sentry/react';
import { ISurveyDisclaimerButtonsTranslation } from './types';
import { setSurveyDisclaimerAccepted, getSurveyDisclaimerAccepted } from 'Features/participant/register/utils';
import OnlyHeaderLayout from 'Features/participant/shared/components/layouts/OnlyHeaderLayout';
import { useAuthContext } from 'Shared/auth/context';

const getSurveyDisclaimerButtons = (
    onSuccess: () => void,
    onLogout: () => void,
    translations: ISurveyDisclaimerButtonsTranslation,
) => {
    return (
        <>
            <Button variant="outlined" color="primary" onClick={onLogout} data-testid="leave-survey">
                {translations.leave}
            </Button>
            <Button variant="contained" color="primary" onClick={onSuccess} data-testid={'proceed-to-survey'}>
                {translations.success}
            </Button>
        </>
    );
};

export default function SurveyDisclaimer() {
    const navigate = useNavigate();
    const { t: getLabel } = useTranslation();
    const isSurveyDisclaimerAccepted = getSurveyDisclaimerAccepted();
    const auth = useAuthContext();

    useEffect(() => {
        if (isSurveyDisclaimerAccepted === 'true') {
            navigate('/me');
        }
    }, [isSurveyDisclaimerAccepted, navigate]);

    const handleLogoutClick = useCallback(() => {
        setSurveyDisclaimerAccepted('false');
        auth.logout('/');
        Sentry.setUser(null);
    }, [navigate]);

    const goToDashboard = () => {
        setSurveyDisclaimerAccepted('true');
        navigate('/me');
    };
    return (
        <OnlyHeaderLayout>
            <Modal
                open
                onClose={() => {}}
                title={getLabel('participant.surveyDisclaimer.title')}
                width={REGISTRATION_MODAL_WIDTH}
                withCloseIcon={false}
                buttons={getSurveyDisclaimerButtons(goToDashboard, handleLogoutClick, {
                    leave: getLabel('participant.surveyDisclaimer.leave'),
                    success: getLabel('participant.surveyDisclaimer.proceedToSurvey'),
                })}
                scroll="paper"
                sx={{
                    modal: {
                        '& .MuiBackdrop-root': {
                            backgroundColor: 'transparent',
                            backdropFilter: 'none',
                        },
                    },
                }}
            >
                <Typography variant="p1">{getLabel('participant.surveyDisclaimer.description')}</Typography>
            </Modal>
        </OnlyHeaderLayout>
    );
}
