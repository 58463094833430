import React from 'react';
import { FormControlLabel, Checkbox, FormControl } from '@mui/material';
import { FieldRenderProps } from 'react-final-form';

export function CheckboxGroupInput(
    props: FieldRenderProps<string> & { label: string; options: { name: string; id: any; defaultChecked: boolean }[] },
) {
    const { input, options, imageUrl } = props;

    return (
        <FormControl sx={{ paddingLeft: '40px' }} component="fieldset" fullWidth>
            {imageUrl && <img src={imageUrl} alt="checkbox" style={{ marginBottom: '20px' }} />}
            {options?.map((option, index) => (
                <FormControlLabel
                    key={index}
                    control={
                        <Checkbox
                            name={`${input.name}[${index}]`}
                            sx={{
                                padding: '6px',
                            }}
                            defaultChecked={option.defaultChecked}
                            value={option.name}
                            checked={input.value.indexOf(option.name) !== -1}
                            onChange={(event) => {
                                const eventValue = event.target.value;
                                let newValue = [...input.value];
                                if (event.target.checked) {
                                    newValue.push(option.name);
                                } else {
                                    const start = newValue.slice(0, newValue.indexOf(eventValue));
                                    const end = newValue.slice(newValue.indexOf(eventValue) + 1, newValue.length);
                                    newValue = [...start, ...end];
                                }
                                return input.onChange(newValue);
                            }}
                        />
                    }
                    label={option.name}
                />
            ))}
        </FormControl>
    );
}
