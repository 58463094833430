import { Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getScrollbarStyles } from '@watched-tech/wantent-ui';
import { ReactComponent as Close } from 'Assets/svg/close.svg';

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
        width: '100vw',
        height: '100%',
        display: 'block',
        boxSizing: 'border-box',
        background: theme.palette.gradient.background,
    },
}));

export const StyledClose = styled(Close)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(12),
    right: theme.spacing(12),
    width: 24,
    height: 24,
    cursor: 'pointer',
}));

export const DrawerContent = styled('div', {
    shouldForwardProp: (prop: string) => !['withFooter', 'isMobile'].includes(prop),
})<{ withFooter: boolean; isMobile: boolean }>(({ theme, withFooter, isMobile }) => ({
    position: 'relative',
    height: withFooter ? `calc(100% - ${theme.footerHeights.l}px)` : '100%',
    padding: theme.spacing(12),
    boxSizing: 'border-box',
    overflow: 'auto',
    [theme.breakpoints.down('lg')]: {
        height: !withFooter
            ? '100%'
            : isMobile
            ? `calc(100% - ${theme.footerHeights.mobile}px)`
            : `calc(100% - ${theme.footerHeights.m}px)`,
    },
    [theme.breakpoints.down('md')]: {
        height: withFooter ? `calc(100% - ${theme.footerHeights.mobile}px)` : '100%',
    },
    ...getScrollbarStyles(theme, 'transparent', theme.palette.gray.x7),
}));
