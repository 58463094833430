import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Empty } from 'Assets/svg/empty.svg';
import { EmptyStateLayout } from 'Features/participant/workspace/dashboard/components/shared/EmptyStateLayout';

export const NoActiveScenarios = () => {
    const { t: getLabel } = useTranslation();
    return (
        <EmptyStateLayout
            Icon={Empty}
            title={getLabel('participant.scenarios.noActiveResults')}
            description={getLabel('participant.scenarios.contactAdmin')}
        />
    );
};
