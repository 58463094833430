import { apiClient } from './axios';
import { AxiosRequestConfig, Method } from 'axios';
import { QueryFunction, QueryKey } from 'react-query';

type RequestName = string;
export type StringQueryKey<TRequestParams> = readonly [RequestName, TRequestParams] | readonly [RequestName];

type UrlMapperParams = { queryKey: QueryKey };

export interface IQueryCallbackProps<TRequestParams, TResponse> {
    url: UrlMapperFn | string;
    dataMapper?: (res: any, params?: UrlMapperParams) => TResponse;
    method?: Method;
    config?: AxiosRequestConfig;
}

type UrlMapperFn = (params: any) => string;

export interface IMutationCallbackProps<TResponse, TConfig = AxiosRequestConfig> {
    url: UrlMapperFn | string;
    method: Method;
    dataMapper?: (res: any, params?: any) => TResponse;
    config?: TConfig;
}
export interface IMutationRequestParams<TRequestUrlParams, TRequestBodyParams> {
    urlParams?: TRequestUrlParams;
    body?: TRequestBodyParams;
}

export const getQueryCallback = <TRequestParams, TResponse>({
    url,
    dataMapper,
    config,
    method = 'GET',
}: IQueryCallbackProps<TRequestParams, TResponse>): QueryFunction<TResponse, QueryKey> => {
    return async (params) => {
        const requestUrl = typeof url === 'string' ? url : url(params);
        const res = await apiClient(requestUrl, { method, ...config });
        if (!res.headers['content-type'] || res.headers['content-type'].includes('application/json')) {
            return typeof dataMapper === 'function' ? dataMapper(res.data.data, params) : res.data.data;
        }
        return typeof dataMapper === 'function' ? dataMapper(res.data, params) : res.data;
    };
};

export const getMutationCallback =
    <TRequestUrlParams, TRequestBodyParams, TResponse>({
        url,
        method,
        dataMapper,
        config,
    }: IMutationCallbackProps<TResponse>) =>
    async (params: IMutationRequestParams<TRequestUrlParams, TRequestBodyParams>): Promise<TResponse> => {
        const requestUrl = typeof url === 'string' ? url : url(params.urlParams);
        const res = await apiClient(requestUrl, { method, ...config, data: params.body });
        if (!res.headers['content-type'] || res.headers['content-type'].includes('application/json')) {
            if (!res.data.data) {
                console.warn('Data key is missing in the response');
            }
            return typeof dataMapper === 'function' ? dataMapper(res.data.data, params) : res.data.data;
        }
        return typeof dataMapper === 'function' ? dataMapper(res.data, params) : res.data;
    };
