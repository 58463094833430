import React, { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Rating } from '@watched-tech/wantent-ui';
import { DataAttrs } from '@watched-tech/wantent-ui/utils';

interface IParticipantFinalFormRatingProps {
    max?: number;
    error?: string;
    id: string;
    showMobileView?: boolean;
    dataAttrs?: DataAttrs;
}

export const ParticipantFinalFormRating: FC<FieldRenderProps<string> & IParticipantFinalFormRatingProps> = ({
    input,
    max,
    error,
    id,
    showMobileView = false,
    sx,
    dataAttrs,
}) => {
    const value = input.value ? input.value : '0';
    return (
        <Rating
            id={id}
            value={value}
            onChange={input.onChange}
            onBlur={input.onBlur}
            onFocus={input.onFocus}
            error={error}
            max={max}
            isMobile={showMobileView}
            sx={sx}
            dataAttrs={dataAttrs}
        />
    );
};
