import { styled, Theme } from '@mui/material/styles';
import { scenarioCardWidth } from 'Features/participant/workspace/dashboard/constants/index';

const filterBarHeight = 80;
const mobileFilterBarHeight = 40;

// we have dynamic paddings in ScenariosList page and fixed width for desktops
// that is 3 * scenarioCardWidth for bigger desktops and 2 * scenarioCardWidth for smaller desktops
// height is 100% - filters bar height - header height

const getHeight = (theme: Theme, height: number) => ({
    xl: `calc(100vh - ${theme.headerHeights.l}px - ${filterBarHeight}px)`,
    lg: `calc(100vh - ${theme.headerHeights.m}px - ${filterBarHeight}px)`,
    mobile: `calc(${height}px - ${mobileFilterBarHeight}px - ${theme.headerHeights.m}px)`,
});
export const Container = styled('div', {
    shouldForwardProp: (prop: string) => !['isMobile', 'height'].includes(prop),
})<{ height: number; isMobile: boolean }>(({ theme, height, isMobile }) => {
    const heights = getHeight(theme, height);
    return {
        width: `${scenarioCardWidth * 3}px`,
        height: heights.xl,
        [theme.breakpoints.down('lg')]: {
            width: isMobile ? '100%' : `${scenarioCardWidth * 2}px`,
            height: isMobile ? heights.mobile : heights.lg,
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: heights.mobile,
        },
    };
});
export const FlexContainer = styled('div')({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

export const IconWrapper = styled('div')(({ theme }) => ({
    marginRight: theme.spacing(8),
    width: theme.spacing(20),
    height: theme.spacing(20),
    '& >svg': {
        color: theme.palette.common.white,
    },
}));

export const Div = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        width: 216,
    },
}));
