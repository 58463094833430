import { NavigateFunction } from 'react-router-dom';
import { AxiosRequestConfig } from 'axios';
import { apiClient } from 'Utils/axios';
import { history, sendWorker } from 'Src';
import * as Sentry from '@sentry/react';
import { useRefreshToken } from 'Utils/axios';
import { AuthStrategy, getJwtPayload, ManagementRoles, Roles, setAuthScheme } from 'Shared/auth/common';
import { AuthContextType } from 'Shared/auth/context';

export const ROLE_KEY = 'role';
export const ID_KEY = 'sub';

const isTokenExpired = (token: any) => {
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return token.exp < currentTime;
};

export const getCurrentUser = () => {
    const token = getAuthToken();
    if (!token) {
        return { role: undefined, userId: undefined };
    }
    const payload = getJwtPayload(token);
    return { role: payload[ROLE_KEY], userId: payload['sub'] };
};

export const getAuthToken = () => localStorage.getItem('WatchedJwtAuthToken') || '';
export const getAuthTokenSilently = async () => {
    const token = getAuthToken();
    const payload = getJwtPayload(token);
    if (isTokenExpired(payload)) {
        await refreshWntToken();
        return getAuthToken();
    }

    return token;
};
export const setAuthToken = (accessToken: string) => {
    localStorage.setItem('WatchedJwtAuthToken', accessToken);
};

export const getRefreshToken = () => localStorage.getItem('WatchedJwtRefreshToken') || '';
export const setRefreshToken = (refreshToken: string) => {
    localStorage.setItem('WatchedJwtRefreshToken', refreshToken);
};
export const removeAuthToken = () => localStorage.removeItem('WatchedJwtAuthToken');
export const removeRefreshToken = () => localStorage.removeItem('WatchedJwtRefreshToken');

export const signInWntUserWithTokens = (accessToken: string, refreshToken: string, auth: AuthContextType) => {
    setAuthToken(accessToken);
    setRefreshToken(refreshToken);
    const { sub } = getJwtPayload(accessToken);
    Sentry.setUser({ id: sub });
    auth.updateScheme('wnt');
};

export const refreshWntToken = async () => {
    const refreshToken = getRefreshToken();
    /* eslint-disable react-hooks/rules-of-hooks */
    const res = await useRefreshToken(refreshToken);
    const { accessToken, refreshToken: refresh } = res.data.data;
    setAuthToken(accessToken);
    setRefreshToken(refresh);
};

export class WntStrategy implements AuthStrategy {
    public role?: keyof typeof Roles;
    public userId?: string;
    public isLoading: boolean;

    constructor(private navigate: NavigateFunction) {
        const { role, userId } = getCurrentUser();
        this.role = role;
        this.userId = userId;
        this.isLoading = false;
    }

    getToken = async () => {
        return await getAuthTokenSilently();
    };

    login = (loc: string) => {
        // Implement Wnt-specific login logic here
    };

    logout = (loc: string) => {
        removeAuthToken();
        removeRefreshToken();
        setAuthScheme(undefined);
        this.navigate(loc);
    };

    refreshTokenAndUpdateWorker = () => {
        refreshWntToken().then(() => {
            const token = getAuthToken();
            if (token) {
                const { sub } = getJwtPayload(token);
                sub && Sentry.setUser({ id: sub });
                sendWorker.updateAccessToken(token);
            }
        });
    };
}
