import { styled } from '@mui/material/styles';
import { scenarioCardWidth } from 'Features/participant/workspace/dashboard/constants';

export const Filters = styled('div', {
    shouldForwardProp: (prop) => prop !== 'showMobileView',
})<{ showMobileView: boolean }>(({ theme, showMobileView }) => ({
    paddingTop: theme.spacing(20),
    display: 'flex',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    [`${theme.breakpoints.down('lg')}`]: {
        paddingTop: showMobileView ? theme.spacing(8) : theme.spacing(20),
        justifyContent: showMobileView ? 'flex-end' : 'space-between',
    },
    [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(8),
        justifyContent: 'flex-end',
    },
}));

// width should be max-content to make the filter bar(Filters) width same as grid container's width
export const FlexContainer = styled('div')<{ showMobileView: boolean }>(({ theme, showMobileView }) => ({
    margin: 'auto',
    width: 'max-content',
    minHeight: `calc(100% - ${theme.headerHeights.l}px)`,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
        width: showMobileView ? 'auto' : 'max-content',
        minHeight: `calc(100% - ${theme.headerHeights.m}px)`,
        padding: showMobileView ? `0 ${theme.spacing(12)}` : 0,
    },
    [theme.breakpoints.down('md')]: {
        width: 'auto',
        padding: `0 ${theme.spacing(12)}`,
    },
}));

// we have dynamic paddings in ScenariosList page and fixed width for desktops
export const GridContainer = styled('div')<{ showMobileView: boolean }>(({ theme, showMobileView }) => ({
    padding: `${theme.spacing(12)} 0 ${theme.spacing(24)}`,
    display: 'grid',
    justifyContent: 'center',
    gridGap: theme.spacing(16),
    gridTemplateColumns: `repeat(3, minmax(auto, ${scenarioCardWidth}px))`,
    boxSizing: 'border-box',
    [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: showMobileView ? 'repeat(2, 1fr)' : `repeat(2, minmax(auto, ${scenarioCardWidth}px))`,
    },
    [theme.breakpoints.down('md')]: {
        width: 'auto',
        padding: `${theme.spacing(8)} 0 ${theme.spacing(24)}`,
    },
    [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
    },
}));
