import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Snackbar, useMediaQuery } from '@mui/material';
import { Typography, Input, Button, useWantentTheme } from '@watched-tech/wantent-ui';
import { Background, MobileImg, TabletDesktopImg, TabletDesktopBlock } from './styles';
import { getEventId } from 'Features/participant/slice';
import { ReactComponent as Copy } from 'Assets/svg/copy.svg';
import { useTranslation } from 'react-i18next';

interface ErrorFallbackProps {
    message?: string;
}
export const ParticipantScenarioErrorFallback = (props: ErrorFallbackProps) => {
    const [isEventIdCopied, setIsEventIdCopied] = useState(false);
    const eventId = useSelector(getEventId);
    const { t: getLabel } = useTranslation();
    const { spacing, breakpoints } = useWantentTheme();
    const matchesMd = useMediaQuery(breakpoints.down('md'));
    const matchesSm = useMediaQuery(breakpoints.down('sm'));
    const handleClick = () => {
        window.location.reload();
    };

    const onCopyNotificationClose = useCallback(() => {
        setIsEventIdCopied(false);
    }, [setIsEventIdCopied]);

    const onIconClick = () => {
        navigator.clipboard.writeText(eventId).then(() => {
            setIsEventIdCopied(true);
        });
    };

    const content = (
        <TabletDesktopBlock>
            <Typography variant={matchesMd ? 'h5' : 'h4'} color="text.primary" sx={{ marginBottom: spacing(4) }}>
                {props.message || getLabel('participant.error.issues')}
            </Typography>
            <Typography variant={matchesMd ? 'p2' : 'p1'} color="text.primary">
                {getLabel('participant.error.refresh')}
            </Typography>
            <Typography variant={matchesMd ? 'p2' : 'p1'} color="text.primary">
                {getLabel('participant.error.contactSupport')}
            </Typography>
            <Input
                value={eventId}
                label={'Event id'}
                onChange={() => {}}
                name="event-id"
                id="event-id"
                Icon={Copy}
                onIconClick={onIconClick}
                sx={{
                    container: { margin: `${spacing(16)} 0` },
                }}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleClick}
                sx={{
                    alignSelf: 'center',
                }}
            >
                {getLabel('participant.error.refreshBtnText')}
            </Button>
        </TabletDesktopBlock>
    );

    return (
        <>
            <Background>
                {matchesSm ? (
                    <>
                        <MobileImg />
                        {content}
                    </>
                ) : (
                    <>
                        <TabletDesktopBlock>{content}</TabletDesktopBlock>
                        <TabletDesktopBlock>
                            <TabletDesktopImg />
                        </TabletDesktopBlock>
                    </>
                )}
            </Background>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={isEventIdCopied}
                autoHideDuration={4000}
                onClose={onCopyNotificationClose}
                message={getLabel('participant.error.idCopied')}
            />
        </>
    );
};
