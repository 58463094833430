import { tooCloseCoefficient, Directions } from '../constants';

interface ICoords {
    x: number;
    y: number;
}

export const getDistance = (current: ICoords, next: ICoords) => {
    return {
        x: Math.abs(current.x - next.x),
        y: Math.abs(current.y - next.y),
    };
};

export const getDirection = (current: ICoords, next: ICoords) => {
    if (current.x < next.x && current.y === next.y) {
        return Directions.Right;
    }
    if (current.x > next.x && current.y === next.y) {
        return Directions.Left;
    }
    if (current.x === next.x && current.y < next.y) {
        return Directions.Bottom;
    }
    if (current.x === next.x && current.y > next.y) {
        return Directions.Top;
    }
    if (current.x < next.x && current.y < next.y) {
        return Directions.BottomRight;
    }
    if (current.x > next.x && current.y < next.y) {
        return Directions.BottomLeft;
    }
    if (current.x > next.x && current.y > next.y) {
        return Directions.TopLeft;
    }
    if (current.x < next.x && current.y > next.y) {
        return Directions.TopRight;
    }
    return '';
};

export const getDirectionSign = (direction: string): { x: number; y: number } => {
    switch (direction) {
        case Directions.Right:
        case Directions.Bottom:
        case Directions.BottomRight:
        default:
            return {
                x: 1,
                y: 1,
            };
        case Directions.Left:
        case Directions.BottomLeft:
            return {
                x: -1,
                y: 1,
            };
        case Directions.Top:
        case Directions.TopRight:
            return {
                x: 1,
                y: -1,
            };
        case Directions.TopLeft:
            return {
                x: -1,
                y: -1,
            };
    }
};

export const getRandomInRange = (min: number, max: number) => Math.round(Math.random() * (max - min) + min);

const getHypotenuse = (leg1: number, leg2: number) => Math.sqrt(Math.pow(leg1, 2) + Math.pow(leg2, 2));

interface GetPseudoRandomPointsReturnType {
    currentPointX: number;
    currentPointY: number;
}

export const getPseudoRandomPoints = (
    width: number,
    height: number,
    padding: number,
    coeff: number,
    occupiedCoords: ICoords[],
): GetPseudoRandomPointsReturnType => {
    const currentPointX = getRandomInRange(padding, width - padding);
    const currentPointY = getRandomInRange(padding, height - padding);
    let isInOccupiedZone = false;

    occupiedCoords.forEach(({ x, y }) => {
        if (getHypotenuse(Math.abs(currentPointX - x), Math.abs(currentPointY - y)) < height * coeff) {
            isInOccupiedZone = true;
        }
    });

    return isInOccupiedZone
        ? getPseudoRandomPoints(width, height, padding, coeff, occupiedCoords)
        : { currentPointX, currentPointY };
};

export const getRandomPoints = (
    count: number,
    width: number,
    height: number,
    padding: number,
    tooCloseCoefficient: number,
) => {
    const coords = [];
    const occupiedCoords = [];

    for (let i = 0; i < count; i++) {
        const prevCoords = coords[i - 1];
        let randomX;
        let randomY;

        if (prevCoords) {
            const { currentPointX, currentPointY } = getPseudoRandomPoints(
                width,
                height,
                padding,
                tooCloseCoefficient,
                occupiedCoords,
            );
            randomX = currentPointX;
            randomY = currentPointY;

            occupiedCoords.push({
                x: randomX,
                y: randomY,
            });
        } else {
            randomX = getRandomInRange(padding, width - padding);
            randomY = getRandomInRange(padding, height - padding);

            occupiedCoords.push({
                x: randomX,
                y: randomY,
            });
        }

        const point = {
            x: randomX,
            y: randomY,
        };

        coords.push(point);
    }
    return coords;
};

export const getCoords = (width: number, height: number, padding: number): { x: number; y: number }[] => [
    { x: width / 2, y: height / 2 }, //  center center

    { x: padding, y: padding }, // top left
    { x: width / 2, y: padding }, // top center
    { x: width - padding, y: padding }, // top right

    { x: padding, y: height / 2 }, // center left
    { x: width / 2, y: height / 2 }, // center center
    { x: width - padding, y: height / 2 }, // center right

    { x: padding, y: height - padding }, // bottom left
    { x: width / 2, y: height - padding }, // bottom center
    { x: width - padding, y: height - padding }, // bottom right,
    ...getRandomPoints(3, width, height, padding, tooCloseCoefficient),
];

export const fitToFullscreen = (
    canvasRef: React.RefObject<HTMLCanvasElement>,
    screen: { height: number; width: number },
) => {
    if (canvasRef.current) {
        canvasRef.current.width = screen.width;
        canvasRef.current.height = screen.height;
        return screen;
    }
    return { width: 0, height: 0 };
};
