import React, { useCallback, useMemo, useState } from 'react';
import { Modal, Typography, Stepper, Button } from '@watched-tech/wantent-ui';
import { Image, Source } from './styles';
import SoundOff from 'Assets/images/soundOff.png';
import CatMute from 'Assets/webp/instructionCatMute.webp';
import CatChrome from 'Assets/webp/instructionCatChrome.webp';
import CatFrame from 'Assets/webp/instructionCatFrame.webp';
import Chrome from 'Assets/images/chrome.png';
import RoomFrameRules from 'Assets/images/roomFrameRules.png';
import { TFunction, useTranslation } from 'react-i18next';
import { setIsScenarioInstructionsShown } from 'Features/participant/workspace/scenario/utils';
import { useAuthContext } from 'Shared/auth/context';

const getSteps = (getLabel: TFunction<'translation'>) => [
    {
        text: getLabel('participant.instructions.soundOff'),
        webpImage: CatMute,
        image: SoundOff,
        alt: 'Sound off',
    },
    {
        text: getLabel('participant.instructions.useChrome'),
        webpImage: CatChrome,
        image: Chrome,
        alt: 'Use chrome',
    },
    {
        text: getLabel('participant.instructions.roomFrameRules'),
        webpImage: CatFrame,
        image: RoomFrameRules,
        alt: 'Room and frame rules',
    },
];

export const Instructions = ({
    scenarioId,
    opened,
    setOpened,
    onFinish,
}: {
    scenarioId: string;
    opened: boolean;
    setOpened: (opened: boolean) => void;
    onFinish: () => void;
}) => {
    const { t: getLabel } = useTranslation();
    const [currentStep, setCurrentStep] = useState(0);
    const steps = useMemo(() => getSteps(getLabel), [getLabel]);
    const { userId } = useAuthContext();
    const onNext = useCallback(() => {
        if (currentStep + 1 < steps.length) {
            setCurrentStep(currentStep + 1);
        }
        if (currentStep + 1 === steps.length) {
            setIsScenarioInstructionsShown(scenarioId, userId || '');
            setOpened(false);
            onFinish();
        }
    }, [currentStep, steps.length, scenarioId, setOpened, onFinish, userId]);
    const onPrev = useCallback(() => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    }, [currentStep]);
    const nextBtnTestId = `instructions-button-step-${currentStep}`;

    const buttons = (
        <>
            <Button
                color="primary"
                variant="outlined"
                fullWidth={false}
                sx={{ display: currentStep === 0 ? 'none' : 'block' }}
                onClick={onPrev}
            >
                {getLabel('participant.instructions.back')}
            </Button>
            <Button
                dataAttrs={{
                    'data-testid': nextBtnTestId,
                }}
                color="primary"
                variant="contained"
                onClick={onNext}
                sx={{ width: 'max-content' }}
            >
                {getLabel(`participant.instructions.${currentStep + 1 < steps.length ? 'nextStep' : 'finish'}`)}
            </Button>
        </>
    );

    return (
        <Modal title={getLabel('participant.instructions.title')} open={opened} buttons={buttons} withCloseIcon={false}>
            <div>
                <Typography variant="p1" color="text.primary">
                    {steps[currentStep].text}
                </Typography>
                <picture>
                    <Source type="image/webp" srcSet={steps[currentStep].webpImage} />
                    <Image src={steps[currentStep].image} alt={steps[currentStep].alt} />
                </picture>
                <Stepper stepsCount={steps.length} activeStep={currentStep} />
            </div>
        </Modal>
    );
};
