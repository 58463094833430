import React, { ReactNode } from 'react';
import { Background } from './styles';
import ParticipantHeader from 'Features/participant/shared/components/Header';

// layout with only header
// min-height is 100%
// has header on mobile devices
interface IOnlyHeaderLayoutProps {
    children: ReactNode;
    isAuthenticated?: boolean;
}
const OnlyHeaderLayout = ({ children, isAuthenticated = true }: IOnlyHeaderLayoutProps) => {
    return (
        <Background>
            <ParticipantHeader isAuthenticated={isAuthenticated} />
            {children}
        </Background>
    );
};

export default OnlyHeaderLayout;
