import { addMinutes, subMinutes } from 'date-fns';

function pad(num: number) {
    return ('0' + num).slice(-2);
}

export function hhmmss(seconds: number, fullPad?: boolean) {
    let minutes = Math.floor(seconds / 60);

    seconds = Math.round(seconds % 60);
    if (seconds === 60) {
        minutes += 1;
        seconds = 0;
    }
    const hours = Math.floor(minutes / 60);
    minutes = Math.round(minutes % 60);
    if (!fullPad && hours <= 0) {
        return `${pad(minutes)}:${pad(seconds)}`;
    }
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
}

export function toUTC(date: Date) {
    const offset = date.getTimezoneOffset();
    return Math.sign(offset) !== -1 ? addMinutes(date, offset) : subMinutes(date, Math.abs(offset));
}

export const hhmmssms = (ms: number) => {
    const milliseconds: number = parseInt(`${ms % 1000}`);
    const seconds: number = parseInt(`${(ms / 1000) % 60}`);
    const minutes = parseInt(`${(ms / (1000 * 60)) % 60}`);
    const hours: number = parseInt(`${(ms / (1000 * 60 * 60)) % 24}`);
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}.${milliseconds}`;
};

export const convertUTCDateToLocalDate = (date: Date) =>
    new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);

export const removeDateMs = (isoString: string) => {
    const [dateString] = isoString.split('.');
    return [dateString, '000000'].join('.');
};
