import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { errorSnack } from 'Features/participant/shared/components/snackbars/Snackbar';
import { setSurveyStep } from 'Features/participant/workspace/scenario/slice';
import { useTranslation } from 'react-i18next';
import { participantQueries } from '../../queries';
import { ElementType, ISurveyElement } from 'Shared/types';
import { ScenarioPaper } from '../shared/ScenarioPaper';
import { infoSnack } from 'Shared/components';
import { SurveyStepPaper } from 'Pages/participant/workspace/scenario/SurveyStepPaper';

interface IParticipantSurveyProps {
    surveyId: string;
    onFinished?: () => void;
    recorderInfo?: string;
    recorderError?: string;
}

export const ParticipantSurveyPaper = ({
    surveyId,
    onFinished,
    recorderInfo,
    recorderError,
}: IParticipantSurveyProps) => {
    const dispatch = useDispatch();
    const { t: getLabel } = useTranslation();
    const [paperTitle, setPaperTitle] = useState<string>(getLabel('participant.survey.title'));
    const {
        data: participantSurvey,
        error: getParticipantSurveyError,
        isLoading: isParticipantSurveyLoading,
    } = participantQueries.useParticipantSurvey(surveyId, {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onSuccess: (survey) => {
            dispatch(setSurveyStep([...survey.surveyElements]));
        },
    });
    useEffect(() => {
        if (
            participantSurvey?.surveyElements?.every(
                (surveyElement: ISurveyElement) => surveyElement.type === ElementType.info,
            )
        ) {
            setPaperTitle(getLabel('participant.survey.info'));
        }
    }, [participantSurvey, getLabel]);

    if (!participantSurvey || getParticipantSurveyError) {
        return (
            <ScenarioPaper title={paperTitle}>
                {isParticipantSurveyLoading && <CircularProgress />}
                {recorderInfo && infoSnack(recorderInfo)}
                {recorderError && errorSnack(recorderError)}
                {getParticipantSurveyError instanceof Error && errorSnack(getParticipantSurveyError.message)}
            </ScenarioPaper>
        );
    }
    return (
        <SurveyStepPaper
            title={paperTitle}
            surveyElements={participantSurvey.surveyElements}
            onSubmitted={onFinished}
        />
    );
};
