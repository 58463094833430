import React, { FC, ElementType, SyntheticEvent, useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { SxProps, Theme } from '@mui/material';
import { Input } from '@watched-tech/wantent-ui';
import { DataAttrs } from '@watched-tech/wantent-ui/utils';

interface IParticipantFinalFormInputProps {
    id: string;
    label?: string;
    helperText?: string;
    error?: string;
    disabled?: boolean;
    placeholder?: string;
    ErrorIcon?: ElementType;
    PasswordIcon?: ElementType;
    PasswordVisibleIcon?: ElementType;
    onFocus?: (e: SyntheticEvent) => void;
    onBlur?: (e: SyntheticEvent) => void;
    sx?: {
        container?: SxProps<Theme>;
        label?: SxProps<Theme>;
        inputWrapper?: SxProps<Theme>;
        input?: SxProps<Theme>;
        iconWrapper?: SxProps<Theme>;
        error?: SxProps<Theme>;
        helperText?: SxProps<Theme>;
    };
    dataAttrs?: DataAttrs;
}

type InputType = 'text' | 'password';

export const ParticipantFinalFormInput: FC<FieldRenderProps<string> & IParticipantFinalFormInputProps> = ({
    input,
    meta,
    id,
    label,
    helperText,
    disabled = false,
    setFieldTouched,
    placeholder,
    PasswordIcon,
    PasswordVisibleIcon,
    ErrorIcon,
    onFocus,
    onBlur,
    sx,
    dataAttrs,
}) => {
    const onInputFocus = useCallback(
        (e: any) => {
            input.onFocus(e);
            typeof onFocus === 'function' && onFocus(e);
        },
        [onFocus, input],
    );
    const onInputBlur = useCallback(
        (e: any) => {
            input.onBlur(e);
            typeof onBlur === 'function' && onBlur(e);
        },
        [input, onBlur],
    );
    return (
        <Input
            value={input.value}
            onChange={input.onChange}
            onBlur={onInputBlur}
            onFocus={onInputFocus}
            type={input.type as InputType}
            label={label}
            helperText={helperText}
            disabled={disabled}
            placeholder={placeholder}
            setFieldTouched={setFieldTouched}
            name={input.name}
            id={id}
            error={meta.touched && meta.error ? meta.error : undefined}
            PasswordIcon={PasswordIcon}
            PasswordVisibleIcon={PasswordVisibleIcon}
            ErrorIcon={ErrorIcon}
            sx={sx}
            dataAttrs={dataAttrs}
        />
    );
};
