import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getMediaRecorder } from 'Features/participant/workspace/scenario/utils/recorder';
import { getStepperIds, getCurrentStep, getStepperStatus } from 'Features/participant/workspace/scenario/slice';
import { useWebcam } from 'Features/participant/workspace/scenario/context/webcamContext/WebcamProvider';
import {
    initRecording,
    completeRecording,
    handleWebcamDataAvailable,
} from 'Features/participant/workspace/scenario/utils/recorder';

export const useRecorder = () => {
    const stepperIds = useSelector(getStepperIds);
    const stepperStatus = useSelector(getStepperStatus);
    const currentStep = useSelector(getCurrentStep)?.scenarioStep;
    const { stream, webcamRef, error: webcamError } = useWebcam();
    const [recorder, setRecorder] = useState<MediaRecorder | null>(null);
    const [isWebcamReady, setIsWebcamReady] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [shouldGetRecorder, setShouldGetRecorder] = useState<boolean>(false);

    useEffect(() => {
        if (!stepperIds || !currentStep) {
            throw new Error('Incorrect state');
        }
        initRecording(stepperIds.stepSessionId, stepperIds.scenarioSessionId, currentStep.id);
        setShouldGetRecorder(true);
    }, [currentStep, stepperIds, stepperIds?.stepSessionId]);

    useEffect(() => {
        if (webcamError) {
            setErrorMessage(webcamError);
        }
        if (shouldGetRecorder && stream) {
            try {
                const stepId = currentStep?.id;
                if (stepId) {
                    const { recorder, isWebcamReady } = getMediaRecorder(stream, (event) =>
                        handleWebcamDataAvailable(stepId, event),
                    );
                    setRecorder(recorder);
                    setIsWebcamReady(isWebcamReady);
                    setShouldGetRecorder(false);
                }
            } catch (err) {
                /* eslint-disable-next-line no-console */
                console.error(err);
            }
        }
    }, [currentStep, shouldGetRecorder, stepperIds, stepperStatus, stream, webcamError]);

    const finishReactionRecording = useCallback(
        (stepId) => {
            if (recorder) {
                if (stepId) {
                    recorder.ondataavailable = async (event: BlobEvent) => {
                        await handleWebcamDataAvailable(stepId, event);
                        await completeRecording(stepId);
                    };
                }
            }
            recorder?.state !== 'inactive' && recorder?.stop();
        },
        [recorder],
    );

    return {
        isWebcamReady,
        errorMessage,
        recorder,
        setRecorder,
        setIsWebcamReady,
        webcamRef,
        finishReactionRecording,
        stream,
    };
};
