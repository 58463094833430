import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
    padding: `${theme.spacing(24)} ${theme.spacing(128)}`,
    color: theme.palette.common.white,
    [theme.breakpoints.down('lg')]: {
        padding: `${theme.spacing(16)} ${theme.spacing(20)}`,
    },
    [theme.breakpoints.down('md')]: {
        padding: `${theme.spacing(16)} ${theme.spacing(12)}`,
    },
}));
