import React, { ReactNode, useEffect } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { theme as wantentTheme, useWantentTheme } from '@watched-tech/wantent-ui';
import { useMediaQuery } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { setMobileView } from 'Features/participant/slice';
import { useDispatch } from 'react-redux';

const useMobileView = () => {
    const { breakpoints } = useWantentTheme();
    const matchesMd = useMediaQuery(breakpoints.down('md'));
    return matchesMd;
};

const MobileViewProvider: React.FC = ({ children }) => {
    const matchesMd = useMobileView();
    const dispatch = useDispatch();
    useEffect(() => {
        const showMobileView = matchesMd || isMobile;
        dispatch(setMobileView(showMobileView));
    }, [matchesMd, dispatch]);

    return <>{children}</>;
};

interface IWantentProviderProps {
    children: ReactNode;
}
export function WantentProvider({ children }: IWantentProviderProps) {
    return (
        <MuiThemeProvider theme={wantentTheme}>
            <MobileViewProvider>{children}</MobileViewProvider>
        </MuiThemeProvider>
    );
}
