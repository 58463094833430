import { ScenarioState } from 'Features/participant/workspace/types';
import { NotStartedChip } from 'Features/participant/workspace/dashboard/components/NotStartedChip';
import { InProgressChip } from 'Features/participant/workspace/dashboard/components/InProgressChip';
import { CompleteChip } from 'Features/participant/workspace/dashboard/components/CompleteChip';

export const scenarioCardWidth = 520;

export const DASHBOARD_SORT_OPTIONS = [
    {
        value: 'nameAsc',
        idx: 0,
    },
    {
        value: 'nameDesc',
        idx: 1,
    },
    {
        value: 'progressAsc',
        idx: 2,
    },
    {
        value: 'progressDesc',
        idx: 3,
    },
];

export const SORTING_VALUES = {
    nameAsc: { field: 'name', direction: 'asc' },
    nameDesc: { field: 'name', direction: 'desc' },
    progressAsc: { field: 'percents', direction: 'asc' },
    progressDesc: { field: 'percents', direction: 'desc' },
};

export const Chips = {
    notStarted: NotStartedChip,
    inProgress: InProgressChip,
    complete: CompleteChip,
};

export const chipStatuses = {
    [ScenarioState.ready]: 'notStarted',
    [ScenarioState.started]: 'inProgress',
    [ScenarioState.finished]: 'complete',
};
