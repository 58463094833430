import React from 'react';
import { FormControlLabel, FormControl, FormLabel, Radio, RadioGroup } from '@mui/material';
import { FieldInputProps, FieldRenderProps, Field } from 'react-final-form';

const RadioFormField = ({
    input: { checked, value, name, onChange, ...otherInput },
    ...other
}: {
    input: FieldInputProps<string>;
    meta: any;
}) => <Radio {...other} name={name} value={value} checked={checked} onChange={onChange} inputProps={otherInput} />;

export function RadioInput(props: FieldRenderProps<string> & { label: string; options: { name: string; id: any }[] }) {
    const { input, label, options, imageUrl } = props;
    return (
        <FormControl
            sx={{
                paddingLeft: '40px',
            }}
            component="fieldset"
            fullWidth
        >
            <FormLabel component="legend">{label}</FormLabel>
            {imageUrl && <img src={imageUrl} alt="checkbox" style={{ marginBottom: '20px' }} />}
            <RadioGroup>
                {options.map((option, index) => (
                    <FormControlLabel
                        key={index}
                        control={
                            <Field type="radio" name={`${input.name}`} value={option.name} component={RadioFormField} />
                        }
                        label={option.name}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
}
