import React, { ReactNode } from 'react';
import { ParticipantRoute, WantentProvider } from 'Shared/components/router';
import Feedback from 'Pages/participant/workspace/scenario/Feedback';
import ParticipantFinishUploadsPage from 'Pages/participant/workspace/scenario/FinishUploads';
import ScreenOut from 'Pages/participant/workspace/scenario/ScreenOut';
import { ParticipantScenario } from 'Pages/participant/workspace/scenario';
import ParticipantDashboard from 'Pages/participant/workspace/dashboard';
import { ParticipantRedirect } from 'Pages/participant/workspace/scenario/Redirect';
import { ParticipantInstructions } from 'Pages/participant/workspace/scenario/ParticipantInstructions';
import { ParticipantScenarioErrorFallback } from 'Src/features/participant/workspace/scenario/components';
import { applyRoutePage } from 'Src/shared/utils/routes/applyRoutePage';
import { RequireChrome } from 'Pages/participant/workspace/info/RequireChrome';
import QuotaFull from 'Pages/participant/workspace/scenario/QuotaFull';

const children = [
    {
        path: '',
        element: <ParticipantDashboard />,
    },
    {
        path: 'scenario/:scenarioId',
        children: [
            {
                path: '',
                element: <ParticipantScenario />,
                errorElement: (
                    <WantentProvider>
                        <ParticipantScenarioErrorFallback />
                    </WantentProvider>
                ),
            },
            {
                path: 'instructions',
                element: <ParticipantInstructions />,
            },
        ],
    },
    {
        path: 'finish-uploads',
        element: <ParticipantFinishUploadsPage />,
    },
    {
        path: 'redir',
        element: <ParticipantRedirect />,
    },
    {
        path: 'feedback',
        element: <Feedback />,
    },
    {
        path: 'different-audience',
        element: <ScreenOut />,
    },
    {
        path: 'quota_full',
        element: <QuotaFull />,
    },
    {
        path: 'require-chrome',
        element: <RequireChrome />,
    },
];
const ParticipantPage = ({ children }: { children: ReactNode }) => (
    <ParticipantRoute>
        <WantentProvider>{children}</WantentProvider>
    </ParticipantRoute>
);
export const participantRoutes = {
    path: 'me',
    children: applyRoutePage(children, ParticipantPage, ['redir']),
};
