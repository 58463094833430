import React, { ReactNode } from 'react';
import { ManagerRoute, PageWithSuspence } from 'Shared/components/router';
import { applyRoutePage } from 'Src/shared/utils/routes/applyRoutePage';
import {
    ManagerFocusGroupsPage,
    ManagerCreateFocusGroupPage,
    ManagerFocusGroupDetailsPage,
    ManagerMainPage,
    ManagerSurveysPage,
    ManagerCreateSurveyPage,
    ManagerEditSurveyPage,
    ManagerFocusGroupRegisterLinksPage,
    ManagerScenariosListPage,
    ManagerCreateScenarioPage,
    ManagerEditScenarioPage,
    ManagerScenarioDetailsPage,
    ManagerCopySurveyPage,
    ManagerScenarioStepReportsPage,
    ManagerTileRenderer,
} from '../lazyPages';
import ManagerEditQuotasPage from 'Pages/manager/scenarios/ManagerEditQuotas';
import ManagerEditBucketPage from 'Pages/manager/scenarios/ManagerEditBucket';
import ManagerEditScreener from 'Pages/manager/scenarios/ManagerEditScreener';

const ManagerPage = ({ children }: { children: ReactNode }) => (
    <ManagerRoute>
        <PageWithSuspence>{children}</PageWithSuspence>
    </ManagerRoute>
);

const children = [
    {
        path: '',
        element: <ManagerMainPage />,
    },
    {
        path: 'tile_renderer',
        element: <ManagerTileRenderer />,
    },
    {
        path: 'focus_groups',
        children: [
            {
                path: '',
                element: <ManagerFocusGroupsPage />,
            },
            {
                path: 'details/:focusGroupId',
                element: <ManagerFocusGroupDetailsPage />,
            },
            {
                path: ':focusGroupId/register_links',
                element: <ManagerFocusGroupRegisterLinksPage />,
            },
            {
                path: 'create',
                element: <ManagerCreateFocusGroupPage />,
            },
        ],
    },
    {
        path: 'surveys',
        children: [
            {
                path: '',
                element: <ManagerSurveysPage />,
            },
            {
                path: 'create',
                element: <ManagerCreateSurveyPage />,
            },
            {
                path: ':surveyId/edit',
                element: <ManagerEditSurveyPage />,
            },
            {
                path: ':surveyId/copy',
                element: <ManagerCopySurveyPage />,
            },
        ],
    },
    {
        path: 'scenarios',
        children: [
            {
                path: '',
                element: <ManagerScenariosListPage />,
            },
            {
                path: 'create',
                element: <ManagerCreateScenarioPage />,
            },
            {
                path: ':scenarioId/edit',
                element: <ManagerEditScenarioPage />,
            },
            {
                path: ':scenarioId/buckets/:bucketId/screener',
                element: <ManagerEditScreener />,
            },
            {
                path: ':scenarioId/buckets/:bucketId/dcms',
                element: <ManagerEditBucketPage />,
            },
            {
                path: ':scenarioId/buckets/:bucketId/quotas',
                element: <ManagerEditQuotasPage />,
            },
            {
                path: ':scenarioId/steps/:stepId/ai_reports',
                element: <ManagerScenarioStepReportsPage />,
            },
            {
                path: 'details/:scenarioId',
                element: <ManagerScenarioDetailsPage />,
            },
        ],
    },
];

const managerWithSuspenceRoutes = applyRoutePage(children, ManagerPage);

export const managerRoutes = {
    path: 'manager',
    children: managerWithSuspenceRoutes,
};
