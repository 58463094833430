import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getMediaRecorder } from 'Features/participant/workspace/scenario/utils/recorder';
import {
    getStepperIds,
    getCurrentStep,
    getStepperStatus,
    ScreenshareStatus,
    setScreenshareStatus,
} from 'Features/participant/workspace/scenario/slice';
import {
    initRecording,
    completeRecording,
    handleWebcamDataAvailable,
} from 'Features/participant/workspace/scenario/utils/recorder';
import { UploadType } from 'Shared/workers/SessionUpload';

const stopScreenShare = (stream: MediaStream) => {
    if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track: MediaStreamTrack) => track.stop());
    }
};

export const useScreenRecorder = () => {
    const stepperIds = useSelector(getStepperIds);
    const stepperStatus = useSelector(getStepperStatus);
    const currentStep = useSelector(getCurrentStep)?.scenarioStep;
    const [recorder, setRecorder] = useState<MediaRecorder | null>(null);
    const [shouldGetRecorder, setShouldGetRecorder] = useState<boolean>(false);
    const [stream, setStream] = useState<MediaStream>();
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
    const [error, setError] = useState<{ message: string; isFirefoxError: boolean }>({
        message: '',
        isFirefoxError: false,
    });
    const dispatch = useDispatch();
    const { t: getLabel } = useTranslation();

    useEffect(() => {
        dispatch(setScreenshareStatus(ScreenshareStatus.notStarted));
        return function clear() {
            stream && stopScreenShare(stream);
        };
    }, [stream]);

    useEffect(() => {
        if (!stepperIds || !currentStep) {
            throw new Error('Incorrect state');
        }
        initRecording(stepperIds.stepSessionId, stepperIds.scenarioSessionId, currentStep.id, UploadType.screenShare);
        setShouldGetRecorder(true);
    }, [currentStep, stepperIds]);

    const getDisplayMediaStream = useCallback(async () => {
        if (navigator.mediaDevices && 'getDisplayMedia' in navigator.mediaDevices) {
            const stream = await navigator.mediaDevices.getDisplayMedia({
                video: {
                    cursor: 'always',
                },
            } as DisplayMediaStreamConstraints);
            setStream(stream);
            return stream;
        } else {
            throw new Error('getDisplayMedia is not supported');
        }
    }, []);

    const handleScreenDataAvailable = useCallback(
        (event: any) => {
            currentStep?.id && handleWebcamDataAvailable(currentStep.id, event, UploadType.screenShare);
        },
        [currentStep?.id],
    );

    useEffect(() => {
        if (shouldGetRecorder && !error.message) {
            getDisplayMediaStream()
                .then((stream) => {
                    const [track] = stream.getVideoTracks();
                    track.addEventListener('ended', () => {
                        // screen share was finished by the user gesture
                        setIsFullscreen(false);
                        setError instanceof Function &&
                            setError({ ...error, message: getLabel('participant.website.doNotStop') });
                        dispatch(setScreenshareStatus(ScreenshareStatus.finished));
                    });
                    const settings = track.getSettings();
                    if ((settings as any).displaySurface === undefined) {
                        setError instanceof Function &&
                            setError({ message: 'Please use google chrome browser', isFirefoxError: true });
                    }
                    if (
                        (settings as any).displaySurface !== 'monitor' &&
                        (settings as any).displaySurface !== undefined
                    ) {
                        setError instanceof Function &&
                            setError({ ...error, message: getLabel('participant.website.entireScreenRequired') });
                    }
                    const { recorder: screenRecorder } = getMediaRecorder(stream, handleScreenDataAvailable);
                    setRecorder(screenRecorder);
                    setShouldGetRecorder(false);
                })
                .catch((err: any) => {
                    // if cancel button clicked
                    if (err.message.toLowerCase().includes('permission denied')) {
                        setError instanceof Function &&
                            setError({ ...error, message: getLabel('participant.website.doNotCancel') });
                    }
                    throw new Error(err);
                });
        }
    }, [
        currentStep,
        shouldGetRecorder,
        stepperIds,
        stepperStatus,
        handleScreenDataAvailable,
        error,
        setError,
        getDisplayMediaStream,
        getLabel,
    ]);

    const finishScreenRecording = useCallback(() => {
        if (recorder) {
            if (currentStep?.id) {
                recorder.ondataavailable = async (event: BlobEvent) => {
                    await handleWebcamDataAvailable(currentStep?.id, event, UploadType.screenShare);
                    await completeRecording(currentStep?.id, UploadType.screenShare);
                };
            }
        }
        recorder?.state !== 'inactive' && recorder?.stop();
        stream && stopScreenShare(stream);
    }, [recorder, stream, currentStep?.id]);

    return {
        isFullscreen,
        setIsFullscreen,
        screenRecorder: recorder,
        finishScreenRecording,
        error,
    };
};
