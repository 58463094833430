import React, { useState, useEffect } from 'react';
import {
    Button,
    TextField,
    Card,
    CardContent,
    Box,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import { ScreenerQuestion } from 'Features/manager/shared/queries';

interface OptionQuota {
    id: number;
    text: string;
    percentage: number;
}

export interface QuestionQuota {
    questionId: string;
    type: 'byScreeningQuestion';
    quotaRules: OptionQuota[];
}

interface EditQuestionQuotaProps {
    onSave: (quotaRule: QuestionQuota) => void;
    questions: ScreenerQuestion[];
    currentQuota?: QuestionQuota;
    existingQuotas: QuestionQuota[];
}

const EditQuestionQuota: React.FC<EditQuestionQuotaProps> = ({ onSave, currentQuota, existingQuotas, questions }) => {
    const [currentEditorQuota, setCurrentEditorQuota] = useState<QuestionQuota>(
        currentQuota || { questionId: '', type: 'byScreeningQuestion', quotaRules: [] },
    );

    useEffect(() => {
        if (currentQuota) {
            setCurrentEditorQuota(currentQuota);
        }
    }, [currentQuota]);

    const handleQuestionChange = (event: SelectChangeEvent<string>) => {
        if (!questions) return;

        const selectedQuestion = questions.find((question) => question.id === event.target.value);
        if (selectedQuestion) {
            const options =
                selectedQuestion.options?.map(
                    (option) => ({ id: option.id, text: option.text, percentage: 0 } as OptionQuota),
                ) || [];
            setCurrentEditorQuota({
                questionId: event.target.value as string,
                type: 'byScreeningQuestion',
                quotaRules: options,
            });
        }
    };

    const handleOptionPercentageChange = (
        optionId: number,
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const updatedOptions = currentEditorQuota.quotaRules.map((o) =>
            o.id === optionId ? { ...o, percentage: Number(event.target.value) } : o,
        );
        setCurrentEditorQuota({ ...currentEditorQuota, quotaRules: updatedOptions });
    };

    const handleSubmit = () => {
        onSave(currentEditorQuota);
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">Quota by question</Typography>
                <Box p={1}>
                    <FormControl fullWidth>
                        <InputLabel id="question-select-label">Question</InputLabel>
                        <Select
                            labelId="question-select-label"
                            id="question-select"
                            value={currentEditorQuota.questionId}
                            onChange={handleQuestionChange}
                        >
                            {questions
                                ?.filter(
                                    (question) =>
                                        !existingQuotas.some(
                                            (quota) =>
                                                quota.questionId === question.id &&
                                                quota.questionId !== currentEditorQuota.questionId,
                                        ),
                                )
                                .map((question) => (
                                    <MenuItem key={question.id} value={question.id}>
                                        {question.text}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Box>
                {currentEditorQuota.quotaRules.map((optionQuota, index) => (
                    <Box p={1} key={index}>
                        <TextField
                            name={optionQuota.id.toString()}
                            label={`Percentage for ${optionQuota.text}`}
                            value={optionQuota.percentage}
                            onChange={(event) => handleOptionPercentageChange(optionQuota.id, event)}
                            required
                            fullWidth
                        />
                    </Box>
                ))}
                <Box p={1}>
                    <Button onClick={handleSubmit} fullWidth>
                        Submit Quota Rule
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};

export default EditQuestionQuota;
