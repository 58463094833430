import { styled } from '@mui/material/styles';

export const FormGap = styled('div')(({ theme }) => ({
    boxSizing: 'border-box',
    width: '100%',
    paddingBottom: theme.spacing(12),
    '& form': {
        width: '100%',
        display: 'grid',
        gridTemplateRows: 'auto',
        gridGap: theme.spacing(12),
    },
}));
