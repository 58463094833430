import { useState, useEffect } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { useWantentTheme } from '@watched-tech/wantent-ui';

export const useHeaderHeight = () => {
    const theme = useWantentTheme();
    const { width } = useWindowSize();
    const isLargeScreen = width >= theme.breakpoints.values.lg;
    const [headerHeight, setHeaderHeight] = useState(theme.headerHeights[isLargeScreen ? 'l' : 'm']);
    useEffect(() => {
        const headerHeight = theme.headerHeights[isLargeScreen ? 'l' : 'm'];
        setHeaderHeight(headerHeight);
    }, [isLargeScreen, theme.headerHeights]);
    return headerHeight;
};
