import { QueryFunction, QueryKey, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';

export const useAuthedQuery = <
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey,
>(
    queryKey: TQueryKey,
    queryFn: QueryFunction<TQueryFnData, TQueryKey>,
    options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> => {
    const query = useQuery(queryKey, queryFn, options);
    const navigate = useNavigate();
    const response = (query?.error as any)?.response;
    if (response?.status === 401) {
        navigate('/login');
    }
    return query;
};
