import { optionQuestionTypes } from 'Shared/constants';

export * from './survey';
export * from './focusGroups';
export * from './common';

import { IQueryCallbackId } from 'Features/manager/shared/queries';
import { ElementType, IQuestion, IQuestionOption, IQuestionWithConfig } from 'Shared/types';
import User from 'Shared/models/User';

export interface IStepReportListParams extends IQueryCallbackId {
    stepId: string;
}
export type QuestionElementType = Exclude<ElementType, ElementType.info>;
export const isQuestionType = (elementType: ElementType): elementType is QuestionElementType => {
    return elementType !== ElementType.info;
};

type PickQuestionType = ElementType.pickManyQuestion | ElementType.pickOneQuestion | ElementType.radioPickOneQuestion;

export interface IPickQuestion extends IQuestion {
    type: PickQuestionType;
    isOptionsRandomized: boolean;
    options: IQuestionOption[];
}
export function isPickQuestion(question: SurveyQuestion): question is IPickQuestion {
    return optionQuestionTypes.includes(question.type as ElementType);
}
export interface IRatingQuestion extends IQuestionWithConfig<{ minValue: number; maxValue: number }> {
    type: ElementType.ratingSliderQuestion;
}

export interface IStarsQuestion extends IQuestionWithConfig<{ maxValue: number }> {
    type: ElementType.starsQuestion;
}

export interface INumberQuestion extends IQuestionWithConfig<Record<string, never>> {
    type: ElementType.numberQuestion;
}

export interface IPlainTextQuestion extends IQuestionWithConfig<Record<string, never>> {
    type: ElementType.plainTextQuestion;
}

export type SurveyQuestion = IPickQuestion | IRatingQuestion | IStarsQuestion | INumberQuestion | IPlainTextQuestion;

export enum ScreenOutRule {
    oneOf = 'oneOf',
    oneOfExclusive = 'oneOf exclusive',
}

export interface ICreateTenantModel {
    name: string;
    admin: {
        email: string;
        password: string;
    };
}

export interface IManager {
    email: string;
    role: string;
}

export interface IProjectManager {
    id: string;
    name: string;
    userName: string;
}

export interface IFocusGroup {
    id: string;
    title: string;
    participantIds: string[];
    manager: IProjectManager;
    createdAt: string;
}

export interface IFocusGroupDetails {
    id: string;
    title: string;
    focusGroupManager: IProjectManager;
    participants: User[];
}
