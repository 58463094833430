import React, { useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useRoutingParams } from 'Src/shared/hooks';

export function ParticipantRedirect() {
    const { url } = useRoutingParams<{ url: string }>();
    useEffect(() => {
        url && window.location.assign(decodeURIComponent(url));
    }, [url]);

    return (
        <Box
            sx={{
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                display: 'flex',
            }}
        >
            <Typography>Redirecting you to {url}</Typography>
            <CircularProgress />
        </Box>
    );
}
