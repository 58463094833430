import React, { useCallback, ReactNode, FC } from 'react';
import { List, Drawer, ListItem, ListItemIcon, ListItemText, Divider, CssBaseline } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { ExitToApp, Poll, Group, GridView, Visibility } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ReactComponent as WantentLogo } from 'Assets/svg/wantent_cyan_logo.svg';
import { useAuthContext } from 'Shared/auth/context';
import { Roles } from 'Shared/auth/common';

const drawerWidth = 203;

const StyledLink = styled(Link)({
    textDecoration: 'none',
    color: '#fff',
});

const StyledList = styled(List)({
    marginTop: 'auto',
    color: '#fff',
});

const SideMenu = () => {
    const theme = useTheme();
    const auth = useAuthContext();
    const navigate = useNavigate();
    const handleLogoutClick = useCallback(() => {
        auth.logout('/');
        Sentry.setUser(null);
    }, [navigate]);
    const contrastText = theme.palette.getContrastText(theme.palette.primary.main);

    return (
        <>
            <List>
                <StyledLink to="/manager/focus_groups" id="focusgroups">
                    <ListItem
                        button
                        sx={{
                            color: contrastText,
                        }}
                    >
                        <ListItemIcon>
                            <Group
                                sx={{
                                    color: contrastText,
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText>Focus Groups</ListItemText>
                    </ListItem>
                </StyledLink>
                <StyledLink to="/manager/surveys" id="surveys">
                    <ListItem
                        button
                        sx={{
                            color: contrastText,
                        }}
                    >
                        <ListItemIcon>
                            <Poll
                                sx={{
                                    color: contrastText,
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText>Surveys</ListItemText>
                    </ListItem>
                </StyledLink>
                <StyledLink to="/manager/scenarios" id="scenarios">
                    <ListItem
                        button
                        sx={{
                            color: contrastText,
                        }}
                    >
                        <ListItemIcon>
                            <Visibility
                                sx={{
                                    color: contrastText,
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText>Scenarios</ListItemText>
                    </ListItem>
                </StyledLink>

                {auth.role === Roles.Admin && (
                    <>
                        <StyledLink to="/admin/managers" id="managers">
                            <ListItem
                                button
                                sx={{
                                    color: contrastText,
                                }}
                            >
                                <ListItemIcon>
                                    <Group
                                        sx={{
                                            color: contrastText,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText>Managers</ListItemText>
                            </ListItem>
                        </StyledLink>
                    </>
                )}
                <Divider variant="middle" color="white" sx={{ margin: '16px' }} />
                <StyledLink to="/manager/tile_renderer">
                    <ListItem
                        button
                        sx={{
                            color: contrastText,
                        }}
                    >
                        <ListItemIcon>
                            <GridView
                                sx={{
                                    color: contrastText,
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText>Tile renderer</ListItemText>
                    </ListItem>
                </StyledLink>
            </List>
            <StyledList>
                <ListItem button onClick={handleLogoutClick}>
                    <ListItemIcon>
                        <ExitToApp
                            sx={{
                                color: contrastText,
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                </ListItem>
            </StyledList>
        </>
    );
};

interface IManagerLayoutProps {
    children: ReactNode;
}

const Content = styled('main')(({ theme }) => {
    return {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    };
});

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
        width: drawerWidth,
        backgroundColor: theme.palette.primary.dark,
    },
}));

const LayoutRoot = styled('div')({
    display: 'flex',
    height: '100%',
    minHeight: '100%',
});

const ManagerLayout: FC<IManagerLayoutProps> = ({ children }) => {
    return (
        <LayoutRoot>
            <CssBaseline />
            <StyledDrawer variant="permanent" anchor="left">
                <div style={{ padding: '32px' }}>
                    <WantentLogo />
                </div>
                <SideMenu />
            </StyledDrawer>
            <Content>{children}</Content>
        </LayoutRoot>
    );
};

export default ManagerLayout;
