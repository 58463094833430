import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, CircularProgress, Sidebar, useWantentTheme } from '@watched-tech/wantent-ui';
import { getVideoStates } from 'Features/participant/slice';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    getSidebarSteps,
    getScenario,
    reset as resetStepper,
    getScenarioSessionId,
} from 'Features/participant/workspace/scenario/slice';
import { SidebarWrapper } from 'Features/participant/workspace/scenario/styles';
import { useActionButton } from 'Features/participant/workspace/scenario/hooks/useActionButton';
import ScenarioLayout from 'Features/participant/workspace/scenario/components/shared/layouts/ScenarioLayout';
import { LinearProgress } from './LinearProgress';
import { participantMutations } from 'Features/participant/workspace/scenario/queries';
import { handleFollowingAction } from 'Utils/following-actions';

const FlexWrapper = styled('div')({
    position: 'relative',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
});

export const FinishModalContent = (props: { value: number; name: string }) => {
    const theme = useTheme();

    return (
        <LinearProgress
            value={Math.floor(props.value)}
            name={props.name}
            sx={{ wrapper: { marginBottom: theme.spacing(20) } }}
        />
    );
};

const FinishUploads = () => {
    const navigate = useNavigate();
    const uploads = useSelector(getVideoStates);
    const sidebarSteps = useSelector(getSidebarSteps);
    const [activeId, setActiveId] = useState(1);
    const scenario = useSelector(getScenario);
    const { t: getLabel } = useTranslation();
    const actionButton = useActionButton();
    const { breakpoints } = useWantentTheme();
    const matchesLg = useMediaQuery(breakpoints.down('lg'));
    const theme = useWantentTheme();
    const dispatch = useDispatch();
    const sessionId = useSelector(getScenarioSessionId);
    const { mutateAsync: finishSession } = participantMutations.useFinishScenarioSession({
        onSuccess: (res) => {
            navigate(handleFollowingAction(res));
            dispatch(resetStepper());
        },
    });
    useEffect(() => {
        setActiveId(2);
        actionButton.setDisabled(true);
    }, []);
    const areAllVideosCompleted = uploads.every((videoState) => videoState.finished);
    const finished = uploads.filter((u) => u.finished);
    useEffect(() => {
        if (areAllVideosCompleted) {
            actionButton.setDisabled(false);
            sessionId &&
                finishSession({ urlParams: { id: sessionId } }).then(() => {
                    scenario?.id && navigate(`/me/feedback?scenarioId=${scenario.id}`);
                });
        }
    }, [areAllVideosCompleted]);

    const uploadSteps = useMemo(
        () => sidebarSteps.slice(-3).map((step, idx) => ({ ...step, id: idx + 1 })),
        [sidebarSteps],
    );

    return (
        <ScenarioLayout>
            <SidebarWrapper>
                <Sidebar steps={uploadSteps} activeId={activeId} />
            </SidebarWrapper>
            <FlexWrapper>
                <CircularProgress
                    left={matchesLg ? 40 : 132}
                    questionsCount={uploads.length}
                    questionsDone={finished.length}
                    isFinished={uploads.length ? areAllVideosCompleted : false}
                    text={[getLabel('participant.bgUploads.reactions'), getLabel('participant.bgUploads.uploaded')]}
                    sx={{ wrapper: { top: '32px', [breakpoints.down('md')]: { display: 'none' } } }}
                />

                <Paper
                    title={getLabel('participant.bgUploads.title')}
                    description={getLabel('participant.bgUploads.description')}
                    sx={{
                        root: {
                            marginLeft: `${theme.sidebarWidth}px`,
                        },
                    }}
                >
                    {uploads.length
                        ? uploads.map((u) => {
                              return (
                                  <FinishModalContent
                                      key={u.uploadName}
                                      name={`Step ${u.idx + 1}`}
                                      value={u.percents}
                                  />
                              );
                          })
                        : null}
                </Paper>
            </FlexWrapper>
        </ScenarioLayout>
    );
};

export default FinishUploads;
