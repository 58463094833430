import React from 'react';
import { FormControl, FormHelperText, InputLabel, OutlinedInput, OutlinedInputProps } from '@mui/material';
import { FieldRenderProps } from 'react-final-form';

interface ITextFieldProps extends FieldRenderProps<any>, OutlinedInputProps {
    label: string;
    imageUrl?: string;
    customHelper: React.ComponentClass;
}

export const FinalFormTextArea = (props: ITextFieldProps): JSX.Element => {
    const materialInputProps = props as OutlinedInputProps;
    return (
        <FormControl fullWidth>
            <InputLabel variant="outlined" htmlFor={props.id}>
                {props.label}
            </InputLabel>
            <OutlinedInput
                id={props.id}
                autoFocus={props.autoFocus}
                multiline
                rows={3}
                fullWidth
                error={props.meta.touched && props.meta.error}
                {...props.input}
                {...materialInputProps}
                inputProps={{
                    name: props.name,
                    id: props.id,
                }}
            />

            <FormHelperText variant="outlined">{props.meta.touched && props.meta.error}</FormHelperText>
        </FormControl>
    );
};
