import React, { FC, SyntheticEvent } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Select } from '@watched-tech/wantent-ui';
import { DataAttrs } from '@watched-tech/wantent-ui/utils';

interface ISelectOption {
    label: string;
    value: string;
    idx: number;
}

enum SelectType {
    primary = 'primary',
    secondary = 'secondary',
}
interface IParticipantFinalFormSelectProps {
    options: ISelectOption[];
    placeholder?: string;
    label?: string;
    helperText?: string;
    id: string;
    selectType?: SelectType;
    dataAttrs?: DataAttrs;
}

export const ParticipantFinalFormSelect: FC<FieldRenderProps<string> & IParticipantFinalFormSelectProps> = ({
    input,
    meta,
    options,
    placeholder,
    label,
    helperText,
    id,
    selectType,
    sx,
    dataAttrs,
}) => {
    const onChange = (e: SyntheticEvent, value: string | number) => {
        input.onChange(value);
    };
    return (
        <Select
            id={id}
            selectType={selectType}
            options={options}
            value={input.value}
            onChange={onChange}
            onFocus={input.onFocus}
            onBlur={input.onBlur}
            placeholder={placeholder}
            error={meta.touched && meta.error ? meta.error : undefined}
            label={label}
            helperText={helperText}
            sx={sx}
            dataAttrs={{ ...dataAttrs }}
        />
    );
};
