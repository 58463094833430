import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    TextField,
    Select,
    MenuItem,
    Typography,
} from '@mui/material';
import { managerQueries, managerMutations } from 'Features/manager/shared/queries';
import ManagerLayout from '../../ManagerLayout';

const ManagerEditBucketPage = () => {
    const { bucketId, scenarioId } = useParams<{ bucketId: string; scenarioId: string }>();
    const { data: bucket, isLoading, error, refetch: refetchBucket } = managerQueries.useAudienceBucket(bucketId || '');
    const { data: dcmList } = managerQueries.useDCMs(scenarioId || '');
    const { mutateAsync: assignDataCollectionMethod } = managerMutations.useAssignDCMToBucket();
    const [selectedMethodId, setSelectedMethodId] = useState('');

    const handleAddMethod = async () => {
        if (bucketId && selectedMethodId) {
            await assignDataCollectionMethod({
                urlParams: { bucketId },
                body: { dataCollectionMethodId: selectedMethodId },
            });
            setSelectedMethodId('');
            await refetchBucket();
        }
    };

    console.log(bucket);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <ManagerLayout>
            <Typography variant="h4">Edit Audience Bucket {bucket?.name}</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Data Collection Method ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Type</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bucket?.dataCollectionMethods.map((method) => (
                            <TableRow key={method.id}>
                                <TableCell>{method.id}</TableCell>
                                <TableCell>{method.title}</TableCell>
                                <TableCell>{method.type}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div>
                <Select value={selectedMethodId} onChange={(e) => setSelectedMethodId(e.target.value)}>
                    {dcmList
                        ?.filter((dcm) => !bucket?.dataCollectionMethods.find((method) => method.id === dcm.id))
                        .map((method) => (
                            <MenuItem key={method.id} value={method.id}>
                                {method.title}
                            </MenuItem>
                        ))}
                </Select>
                <Button onClick={handleAddMethod}>Add Method</Button>
            </div>
        </ManagerLayout>
    );
};

export default ManagerEditBucketPage;
