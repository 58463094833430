import React from 'react';
import { UiLocale, useLocale } from 'Src/localizations/i18n';
import RegisterLayout from 'Features/participant/register/components/shared/RegisterLayout';
import { OpenGroupRegisterForm } from 'Features/participant/register/components/OpenGroupRegisterForm';
import { useRoutingParams } from 'Src/shared/hooks';

interface JoinOpenGroupPathParams {
    id: string;
    preferredLocale: UiLocale;
}

const JoinOpenGroup = () => {
    const { id, preferredLocale } = useRoutingParams<JoinOpenGroupPathParams>();
    useLocale(preferredLocale);
    return (
        <RegisterLayout>
            <OpenGroupRegisterForm openGroupId={id} />
        </RegisterLayout>
    );
};

export default JoinOpenGroup;
