import React, { FC, SyntheticEvent } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Slider } from '@watched-tech/wantent-ui';
import { SxProps, Theme } from '@mui/material';
import { DataAttrs } from '@watched-tech/wantent-ui/utils';

interface IParticipantFinalFormSliderProps {
    min: number;
    max: number;
    error?: string;
    setFieldTouched: (args: string, state: any) => void;
    id: string;
    showMobileView?: boolean;
    sx?: {
        errorWrapper?: SxProps<Theme>;
        sliderWrapper?: SxProps<Theme>;
        slider?: SxProps<Theme>;
        error?: SxProps<Theme>;
    };
    dataAttrs?: DataAttrs;
}

export const ParticipantFinalFormSlider: FC<FieldRenderProps<number> & IParticipantFinalFormSliderProps> = ({
    input,
    meta,
    min,
    max,
    error,
    setFieldTouched,
    showMobileView = false,
    sx,
    id,
    dataAttrs,
}) => {
    const onChange = (e: SyntheticEvent) => {
        input.onChange(e);
        setFieldTouched(input.name, true);
    };
    return (
        <Slider
            id={id}
            value={input.value}
            onChange={onChange}
            min={min}
            max={max}
            error={error}
            valueLabelDisplay="auto"
            isMobile={showMobileView}
            sx={sx}
            dataAttrs={dataAttrs}
        />
    );
};
