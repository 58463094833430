import { useState, useEffect } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { useWantentTheme } from '@watched-tech/wantent-ui';

export const useFooterHeight = () => {
    const theme = useWantentTheme();
    const { width } = useWindowSize();
    const isLargeScreen = width >= theme.breakpoints.values.lg;
    const [footerHeight, setFooterHeight] = useState(theme.footerHeights[isLargeScreen ? 'l' : 'm']);
    useEffect(() => {
        const footerHeight = theme.footerHeights[isLargeScreen ? 'l' : 'm'];
        setFooterHeight(footerHeight);
    }, [isLargeScreen, theme.footerHeights]);
    return footerHeight;
};
