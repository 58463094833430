export const Roles = Object.freeze({
    Admin: 'Admin',
    FocusGroupManager: 'FocusGroupManager',
    TenantAdmin: 'TenantAdmin',
    FocusGroupParticipant: 'FocusGroupParticipant',
    OpenGroupParticipant: 'OpenGroupParticipant',
    PanelParticipant: 'PanelParticipant',
});

export const ManagementRoles: (keyof typeof Roles)[] = ['Admin', 'TenantAdmin', 'FocusGroupManager'];
export const ParticipantRoles: (keyof typeof Roles)[] = [
    'FocusGroupParticipant',
    'OpenGroupParticipant',
    'PanelParticipant',
];

export const getJwtPayload = (token: string) => {
    const base64body = token.split('.')[1];
    const base64 = base64body.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
};

export interface AuthStrategy {
    getToken: () => Promise<string>;
    login: (loc: string) => void;
    logout: (loc: string) => void;
    refreshTokenAndUpdateWorker: () => void;
    isLoading: boolean;
    role?: keyof typeof Roles;
    userId?: string;
}

export type AuthScheme = 'auth0' | 'wnt';

export function getAuthScheme() {
    return localStorage.getItem('authScheme');
}

export function setAuthScheme(scheme: 'auth0' | 'wnt' | undefined) {
    if (!scheme) {
        localStorage.removeItem('authScheme');
        return;
    }
    localStorage.setItem('authScheme', scheme);
}
