import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { UiLocale, useLocale } from 'Src/localizations/i18n';
import { LinearProgress } from '@mui/material';
import RegisterLayout from 'Features/participant/register/components/shared/RegisterLayout';
import RegisterPanelGroupForm from 'Features/participant/register/components/RegisterPanelGroupForm';
import {
    getIsPanelRespondentLoginAvailable,
    isPanelRespondentLoginAvailableQueryKey,
} from 'Features/participant/register/queries';
import { useRoutingParams } from 'Src/shared/hooks';

interface JoinPanelGroupPathParams {
    pgid: string;
    panelRespondentId: string;
    preferredLocale: UiLocale;
}

function getPanelRespondentId(queryParams: JoinPanelGroupPathParams): string {
    if (!queryParams.panelRespondentId) {
        const leftoverParams = Object.keys(queryParams).filter((k) => k !== 'pgid' && k !== 'preferredLocale');
        if (leftoverParams.length == 1) {
            return queryParams[leftoverParams[0]] as string;
        }
    }

    return queryParams.panelRespondentId;
}

const JoinPanelGroup = (): JSX.Element => {
    const navigate = useNavigate();
    const queryParams = useRoutingParams<JoinPanelGroupPathParams>();
    const { pgid, preferredLocale } = queryParams;
    const panelRespondentId = getPanelRespondentId(queryParams);
    useLocale(preferredLocale);
    const {
        isLoading: isSessionLoading,
        error,
        isError,
        isIdle,
    } = useQuery(
        [
            isPanelRespondentLoginAvailableQueryKey,
            { panelGroupId: pgid, panelRespondentId: panelRespondentId },
        ] as const,

        getIsPanelRespondentLoginAvailable,
        {
            onError: () => {
                navigate('/invalid_link');
            },
        },
    );

    if (isIdle || isSessionLoading) {
        return (
            <RegisterLayout>
                <LinearProgress sx={{ position: 'absolute', top: 0, right: 0, left: 0, height: 2 }} />
            </RegisterLayout>
        );
    }

    if (!panelRespondentId) {
        return <p>panelRespondentId or panel respondent indicator is required</p>;
    }
    if (isError) {
        return <p>{(error as any).response.data.message?.toString()}</p>;
    }
    return (
        <RegisterLayout>
            <RegisterPanelGroupForm panelGroupId={pgid} panelRespondentId={panelRespondentId} />
        </RegisterLayout>
    );
};

export default JoinPanelGroup;
