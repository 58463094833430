export const preloadImage = (url: string, callback: any) => {
    const img = document.createElement('img');
    img.src = url;
    img.onload = function (e) {
        callback();
    };
};

export async function getImageDimensions(image: File) {
    return new Promise<{ height: number; width: number }>((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            resolve({ height: img.height, width: img.width });
        };
        img.onerror = (err) => {
            reject(err);
        };
        img.src = URL.createObjectURL(image);
    });
}
