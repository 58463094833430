import { lazy } from 'react';

export const ManagerFocusGroupsPage = lazy(
    () => import(/* webpackChunkName: "manager.fgs" */ './pages/manager/focus-groups/ManagerFocusGroupsPage'),
);

export const ManagerCreateFocusGroupPage = lazy(
    () => import(/* webpackChunkName: "manager.createfg" */ './pages/manager/focus-groups/ManagerCreateFocusGroupPage'),
);

export const ManagerFocusGroupDetailsPage = lazy(
    () => import(/* webpackChunkName: "manager.fg" */ './pages/manager/focus-groups/ManagerFocusGroupDetailsPage'),
);

export const ManagerMainPage = lazy(
    () => import(/* webpackChunkName: "manager.main" */ './pages/manager/ManagerMainPage'),
);

export const ManagerSurveysPage = lazy(
    () => import(/* webpackChunkName: "manager.surveys" */ './pages/manager/surveys/ManagerSurveysPage'),
);

export const ManagerCreateSurveyPage = lazy(
    () => import(/* webpackChunkName: "manager.survey" */ './pages/manager/surveys/ManagerCreateSurveyPage'),
);

export const ManagerEditSurveyPage = lazy(
    () => import(/* webpackChunkName: "manager.survey" */ './pages/manager/surveys/ManagerEditSurveyPage'),
);

export const ManagerFocusGroupRegisterLinksPage = lazy(
    () =>
        import(
            /* webpackChunkName: "manager.links" */ './pages/manager/focus-groups/ManagerFocusGroupRegisterLinksPage'
        ),
);

export const ManagerScenariosListPage = lazy(
    () => import(/* webpackChunkName: "manager.scenarios" */ './pages/manager/scenarios/ManagerScenariosList/index'),
);

export const ManagerCreateScenarioPage = lazy(
    () =>
        import(
            /* webpackChunkName: "manager.scenario.details" */ './pages/manager/scenarios/ManagerCreateScenarioPage/index'
        ),
);

export const ManagerEditScenarioPage = lazy(
    () =>
        import(
            /* webpackChunkName: "manager.scenario.details" */ './pages/manager/scenarios/ManagerEditScenarioPage/index'
        ),
);

export const ManagerScenarioDetailsPage = lazy(
    () =>
        import(
            /* webpackChunkName: "manager.scenario.details" */ './pages/manager/scenarios/ManagerScenarioDetails/index'
        ),
);
export const ManagerScenarioStepReportsPage = lazy(
    () =>
        import(
            /* webpackChunkName: "manager.scenario.step.ai_reports" */ 'Pages/manager/scenarios/ManagerScenarioStepReports/index'
        ),
);
export const ManagerCopySurveyPage = lazy(
    () => import(/* webpackChunkName: "manager.survey.copy" */ 'Pages/manager/surveys/ManagerCopySurveyPage/index'),
);

export const ManagerTileRenderer = lazy(() => import('./pages/manager/tile/index'));

export const AdminCreateUserPage = lazy(
    () => import(/* webpackChunkName: "manager.main" */ './pages/admin/AdminCreateUserPage'),
);

export const AdminManagersPage = lazy(
    () => import(/* webpackChunkName: "manager.main" */ './pages/admin/AdminManagersPage'),
);
