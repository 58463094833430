import React, { useState, useCallback, FC, ReactNode, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Alert } from '@watched-tech/wantent-ui';
import { ReactComponent as User } from 'Assets/svg/user.svg';
import { IFaceCheckResult } from 'Features/participant/workspace/scenario/types';
import { getCurrentStep, setWebcamCheck } from 'Features/participant/workspace/scenario/slice';
import { useActionButton } from 'Features/participant/workspace/scenario/hooks/useActionButton';
import { _base64ToArrayBuffer } from 'Utils/toBase64';
import { ScenarioPaper } from './shared/ScenarioPaper';
import { useWebcam } from 'Features/participant/workspace/scenario/context/webcamContext/WebcamProvider';
import { participantMutations } from 'Features/participant/workspace/scenario/queries';

const StyledUser = styled(User)(({ theme }) => ({
    position: 'absolute',
    margin: 'auto',
    width: 360,
    height: 360,
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    color: theme.palette.primary.main,
    zIndex: 1,
    [theme.breakpoints.up('lg')]: {
        width: 480,
        height: 480,
    },
    [theme.breakpoints.down('md')]: {
        width: '45%',
        height: '80%',
    },
}));

const Video = styled('video')<{ height?: number }>(({ height }) => ({
    width: '100%',
    height,
    margin: '0 auto',
    borderRadius: 16,
    transform: 'rotateY(180deg)',
}));

const dataPrefixLength = 'data:image/jpeg;base64,'.length;

interface IWithCamConfigurationProps {
    children: ReactNode;
}

function createFaceCheckError(result: IFaceCheckResult, getLabel: (t: string) => string): string {
    if (!result.isFaceDetected) return getLabel('participant.watch.webcamCheckFailed.face');
    if (!result.isCenterPosition) return getLabel('participant.watch.webcamCheckFailed.centerPosition');
    if (!result.isCenterLooking) return getLabel('participant.watch.webcamCheckFailed.centerLooking');
    return getLabel('participant.watch.webcamCheckFailed.unknown');
}

export const WithCamConfiguration: FC<IWithCamConfigurationProps> = ({ children }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isWebcamChecked, setIsWebcamChecked] = useState(false);
    const [height, setHeight] = useState<number>();
    const { t: getLabel } = useTranslation();
    const { webcamRef } = useWebcam();
    const containerRef = useRef<HTMLDivElement>(null);
    const [isFaceChecked, setIsFaceChecked] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [isUserFrameVisible, setIsUserFrameVisible] = useState(false);
    const currentStep = useSelector(getCurrentStep)?.scenarioStep;
    const actionButton = useActionButton();
    const { mutateAsync: checkFace } = participantMutations.useFaceCheck();

    const handleCheckClick = useCallback(async () => {
        if (isWebcamChecked || !webcamRef.current) return;
        actionButton.setDisabled(true);
        const base64screenshot = webcamRef.current.getScreenshot()?.slice(dataPrefixLength);
        if (!base64screenshot) {
            setError(getLabel('participant.watch.webcamCheckFailed.screenshotFailed'));
            actionButton.setDisabled(false);
            return;
        }
        const result = await checkFace({ body: _base64ToArrayBuffer(base64screenshot) });

        if (result.isValid) {
            setIsWebcamChecked(true);
            setIsFaceChecked(true);
            return;
        } else {
            setError(createFaceCheckError(result, getLabel));
        }
        actionButton.setDisabled(false);
    }, [getLabel, isWebcamChecked, checkFace]);

    const onClick = useCallback(() => {
        if (currentStep?.isWebcamCheckRequired && !isFaceChecked) {
            handleCheckClick();
        }
    }, [currentStep?.isWebcamCheckRequired, handleCheckClick, isFaceChecked]);

    const handleResize = useCallback(() => {
        if (containerRef.current) {
            setHeight(containerRef.current.offsetWidth / 1.78);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        setIsFaceChecked(false);
        actionButton.setOnClick(onClick);
        return function clean() {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize, onClick]);
    useEffect(() => {
        actionButton.setDisabled(false);
        setWebcamCheck();
    }, []);
    useEffect(() => {
        if (videoRef.current && webcamRef.current) {
            videoRef.current.srcObject = webcamRef.current.stream;
            setIsUserFrameVisible(true);
        }
    }, [webcamRef.current?.stream?.id]);

    const onUserMedia = useCallback(() => {
        setIsUserFrameVisible(true);
    }, [setIsUserFrameVisible]);

    // TODO: uncomment when BE for disclaimers/all step instructions will be ready
    // const toggleModal = useCallback(() => {
    //     setIsInstructionsOpened(!isInstructionsOpened);
    // }, [isInstructionsOpened]);

    return isWebcamChecked && children ? (
        <> {children}</>
    ) : (
        <>
            {/* <Modal
                open={isInstructionsOpened}
                title={getLabel('participant.cameraCheck.instructionTitle')}
                onClose={toggleModal}
                buttons={
                    <Button variant="contained" color="primary" onClick={toggleModal}>
                        {getLabel('participant.cameraCheck.gotIt')}
                    </Button>
                }
                width={616}
            >
                <Typography variant="p1" component="div">
                    {getLabel('participant.cameraCheck.instruction1')}
                    <br />
                    {getLabel('participant.cameraCheck.instruction2')}
                </Typography>
            </Modal> */}
            <ScenarioPaper
                title={getLabel('participant.cameraCheck.title')}
                alert={error && <Alert type="warning" text={error} />}
                // sx={{
                //     root: {
                //         position: 'relative',
                //         margin: matchesMd ? 'auto' : `0 0 0 ${theme.sidebarWidth}px`,
                //     },
                // }}
            >
                {/* <Typography
                    onClick={toggleModal}
                    variant="b2"
                    color="textSecondary"
                    sx={{ position: 'absolute', top: 44, right: 32, cursor: 'pointer' }}
                >
                    <StyledInfo />
                    {getLabel('participant.cameraCheck.instructionTitle')}
                </Typography> */}
                <div ref={containerRef} style={{ position: 'relative' }}>
                    {isUserFrameVisible && <StyledUser />}
                    <Video ref={videoRef} id="webcam-cast" playsInline autoPlay muted height={height}></Video>
                </div>
            </ScenarioPaper>
        </>
    );
};
