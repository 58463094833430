import { useTranslation } from 'react-i18next';

export const useGenderOptions = () => {
    const { t: getLabel } = useTranslation();
    return [
        { label: getLabel('register.form.gender.male'), value: 'male', idx: 0 },
        { label: getLabel('register.form.gender.female'), value: 'female', idx: 1 },
        { label: getLabel('register.form.gender.nonBinary'), value: 'nonBinary', idx: 2 },
    ];
};
