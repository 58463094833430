import React, { useEffect, useState } from 'react';
import { Instructions } from 'Features/participant/workspace/scenario/components';
import { useRequiredParams } from 'Src/shared/hooks';
import { getIsScenarioInstructionsShown } from 'Features/participant/workspace/scenario/utils';
import { useNavigate } from 'react-router-dom';
import OnlyHeaderLayout from 'Src/features/participant/shared/components/layouts/OnlyHeaderLayout';
import { useAuthContext } from 'Shared/auth/context';

interface IScenarioInstructionsRoutingParams {
    scenarioId: string;
}

export const ParticipantInstructions = () => {
    const [opened, setOpened] = useState(false);
    const { scenarioId } = useRequiredParams<IScenarioInstructionsRoutingParams>();
    const navigate = useNavigate();
    const { userId } = useAuthContext();

    useEffect(() => {
        const isInstructionsShown = getIsScenarioInstructionsShown(scenarioId, userId || '');
        if (isInstructionsShown) {
            navigate(`/me/scenario/${scenarioId}`);
        } else {
            setOpened(true);
        }
    }, [scenarioId, navigate, userId]);
    const onFinish = () => {
        navigate(`/me/scenario/${scenarioId}`);
    };

    return (
        <>
            <OnlyHeaderLayout>
                <Instructions onFinish={onFinish} scenarioId={scenarioId} opened={opened} setOpened={setOpened} />
            </OnlyHeaderLayout>
        </>
    );
};
