import { IScenario, ScenarioState } from 'Features/participant/workspace/types';
import { NotStartedChip, InProgressChip, CompleteChip } from 'Features/participant/workspace/dashboard/components';

export const isScenarioReady = (scenario: IScenario) => scenario.state === ScenarioState.ready;
export const isScenarioUnfinished = (scenario: IScenario) => scenario.state === ScenarioState.started;
export const isScenarioFinished = (scenario: IScenario) => scenario.state === ScenarioState.finished;
export const isScenarioExpired = (scenario: IScenario) => scenario.state === ScenarioState.expired;

export const getChip = (state: ScenarioState) => {
    switch (state) {
        case ScenarioState.ready:
            return NotStartedChip;
        case ScenarioState.started:
            return InProgressChip;
        case ScenarioState.finished:
        default:
            return CompleteChip;
    }
};
