import React, { FC } from 'react';
import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material';
import { FieldRenderProps } from 'react-final-form';

export type SelectInputProps = FieldRenderProps<any> & {
    label: string;
    defaultValue: string;
    imageUrl?: string;
    id: string;
    autoFocus: boolean;
};

export const SelectInput: FC<SelectInputProps> = ({ id, imageUrl, input, meta, name, children, label, sx }) => {
    const labelId = id + '-label';
    return (
        <FormControl fullWidth>
            {imageUrl && <img src={imageUrl} alt="error" style={{ marginBottom: '20px' }} />}
            <InputLabel variant="outlined" id={labelId}>
                {label}
            </InputLabel>
            <Select
                labelId={labelId}
                variant="outlined"
                {...input}
                fullWidth
                label={label}
                error={meta.touched && meta.error}
                inputProps={{
                    name,
                    id,
                }}
                sx={sx}
            >
                {children}
            </Select>
            <FormHelperText>{meta.touched && meta.error}</FormHelperText>
        </FormControl>
    );
};
