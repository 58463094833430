import { Auth0Provider, useAuth0, Auth0ContextInterface, AppState, User } from '@auth0/auth0-react';
import React, { useEffect, useState, useContext, useMemo, useCallback } from 'react';
import { useNavigate, NavigateFunction, Outlet } from 'react-router-dom';

import { WntStrategy } from 'Shared/auth/schemes/wnt';
import { AuthScheme, AuthStrategy, getAuthScheme, Roles, setAuthScheme } from './common';
import { Auth0Strategy } from 'Shared/auth/schemes/auth0';

const minuteMs = 1000 * 60;

export interface AuthContextType extends AuthStrategy {
    scheme?: AuthScheme;
    userId?: string;
    updateScheme: (scheme: AuthScheme) => AuthContextType | undefined;
    getCurrentUserRole: () => keyof typeof Roles | undefined;
}

const AuthContext = React.createContext<AuthContextType>({
    scheme: undefined,
    userId: undefined,
    isLoading: false,
    role: undefined,
    updateScheme: (scheme) => undefined,
    login: (redirectLocation: string) => {},
    getToken: async () => '',
    logout: (redirectLocation: string) => {},
    getCurrentUserRole: () => undefined,
    refreshTokenAndUpdateWorker: () => {},
});

const initialState = {
    scheme: (getAuthScheme() as AuthScheme) || undefined,
    updateScheme: (scheme?: AuthScheme) => undefined,
    isLoading: false,
    role: undefined,
    getToken: async () => '',
    login: (redirectLocation: string) => {},
    logout: (redirectLocation: string) => {},
    getCurrentUserRole: () => undefined,
    refreshTokenAndUpdateWorker: () => {},
};

const strategies = {};

function createStrategies(auth0: Auth0ContextInterface<User>, navigate: any) {
    return {
        auth0: new Auth0Strategy(auth0),
        wnt: new WntStrategy(navigate),
    };
}

const AuthContextProvider: React.FC = ({ children }) => {
    const auth0 = useAuth0();
    const navigate = useNavigate();

    const [authContext, setAuthContext] = useState<AuthContextType>(initialState);

    const strategies: Record<AuthScheme, AuthStrategy> = useMemo(
        () => createStrategies(auth0, navigate),
        [auth0.user, auth0.isLoading, navigate, getAuthScheme(), authContext.role],
    );

    useEffect(() => {
        if (!authContext.scheme) return;
        strategies[authContext.scheme].refreshTokenAndUpdateWorker();

        const timer = setInterval(strategies[authContext.scheme].refreshTokenAndUpdateWorker, minuteMs * 5);

        return function clear() {
            clearInterval(timer);
        };
    }, [authContext.scheme, authContext.userId]);

    const updateScheme = useCallback(
        (scheme) => {
            const strategies = createStrategies(auth0, navigate);
            const newAuthContext = {
                ...authContext,
                ...(scheme ? strategies[scheme!] : {}),
                scheme,
            };
            setAuthScheme(scheme);
            setAuthContext(newAuthContext);
            return newAuthContext;
        },
        [auth0, authContext, navigate],
    );
    return (
        <AuthContext.Provider
            value={{
                ...authContext,
                updateScheme,
                ...(authContext?.scheme ? strategies[authContext.scheme!] : {}),
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => {
    const ctx = useContext(AuthContext);
    return ctx;
};
export const Auth0ProviderWithNavigate: React.FC = ({ children }) => {
    const navigate = useNavigate();

    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

    const onRedirectCallback = (appState?: AppState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    if (!(domain && clientId && redirectUri && audience)) {
        return null;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            useRefreshTokens={true}
            cacheLocation="localstorage"
            authorizationParams={{
                redirect_uri: redirectUri,
                audience,
                scope: 'openid profile email offline_access',
            }}
            onRedirectCallback={onRedirectCallback}
        >
            <AuthContextProvider>
                <Outlet />
            </AuthContextProvider>
        </Auth0Provider>
    );
};
