import { VideoType } from 'Shared/types';
import { UiLocale } from 'Src/localizations/i18n';
import { ScreenerQuestion } from 'Features/manager/shared/queries';

export interface IProgress {
    percents: number;
    seconds: number;
}

export enum StepType {
    presentation = 'presentation',
    landing = 'landing',
    survey = 'survey',
    profiling = 'profiling',
    video = 'video',
    website = 'website',
    calibration = 'calibration',
}

export interface IScenarioStep {
    id: string;
    isRecordingRequired: boolean;
    isWebcamCheckRequired: boolean;
    stepType: StepType;
    stepPosition: number;
}

enum ProfilingType {
    socDem = 'socDem',
    interests = 'interests',
}

export interface IProfilingStep extends IScenarioStep {
    stepType: StepType.profiling;
    profilingType: ProfilingType;
    locale: UiLocale;
    name: string;
    surveyId: string;
}

export type ScenarioStep =
    | IPresentationStep
    | ILandingStep
    | ISurveyStep
    | IProfilingStep
    | IVideoScenarioStep
    | IWebsiteScenarioStep
    | ICalibrationStep;

interface IPresentationStep extends IScenarioStep {
    presentationLink: string;
    stepType: StepType.presentation;
}

interface ILandingStep extends IScenarioStep {
    landingLink: string;
    stepType: StepType.landing;
}

interface ISurveyStep extends IScenarioStep {
    stepType: StepType.survey;
    surveyId: string;
}

export interface IVideoScenarioStep extends IScenarioStep {
    stepType: StepType.video;
    videoForReactionId: string;
    videoType: VideoType;
    requiredWatchedPercentage: number;
}

export interface IWebsiteScenarioStep extends IScenarioStep {
    stepType: StepType.website;
    url: string;
}

interface ICalibrationStep extends IScenarioStep {
    stepType: StepType.calibration;
}

export enum SessionState {
    preview = 'preview',
    recording = 'recording',
    finishing = 'finishing',
}

export enum SourceType {
    video = 'video',
    presentation = 'presentation',
    landing = 'landing',
}

export interface IVideoLinkParams {
    uri: string;
    videoForReactionId: string;
    stepSessionId?: string;
}

export interface IVideoLinkObj {
    link: string;
}

export interface IParticipantCalibrationIds {
    scenarioId: string;
    calibrationId: string;
}

export interface ICalibrationNextVideo {
    width: number;
    height: number;
}

export interface IParticipantCalibratopnStepResponse {
    isAdaptive: boolean;
    nextVideo: ICalibrationNextVideo | null;
    id: string;
    stepPosition: number;
    isWebcamCheckRequired: boolean;
    stepType: StepType;
    isRecordingRequired: boolean;
}

export interface IFaceCheckResult {
    isValid: boolean;
    isFaceDetected: boolean;
    isCenterPosition: boolean;
    isCenterLooking: boolean;
}

export interface IParticipantQuestionResponse {
    questionId: string;
    answer?: string;
    optionId?: number;
}

export interface IParticipantSurveyResponse {
    questionAnswers: IParticipantQuestionResponse[];
    sessionId: string;
}

export interface QuestionSelectedOption {
    screenerQuestionId: string;
    optionId: number;
}

export interface IParticipantScreenerResponse {
    screenerAnswers: QuestionSelectedOption[];
    scenarioSessionId: string;
}

export enum ScenarioSessionState {
    started = 'started',
    finished = 'finished',
    screenOut = 'screenOut',
    quotaFull = 'quotaFull',
}

export type FollowingAction = { actionType: 'redirectToPanel'; redirectUrl: string } | { actionType: 'doNothing' };

export interface IPresentationEvent {
    fromSlide: number;
    toSlide: number;
    atTime: string;
}

export interface IPresentationResponseBody {
    event: IPresentationEvent;
}

export interface ILandingEvent {
    height: number;
    width: number;
    offsetY: number;
    atTime: string;
}

export interface IScenarioResponse {
    id: string;
    name: string;
    isScreenerPassed: boolean;
    isScreenerCompleted: boolean;
    lastScenarioSessionId?: string;
    lastStepDoneId?: string;
    lastStepSessionId?: string;
    lastStepSessionCompleted?: boolean;
    steps: ScenarioStep[];
    screenerQuestions: ScreenerQuestion[];
}
