import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'Src/reducers';
import { VideoType } from 'Features/manager/shared/types/videos/VideoType';
import { DataCollectionMethod, DataCollectionMethodType } from 'Pages/manager/scenarios/types/DataCollectionMethod';
import { UiLocale } from 'Src/localizations/i18n';

export enum StepType {
    survey = 'survey',
    profiling = 'profiling',
    presentation = 'presentation',
    landing = 'landing',
    video = 'video',
    calibration = 'calibration',
    website = 'website',
}

interface IStep {
    id: string;
    stepPosition: number;
    isWebcamCheckRequired: boolean;
    isRecordingRequired: boolean;
}

export interface IVideoStep extends IStep {
    stepType: StepType.video;
    name: string;
    isGazeRequired: boolean;
    requiredWatchedPercentage: number;
    videoType: VideoType;
    videoReactionSource: {
        id: string;
        name: string;
        duration: string;
        width: number;
        height: number;
        edl?: File;
        file?: File;
        link?: string;
    };
}

interface IVideoReactionSource {
    videoType: VideoType;
    name: string;
    edl?: File;
}

export interface IYoutubeReactionSource extends IVideoReactionSource {
    link: string;
}

export interface ICustomWatermarkedVideoReactionSource extends IVideoReactionSource {
    duration: string;
    file: File;
    width: number;
    height: number;
}

export type VideoReactionSource = ICustomWatermarkedVideoReactionSource | IYoutubeReactionSource;

export interface ICalibrationStep extends IStep {
    stepType: StepType.calibration;
    isRecordingRequired: true;
    name: string;
    isAdaptive: boolean;
}

export interface ISurveyStep extends IStep {
    stepType: StepType.survey;
    isRecordingRequired: boolean;
    surveyId: string;
    name: string;
}

export enum ProfilingType {
    socDem = 'socDem',
    interests = 'interests',
}
export interface IProfilingStep extends IStep {
    stepType: StepType.profiling;
    id: string;
    isRecordingRequired: boolean;
    profilingType: ProfilingType;
    locale: UiLocale;
    name: string;
}

export interface IPresentationStep extends IStep {
    stepType: StepType.presentation;
    file: File;
    name: string;
}

export interface ILandingStep extends IStep {
    stepType: StepType.landing;
    file: File;
    name: string;
}

export interface IWebsiteStep extends IStep {
    stepType: StepType.website;
    url: string;
    name: string;
}

export type EditScenarioStep =
    | ISurveyStep
    | IProfilingStep
    | IPresentationStep
    | ILandingStep
    | IWebsiteStep
    | IVideoStep
    | ICalibrationStep;

interface IStepsListReducerState {
    steps: EditScenarioStep[];
    dcms: DataCollectionMethod[];
}

const initialState: IStepsListReducerState = {
    steps: [],
    dcms: [],
};

export const managerEditScenarioSlice = createSlice({
    name: 'editScenario',
    initialState,
    reducers: {
        addSteps(state, action: PayloadAction<EditScenarioStep[]>) {
            state.steps = [...action.payload];
        },
        addDataCollectionMethods(state, action: PayloadAction<DataCollectionMethod[]>) {
            state.dcms = [...action.payload];
        },
    },
});

export const getSteps = (state: AppState) => state.managerEditScenario.steps;
export const getSurveySteps = (state: AppState): ISurveyStep[] =>
    state.managerEditScenario.steps.filter(
        (step: EditScenarioStep) => step.stepType === StepType.survey,
    ) as ISurveyStep[];
export const getCalibrationSteps = (state: AppState): ICalibrationStep[] =>
    state.managerEditScenario.steps.filter(
        (step: EditScenarioStep) => step.stepType === StepType.calibration,
    ) as ICalibrationStep[];
export const getDcms = (state: AppState) => state.managerEditScenario.dcms;
export const getFocusGroups = (state: AppState) =>
    state.managerEditScenario.dcms.filter(
        (dcm: DataCollectionMethod) => dcm.type === DataCollectionMethodType.focusGroup,
    );
export const getOpenGroups = (state: AppState) =>
    state.managerEditScenario.dcms.filter((dcm) => dcm.type === DataCollectionMethodType.openGroup);

export const {
    actions: { addSteps, addDataCollectionMethods },
    reducer,
} = managerEditScenarioSlice;
