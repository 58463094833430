import { ECharts } from 'echarts';

export function downloadBlob(blob: Blob, filename: string) {
    const objectUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = objectUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
}

export const getSvgBlob = (chartInstance: ECharts) => {
    const svgRows = (chartInstance.renderToSVGString() as string).split('\n');
    svgRows.splice(
        1,
        0,
        '<defs><style type="text/css">@import url(http://fonts.googleapis.com/css?family=Manrope);</style></defs>',
    );
    return new Blob([svgRows.join('\n')], { type: 'image/svg+xml' });
};

export const hasScene =
    (scene: string) =>
    ({ sceneName }: { sceneName: string }) =>
        sceneName === scene;
