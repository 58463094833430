import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Footer, Button, useFooterButtonsSize } from '@watched-tech/wantent-ui';
import { ReactComponent as Leave } from 'Assets/svg/leave.svg';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRight } from 'Assets/svg/arrowRight.svg';
import { useActionButton } from 'Features/participant/workspace/scenario/hooks/useActionButton';
import { useSelector } from 'react-redux';
import { getMobileView } from 'Features/participant/slice';
import { MobileFooter } from 'Features/participant/shared/components/MobileFooter';
import {
    getCurrentStep,
    getScreenshareStatus,
    ScreenshareStatus,
} from 'Src/features/participant/workspace/scenario/slice';
import { StepType } from 'Src/features/participant/workspace/scenario/types';

enum FooterButtonState {
    start = 'start',
    finish = 'finish',
}

const ParticipantFooter = ({ isVirtualKeyboardShown = false }: any) => {
    const { t: getLabel } = useTranslation();
    const [presentationLandingState, setPresentationLandingState] = useState(FooterButtonState.start);
    const [websiteState, setWebsiteState] = useState(FooterButtonState.start);
    const { label, disabled, onClick } = useActionButton();
    const showMobileView = useSelector(getMobileView);
    const size = useFooterButtonsSize();
    const currentStep = useSelector(getCurrentStep);
    const navigate = useNavigate();
    const screenShareStatus = useSelector(getScreenshareStatus);
    useEffect(() => {
        if (screenShareStatus === ScreenshareStatus.finished) {
            setWebsiteState(FooterButtonState.start);
        }
    }, [screenShareStatus]);
    const leaveToMain = useCallback(() => {
        navigate('/me');
    }, [navigate]);
    const stepType = currentStep?.type;
    useEffect(() => {
        setPresentationLandingState(FooterButtonState.start);
        setWebsiteState(FooterButtonState.start);
    }, [stepType]);

    const buttonLabel = label ?? getLabel('participant.common.next');
    const isPresentationOrLandingStep = stepType === StepType.presentation || stepType === StepType.landing;
    const isWebsiteStep = stepType === StepType.website;
    const nextBtnTestId = `${stepType}-${
        isPresentationOrLandingStep ? presentationLandingState : isWebsiteStep ? websiteState : 'next'
    }-btn`;
    const handleClick = () => {
        onClick();
        setPresentationLandingState(FooterButtonState.finish);
        setWebsiteState(FooterButtonState.finish);
    };
    return showMobileView ? (
        <MobileFooter
            sx={{
                footer: {
                    display: isVirtualKeyboardShown ? 'none' : 'flex',
                },
            }}
        >
            <Button
                dataAttrs={{
                    'data-testid': nextBtnTestId,
                }}
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleClick}
            >
                {buttonLabel}
            </Button>
        </MobileFooter>
    ) : (
        <Footer
            leftButtonsOptions={[
                {
                    type: 'button',
                    startIcon: <Leave />,
                    variant: 'outlined',
                    color: 'primary',
                    onClick: leaveToMain,
                    size,
                    children: getLabel('participant.layout.leave'),
                    dataAttrs: {
                        'data-testid': 'leave-survey-btn',
                    },
                },
            ]}
            rightButtonsOptions={[
                {
                    type: 'submit',
                    size,
                    variant: 'contained',
                    color: 'primary',
                    disabled,
                    onClick: handleClick,
                    endIcon: <ArrowRight />,
                    children: buttonLabel,
                    dataAttrs: {
                        'data-testid': nextBtnTestId,
                    },
                },
            ]}
        />
    );
};

export default ParticipantFooter;
