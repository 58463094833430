import React from 'react';
import { CircularProgress } from '@mui/material';
import { YoutubePlayer } from 'Features/participant/workspace/scenario/components/YoutubePlayer';
import { useVideoRecorder } from 'Features/participant/workspace/scenario/hooks';
import { participantQueries } from 'Features/participant/workspace/scenario/queries';
import { infoSnack, errorSnack } from 'Shared/components/snackbars/Snackbar';
import { useTranslation } from 'react-i18next';
import { ScenarioPaper } from '../shared/ScenarioPaper';

interface IYoutubeRecorderProps {
    video: {
        videoForReactionId: string;
        requiredWatchedPercentage: number;
    };
}

export const YoutubeVideoRecorder: React.FC<IYoutubeRecorderProps> = ({
    video: { videoForReactionId, requiredWatchedPercentage },
}) => {
    const { t: getLabel } = useTranslation();
    const {
        data: youtubeVideoObj,
        error: getYoutubeVideoObjError,
        isLoading,
    } = participantQueries.useYoutubeVideoLinkObj(videoForReactionId, {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
    const { isWebcamReady, errorMessage } = useVideoRecorder(requiredWatchedPercentage);

    return (
        <ScenarioPaper title={getLabel('participant.video.title')}>
            {isLoading && <CircularProgress />}
            {getYoutubeVideoObjError instanceof Error && errorSnack(getYoutubeVideoObjError.message)}
            {youtubeVideoObj?.link && (
                <>
                    {!isWebcamReady && infoSnack(getLabel('participant.watch.acceptwebcam'))}
                    {errorMessage && errorSnack(errorMessage)}
                    {isWebcamReady && !errorMessage && <YoutubePlayer link={youtubeVideoObj?.link} />}
                </>
            )}
        </ScenarioPaper>
    );
};
