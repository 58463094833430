import React from 'react';
import { styled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import { FieldRenderProps } from 'react-final-form';

const Container = styled('div')({
    display: 'flex',
    justifyContent: 'center',
});
export function SliderInput(
    props: FieldRenderProps<number> & { label: string; min: number; max: number; options: { name: string; id: any }[] },
) {
    return (
        <Container>
            <Slider
                sx={{
                    maxWidth: '520px',
                }}
                value={props.input.value}
                onChange={(event, value) => {
                    props.input.onChange(value);
                }}
                min={props.min}
                max={props.max}
                marks={[
                    { value: props.min, label: props.min },
                    { value: props.max, label: props.max },
                ]}
                valueLabelDisplay="auto"
            />
        </Container>
    );
}
