import { Button, useMediaQuery } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import throttle from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { apiClient } from 'Utils/axios';
import { ReactComponent as ArrowRight } from 'Assets/svg/arrowRight.svg';
import { HidingPanel } from 'Features/participant/workspace/scenario/components/shared/HidingPanel';
import {
    finishStep,
    getCurrentStep,
    getStepperIds,
    setActionButton,
} from 'Features/participant/workspace/scenario/slice';
import { SessionState } from '../../../types';
import { chunkDurationSec } from 'Features/participant/workspace/scenario/constants';
import { format } from 'date-fns';
import { toUTC } from 'Utils/time';
import { ScenarioPaper } from '../../shared/ScenarioPaper';
import { useRecorder, useScreenRecorder } from 'Features/participant/workspace/scenario/hooks';
import { IWebsiteRecorderProps, IWebsiteSessionEvent } from './types';
import { Alert, Footer, useFooterButtonsSize, useWantentTheme } from '@watched-tech/wantent-ui';
import { Container, Iframe } from './styles';
import { participantMutations } from 'Features/participant/workspace/scenario/queries';

const WebsiteRecorder = ({ url }: IWebsiteRecorderProps) => {
    const { t: getLabel } = useTranslation();
    const dispatch = useDispatch();
    const [sessionState, setSessionState] = useState<SessionState>(SessionState.preview);
    const [scheduledEvents, setScheduledEvents] = useState<Array<IWebsiteSessionEvent>>([]);
    const stepperIds = useSelector(getStepperIds);
    const currentStep = useSelector(getCurrentStep)?.scenarioStep;
    const [sessionId] = useState<string | undefined>(stepperIds?.stepSessionId);
    const [isControlsShown, setIsControlsShown] = useState<boolean>(true);
    const [isStarted, setIsStarted] = useState<boolean>(false);
    const [controlsDissapearTimeout, setControlsDissapearTimeout] = useState<number>();
    const [recordingStartTime, setRecordingStartTime] = useState<number>();
    const [isFinished, setIsFinished] = useState<boolean>(false);
    const [isInitiaslIframeRender, setIsInitialIframeRender] = useState<boolean>(true);
    const { recorder, finishReactionRecording, stream } = useRecorder();
    const theme = useWantentTheme();
    const matchesLg = useMediaQuery(theme.breakpoints.down('lg'));
    const { error, screenRecorder, finishScreenRecording, isFullscreen, setIsFullscreen } = useScreenRecorder();
    const { mutateAsync: finishSession } = participantMutations.useFinishScenarioStepSession();
    const { mutateAsync: sendEvents } = participantMutations.useSendWebsiteSessionEvents();
    const size = useFooterButtonsSize();

    const handleRecorderStart = () => {
        setRecordingStartTime(new Date().getTime());
    };
    useEffect(() => {
        return function clear() {
            clearTimeout(controlsDissapearTimeout);
        };
    }, [controlsDissapearTimeout]);
    useEffect(() => {
        if (error.message) {
            recorder?.state !== 'inactive' && recorder?.stop();
            screenRecorder?.state !== 'inactive' && screenRecorder?.stop();
        }
    }, [error, recorder, screenRecorder]);
    useEffect(() => {
        if (isFinished && scheduledEvents.length) {
            sendEvents({
                body: { events: scheduledEvents },
            });
            setScheduledEvents([]);
        }
    }, [isFinished, scheduledEvents, sessionId]);

    const handleFinishClick = useCallback(() => {
        if (!sessionId) throw new Error('Session id cannot be undefined');
        setSessionState(SessionState.finishing);
        dispatch(setActionButton({ disabled: true }));
        setIsFinished(true);
        finishReactionRecording(currentStep?.id);
        finishScreenRecording();
        finishSession({ urlParams: { id: sessionId } }).then(() => {
            dispatch(finishStep());
        });
        dispatch(
            setActionButton({
                onClick: undefined,
                label: undefined,
            }),
        );
    }, [currentStep?.id, dispatch, finishReactionRecording, finishScreenRecording, sessionId]);
    const handleStart = useCallback(() => {
        setIsFullscreen(true);
        setSessionState(SessionState.recording);
        if (recorder?.state !== 'recording') recorder?.start(chunkDurationSec * 1000);
        screenRecorder?.start(chunkDurationSec * 1000);
        setIsStarted(true);
        dispatch(
            setActionButton({
                onClick: handleFinishClick,
                label: getLabel('participant.scenarios.finish'),
            }),
        );
    }, [recorder, screenRecorder, dispatch, getLabel, handleFinishClick, setIsFullscreen]);
    useEffect(() => {
        if (!isStarted || (isStarted && !isFullscreen)) {
            dispatch(
                setActionButton({
                    onClick: handleStart,
                    label: getLabel('participant.scenarios.start'),
                    disabled: !!error.message,
                }),
            );
        }
    }, [dispatch, getLabel, handleStart, isStarted, isFullscreen, screenRecorder, error]);
    useEffect(() => {
        if (recorder?.state === 'inactive') {
            recorder.onstart = handleRecorderStart;
        }
    }, [recorder]);
    useEffect(() => {
        if (!isFullscreen && isControlsShown) {
            setIsControlsShown(false);
        }
    }, [isFullscreen, isControlsShown]);
    useEffect(() => {
        return function clear() {
            clearTimeout(controlsDissapearTimeout);
        };
    }, [controlsDissapearTimeout]);
    const handleIframeLoaded = async () => {
        if (!isInitiaslIframeRender && recordingStartTime) {
            const diff = new Date().getTime() - recordingStartTime;
            setScheduledEvents([
                ...scheduledEvents,
                {
                    atTime: format(+toUTC(new Date(diff)), 'HH:mm:ss.SSS'),
                    eventType: 'pageChanged',
                },
            ]);
        }
        setIsInitialIframeRender(false);
    };
    const handleMouseMove = throttle(
        () => {
            clearTimeout(controlsDissapearTimeout);
            setIsControlsShown(true);
            const timeoutId = window.setTimeout(() => {
                setIsControlsShown(false);
            }, 2000);
            setControlsDissapearTimeout(timeoutId);
        },
        200,
        { leading: true },
    );
    const controls = (
        <HidingPanel isShown={sessionState === SessionState.recording ? isControlsShown : false}>
            <>
                <Footer
                    sx={{
                        root: {
                            position: isFullscreen ? 'fixed' : 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            background: 'teal',
                        },
                    }}
                    rightButtonsOptions={[
                        {
                            type: 'submit',
                            size,
                            variant: 'contained',
                            color: 'primary',
                            disabled: sessionState === SessionState.finishing,
                            onClick: handleFinishClick,
                            endIcon: <ArrowRight />,
                            children: getLabel('participant.scenarios.finish'),
                        },
                    ]}
                />
            </>
        </HidingPanel>
    );
    return (
        <ScenarioPaper
            title={getLabel('participant.website.title')}
            alert={
                error.message ? (
                    <Alert
                        type="error"
                        text={error.message}
                        button={
                            error.isFirefoxError ? undefined : (
                                <Button
                                    variant="text"
                                    color="primary"
                                    size="medium"
                                    onClick={() => {
                                        window.location.reload();
                                    }}
                                >
                                    {getLabel('participant.website.rerequestScreenShare')}
                                </Button>
                            )
                        }
                    />
                ) : undefined
            }
        >
            {stream && (
                <div onMouseMove={handleMouseMove}>
                    <Container isFullscreen={isFullscreen} matchesLg={matchesLg}>
                        <Iframe onLoad={handleIframeLoaded} title={url} src={url} />
                    </Container>
                    {controls}
                </div>
            )}
        </ScenarioPaper>
    );
};
export default WebsiteRecorder;
