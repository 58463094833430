import { replaceAtIndex } from 'Utils/functools';
import { MultipartUploader } from 'Shared/services/MultipartUploader';
import { etag } from 'Utils/global';

export enum PartStatus {
    preparing = 'preparing',
    uploading = 'uploading',
    completed = 'completed',
}

export interface IStepSessionInfo {
    stepSessionId: string;
    scenarioSessionId: string;
    stepId: string;
}

export enum UploadType {
    reaction = 'reaction',
    screenShare = 'screenShare',
}

interface IPart {
    partId: number;
    task?: Promise<void>;
    status: PartStatus;
}

export interface IUpload {
    scenarioSessionId: string;
    uploadName: string;
    stepId: string;
    stepSessionId: string;
    nextPart: number;
    finished: boolean;
    started: boolean;
    blobStack: Blob[];
    parts: IPart[];

    addBlob: (blob: Blob) => void;

    start(): Promise<void>;

    finish(): Promise<void>;

    addPartUploadingTask(targetPartId: number, task: Promise<string>): void;
}

const minUploadSize = 5 * 1024 * 1024;

export class SessionUpload implements IUpload {
    blobStack: Blob[];
    nextPart: number;
    finished: boolean;
    started: boolean;
    parts: IPart[];
    scenarioSessionId: string;
    stepId: string;
    stepSessionId: string;
    uploadName: string;
    private uploader: MultipartUploader<{ sessionId: string }>;
    private onPartsUpdated: () => void;

    constructor(
        sessionInfo: IStepSessionInfo,
        uploadName: string,
        reactionUploader: MultipartUploader<{ sessionId: string }>,
        onUpdated: () => void,
    ) {
        this.onPartsUpdated = onUpdated;
        this.blobStack = [];
        this.nextPart = 1;
        this.finished = false;
        this.started = false;
        this.parts = [];
        this.scenarioSessionId = sessionInfo.scenarioSessionId;
        this.stepId = sessionInfo.stepId;
        this.stepSessionId = sessionInfo.stepSessionId;
        this.uploadName = uploadName;
        this.uploader = reactionUploader;
    }

    finish = async (): Promise<void> => {
        await this.sendNextPart(new Blob([...this.blobStack]));
        await Promise.all(this.parts.map((p) => p.task).filter((task) => task !== undefined) as Promise<void>[]).then(
            async (etagPromises) => {
                await Promise.all(etagPromises).then(this.waitUploadAndFinish);
            },
        );
        this.onPartsUpdated();
    };

    start = async () => {
        await this.uploader.start({ sessionId: this.stepSessionId }, { uploadName: this.uploadName });
        this.started = true;
    };

    private blobStackSize() {
        return this.blobStack.reduce((acc, blob) => acc + blob.size, 0);
    }

    addBlob = async (blob: Blob) => {
        if (this.blobStackSize() + blob.size < minUploadSize) {
            this.blobStack = this.blobStack?.concat([blob]);
            return;
        }

        // blob stack size >= 5mb
        await this.sendNextPart(blob);
    };

    sendNextPart = async (blob: Blob) => {
        // blob stack size >= 5mb
        const mergedBlob = new Blob([...this.blobStack, blob]);
        if (!this.started) {
            await this.start();
        }

        const currentPart = this.nextPart;
        this.preparePartUpload(currentPart);
        const task = this.uploader
            .uploadPart(
                { sessionId: this.stepSessionId },
                {
                    partNumber: currentPart,
                    etag: await etag(mergedBlob),
                    bytes: mergedBlob.size,
                },
                mergedBlob,
                {
                    uploadName: this.uploadName,
                },
            )
            .then((etag: string) => {
                this.setPartCompleted(currentPart);
                return etag;
            });
        this.addPartUploadingTask(currentPart, task);
    };

    preparePartUpload(currentPart: number) {
        this.nextPart = currentPart + 1;
        this.blobStack = [];
        this.parts = [...this.parts, { partId: currentPart, status: PartStatus.preparing }];
    }

    addPartUploadingTask(targetPartId: number, task: Promise<string>): void {
        const targetPartIdx = this.parts.findIndex((p) => p.partId === targetPartId);
        const updated = Object.assign({}, this.parts[targetPartIdx], { task });

        this.parts = replaceAtIndex(this.parts, targetPartIdx, updated);
    }

    private setPartCompleted(partId: number) {
        const targetPartIdx = this.parts.findIndex((p) => p.partId === partId);
        this.parts = replaceAtIndex(this.parts, targetPartIdx, {
            ...this.parts[targetPartIdx],
            status: PartStatus.completed,
        });
        this.onPartsUpdated();
    }

    private waitUploadAndFinish = async () => {
        if (!this.started) {
            throw new Error('');
        }

        await this.uploader.finish({ sessionId: this.stepSessionId }, { uploadName: this.uploadName });

        this.finished = true;
    };
}
