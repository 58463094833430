import React, { ReactNode, useContext } from 'react';
import ParticipantHeader from 'Features/participant/shared/components/Header';
import ParticipantFooter from 'Features/participant/shared/components/Footer';
import { Background, FixedHeightContent } from './styles';
import { VirtualKeyboardContext } from 'Features/participant/workspace/scenario/context/virtualKeyboardContext/VirtualKeyboardProvider';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { getMobileView } from 'Features/participant/slice';

// fixed height & overflow
interface IScenarioLayoutProps {
    children: ReactNode;
}
const ScenarioLayout = ({ children }: IScenarioLayoutProps) => {
    const ctx = useContext(VirtualKeyboardContext);
    const showMobileView = useSelector(getMobileView);
    return (
        <Background>
            <>
                <ParticipantHeader isAuthenticated />
                <FixedHeightContent
                    isVirtualKeyboardShown={isMobile ? ctx?.isKeyboardShown : false}
                    showMobileView={showMobileView}
                >
                    {children}
                </FixedHeightContent>
                <ParticipantFooter isVirtualKeyboardShown={isMobile ? ctx?.isKeyboardShown : false} />
            </>
        </Background>
    );
};

export default ScenarioLayout;
