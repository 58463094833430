import React, { useEffect, useState } from 'react';
import { useElementSize } from 'usehooks-ts';
import { styled } from '@mui/material/styles';
import { ReactComponent as Play } from 'Assets/svg/play.svg';
import { VIDEO_WIDTH_TO_HEIGHT_RATIO } from 'Features/participant/workspace/scenario/constants';

const StyledPlay = styled(Play)(({ theme }) => ({
    width: 80,
    height: 80,
    color: theme.palette.gray.x9,
    cursor: 'pointer',
}));

const StyledWrapper = styled('div')<{ height: number }>(({ theme, height }) => ({
    width: '100%',
    height,
    background: '#000',
    display: 'flex',
    borderRadius: 16,
    justifyContent: 'center',
}));

const StyledVideoContainer = styled('div')<{ width: number | string }>(({ width }) => ({
    width,
    height: '100%',
    background: '#0b1537',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));
interface IFakeVideoProps {
    isAdaptive: boolean;
    nextVideoWidth?: number;
    nextVideoHeight?: number;
    onClick: (event: any) => void;
}

interface IFakeVideoDimensions {
    outerHeight: number;
    innerWidth: number | string;
}

export const FakeVideo: React.FC<IFakeVideoProps> = ({ isAdaptive, nextVideoWidth, nextVideoHeight, onClick }) => {
    const [containerRef, { width }] = useElementSize();
    const [dimensions, setDimensions] = useState<IFakeVideoDimensions>({ outerHeight: 0, innerWidth: 0 });

    useEffect(() => {
        const outerHeight = width / VIDEO_WIDTH_TO_HEIGHT_RATIO;
        if (isAdaptive && nextVideoWidth && nextVideoHeight) {
            const uploadedVideoRatio = +(nextVideoWidth / nextVideoHeight).toFixed(2);
            const innerWidth = uploadedVideoRatio * outerHeight;
            setDimensions({ outerHeight, innerWidth });
            return;
        }
        setDimensions({ outerHeight, innerWidth: '100%' });
    }, [nextVideoWidth, nextVideoHeight, width, isAdaptive]);
    return (
        <StyledWrapper height={dimensions.outerHeight} ref={containerRef}>
            <StyledVideoContainer width={dimensions.innerWidth}>
                <StyledPlay onClick={onClick} data-testid="calibration-play-icon" />
            </StyledVideoContainer>
        </StyledWrapper>
    );
};
