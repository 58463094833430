import { styled } from '@mui/material/styles';
import { ReactComponent as NotFound } from 'Assets/svg/404.svg';
import { ReactComponent as Cat } from 'Assets/svg/catInBox.svg';

export const Container = styled('div')(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box',
    background: theme.palette.gradient.background,
    [theme.breakpoints.down('md')]: {
        minHeight: '100%',
        height: 'auto',
        padding: `${theme.spacing(12)}`,
    },
}));

const mdPaddings = 48;
const catImgAspectRatio = 1.3;

export const Content = styled('div')(({ theme }) => ({
    position: 'relative',
    height: 515,
    [theme.breakpoints.down('md')]: {
        height: `calc((100vw - ${mdPaddings}px) / ${catImgAspectRatio})`,
    },
}));

export const FourOFour = styled(NotFound)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-118px, 0px)',
    width: 236,
    height: 101,
    [theme.breakpoints.down('sm')]: {
        width: 169,
        height: 72,
        transform: 'translate(-84.5px, 0px)',
    },
}));
export const CatInBox = styled(Cat)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-339px, -257.5px)',
    width: 678,
    height: 515,
    [theme.breakpoints.down('md')]: {
        width: '100%',
        height: 'auto',
        transform: 'translate(-50%, -50%)',
    },
}));

export const TypographyWrapper = styled('div')(({ theme }) => ({
    margin: 'auto',
    position: 'absolute',
    left: '0',
    right: 0,
    bottom: theme.spacing(8),
    width: 'max-content',
    [theme.breakpoints.down('sm')]: {
        position: 'static',
        marginTop: theme.spacing(6),
        marginBottom: 0,
        width: '100%',
    },
}));
