import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UiLocale, useLocale } from 'Src/localizations/i18n';
import { LinearProgress } from '@mui/material';
import RegisterLayout from 'Features/participant/register/components/shared/RegisterLayout';
import RegisterPanelGroupForm from 'Features/participant/register/components/RegisterPanelGroupForm';
import { panelRespondentLogin, participantQueries } from 'Features/participant/register/queries';
import { useRoutingParams } from 'Src/shared/hooks';
import { signInWntUserWithTokens } from 'Shared/auth/schemes/wnt';
import { useAuthContext } from 'Shared/auth/context';
import { useMutation } from 'react-query';

interface JoinPanelGroupPathParams extends Record<string, string> {
    pgid: string;
    panelRespondentId: string;
    preferredLocale: UiLocale;
}

function useJoinTemplateUrlGroup() {
    const navigate = useNavigate();
    const queryParams = useRoutingParams<JoinPanelGroupPathParams>();
    const { pgid, preferredLocale, panelRespondentId, ...stateQueryParams } = queryParams;
    const auth = useAuthContext();
    useLocale(preferredLocale);

    const {
        mutate: panelLogin,
        isLoading: isLoggingIn,
        isError: isLoginError,
        error: loginError,
    } = useMutation(panelRespondentLogin, {
        onSuccess: (tokens) => {
            signInWntUserWithTokens(tokens.accessToken, tokens.refreshToken, auth);
            auth.updateScheme('wnt');
            navigate('/me');
        },
    });
    const {
        isLoading: isPanelGroupLoading,
        data,
        error: panelGroupError,
        isError: isPanelGroupError,
    } = participantQueries.useTemplateUrlPanelGroup(pgid, panelRespondentId, {
        onSuccess: (res) => {
            const incomingKeys = Object.keys(stateQueryParams);
            const missingKeys = res.stateQueryParams.filter((key) => !incomingKeys.includes(key));
            if (missingKeys.length) {
                navigate(`/invalid_link?missingKeys=${missingKeys.join(',')}`);
            }

            if (res.isUserExists) {
                panelLogin({
                    body: {
                        panelParticipantId: panelRespondentId,
                        panelGroupId: pgid,
                        stateQueryParams,
                    },
                });
            }
        },
    });
    return {
        pgid,
        panelRespondentId,
        isLoading: isPanelGroupLoading || isLoggingIn,
        isError: isPanelGroupError || isLoginError,
        error: panelGroupError || loginError,
        stateQueryParams,
    };
}

const JoinTemplateUrlPanelGroup = (): JSX.Element => {
    const { pgid, panelRespondentId, isLoading, isError, error, stateQueryParams } = useJoinTemplateUrlGroup();

    if (isLoading) {
        return (
            <RegisterLayout>
                <LinearProgress sx={{ position: 'absolute', top: 0, right: 0, left: 0, height: 2 }} />
            </RegisterLayout>
        );
    }

    if (!panelRespondentId) {
        return <p>panelRespondentId or panel respondent indicator is required</p>;
    }
    if (isError) {
        return <p>{(error as any).response.data.message?.toString()}</p>;
    }
    return (
        <RegisterLayout>
            <RegisterPanelGroupForm
                panelGroupId={pgid}
                panelRespondentId={panelRespondentId}
                stateQueryParams={stateQueryParams}
            />
        </RegisterLayout>
    );
};

export default JoinTemplateUrlPanelGroup;
