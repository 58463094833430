import React from 'react';
import { Container } from './styles';
import { useTranslation } from 'react-i18next';
import WantentMarkdown from 'Features/participant/shared/components/markdowns/WantentMarkdown';
import en from './en.md';
import uk from './uk.md';
import ru from './ru.md';
import OnlyHeaderLayout from 'Features/participant/shared/components/layouts/OnlyHeaderLayout';
import { useAuthContext } from 'Shared/auth/context';
import { ParticipantRoles } from 'Shared/auth/common';

const markdowns = {
    en: en,
    uk: uk,
    ru: ru,
    kk: en,
    nl: en,
    pl: en,
};

export default function PrivacyPolicy() {
    const {
        i18n: { language },
    } = useTranslation();
    const auth = useAuthContext();
    const isAuthenticated = !!auth.userId;
    return (
        <OnlyHeaderLayout isAuthenticated={isAuthenticated && auth.role && ParticipantRoles.includes(auth.role)}>
            <Container>
                <WantentMarkdown>{markdowns[language]}</WantentMarkdown>
            </Container>
        </OnlyHeaderLayout>
    );
}
