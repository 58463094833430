import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from 'Pages/app/App';
import RegisterLink from 'Pages/participant/register/RegisterLink';
import ParticipantPrivacyPolicy from 'Pages/participant/PrivacyPolicy';
import ParticipantContentProtection from 'Pages/participant/ContentProtection';
import ParticipantSurveyDisclaimer from 'Pages/participant/register/SurveyDisclaimer';
import JoinPanelGroup from 'Pages/participant/register/JoinPanelGroup';
import JoinOpenGroup from 'Pages/participant/register/JoinOpenGroup';
import { NotFound } from './features/participant/shared/components/NotFound/index';
import { WantentProvider } from 'Shared/components';
import { adminRoutes, managerRoutes, participantRoutes } from 'Src/routes';
import { ParticipantRoute } from 'Shared/components/router';
import { LoginCallback, LoginRoleRedirectCallback } from 'Pages/participant/LoginCallback';
import { Auth0ProviderWithNavigate } from 'Shared/auth/context';
import JoinTemplateUrlPanelGroup from 'Pages/participant/register/JoinTemplateUrlPanelGroup';

export const router = createBrowserRouter([
    {
        element: <Auth0ProviderWithNavigate />,
        children: [
            {
                path: '/',
                element: <App />,
            },
            // {
            //     path: 'login',
            //     element: <LoginPage />,
            // },
            {
                path: 'join_pg',
                element: (
                    <WantentProvider>
                        <JoinPanelGroup />
                    </WantentProvider>
                ),
            },
            {
                path: 'join_tpg',
                element: (
                    <WantentProvider>
                        <JoinTemplateUrlPanelGroup />
                    </WantentProvider>
                ),
            },
            {
                path: 'register_link',
                element: <RegisterLink />,
            },
            {
                path: 'join_og',
                element: (
                    <WantentProvider>
                        <JoinOpenGroup />
                    </WantentProvider>
                ),
            },
            {
                path: 'privacy_policy',
                element: (
                    <WantentProvider>
                        <ParticipantPrivacyPolicy />
                    </WantentProvider>
                ),
            },
            {
                path: 'content_protection_rules',
                element: (
                    <WantentProvider>
                        <ParticipantContentProtection />
                    </WantentProvider>
                ),
            },
            {
                path: 'survey-disclaimer',
                element: (
                    <ParticipantRoute>
                        <WantentProvider>
                            <ParticipantSurveyDisclaimer />
                        </WantentProvider>
                    </ParticipantRoute>
                ),
            },
            participantRoutes,
            managerRoutes,
            adminRoutes,
            {
                path: 'callback',
                element: (
                    <WantentProvider>
                        <LoginCallback />
                    </WantentProvider>
                ),
            },
            {
                path: 'login_from_home_callback',
                element: (
                    <WantentProvider>
                        <LoginRoleRedirectCallback />
                    </WantentProvider>
                ),
            },
            {
                path: '*',
                element: (
                    <WantentProvider>
                        <NotFound />
                    </WantentProvider>
                ),
            },
        ],
    },
]);

function AppRouter() {
    return <RouterProvider router={router} />;
}

export default AppRouter;
