import {
    getMutationCallback,
    getQueryCallback,
    IMutationCallbackProps,
    IMutationRequestParams,
    IQueryCallbackProps,
} from 'Utils/query';
import { useMutation, useQuery } from 'react-query';
import { MutationFunction, MutationKey, QueryFunction, QueryKey } from 'react-query/types/core/types';
import { UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult } from 'react-query/types/react/types';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useAuthContext } from 'Shared/auth/context';
import { merge } from 'lodash';

export const queries = {
    getDashboardScenarios: {
        url: '/api/me/dashboard/scenarios',
    },
};
