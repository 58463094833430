import React from 'react';
import WrongDeviceScreenOutLayout from 'Features/participant/workspace/scenario/components/shared/layouts/WrongDeviceScreenOutLayout';
import catScreenOut from 'Assets/svg/catScreenOut.svg';
import { useTranslation } from 'react-i18next';

const ScreenOut = () => {
    const { t: getLabel } = useTranslation();
    return (
        <WrongDeviceScreenOutLayout
            title={getLabel('participant.screenOut.diffAudience')}
            description={getLabel('participant.screenOut.diffAudienceDescr')}
            imageSrc={catScreenOut}
            imageAlt="Calm cat"
            dataAttrs={{
                'data-testid': 'screenout-paper',
            }}
        />
    );
};

export default ScreenOut;
