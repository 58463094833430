import React from 'react';
import WrongDeviceScreenOutLayout from 'Features/participant/workspace/scenario/components/shared/layouts/WrongDeviceScreenOutLayout';
import catQuotaFull from 'Assets/svg/catQuotaFull.svg';
import { useTranslation } from 'react-i18next';

const QuotaFull = () => {
    const { t: getLabel } = useTranslation();
    return (
        <WrongDeviceScreenOutLayout
            title={getLabel('participant.quotaFull.title')}
            description={getLabel('participant.quotaFull.description')}
            imageSrc={catQuotaFull}
            imageAlt="Polite cat in a box"
        />
    );
};

export default QuotaFull;
