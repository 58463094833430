import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Button, FormGroup, FormControlLabel, useWantentTheme, Link as WantentLink } from '@watched-tech/wantent-ui';
import { Link as RouterLink } from 'react-router-dom';

import {
    ParticipantFinalFormInput,
    ParticipantFinalFormSelect,
    ParticipantFinalFormCheckbox,
} from 'Features/participant/shared/components/final-form';
import { composeValidators, numberRange, numberValidator, requiredValidator } from 'Utils/form';
import { useMediaQuery } from '@mui/material';
import { StyledForm } from './DemographicsAndTOSForm.styles';
import { IDemographicsAndTOSFormProps } from './DemographicsAndTOSForm.types';
import { useGenderOptions } from 'Features/participant/register/hooks/useGenderOptions';
import { setSurveyDisclaimerAccepted } from 'Features/participant/register/utils';
import { getMobileView } from 'Features/participant/slice';
import { useSelector } from 'react-redux';

export const DemographicsAndTOSForm: FC<IDemographicsAndTOSFormProps> = ({
    onSubmit,
    form,
    values,
    invalid,
    isLoading,
}) => {
    const showMobileView = useSelector(getMobileView);
    const theme = useWantentTheme();
    const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
    const { t: getLabel } = useTranslation();
    const genderOptions = useGenderOptions();
    const required = useCallback(requiredValidator(getLabel('validation.required')), [getLabel('validation.required')]);
    const validateNumber = useCallback(numberValidator(getLabel('validation.number')), [getLabel('validation.number')]);
    const validateAge = useCallback(
        numberRange(18, 99, getLabel('validation.older', { years: 18 }), getLabel('validation.younger', { years: 99 })),
        [getLabel('validation.older'), getLabel('validation.younger')],
    );

    useEffect(() => {
        setSurveyDisclaimerAccepted('false');
    }, []);

    const mobileAnchorStyles = useMemo(
        () => ({
            marginTop: theme.spacing(6),
            width: 'max-content',
            display: 'inline-block',
        }),
        [theme],
    );
    const getPrivacyPolicyField = ({ withLabelText, withLink }: { withLabelText?: boolean; withLink?: boolean }) => (
        <FormControlLabel
            isMobile={showMobileView}
            selected={values['ppAccepted'] && values['ppAccepted'].includes('ppAccepted')}
            control={
                <Field
                    component={ParticipantFinalFormCheckbox}
                    dataAttrs={{
                        'data-testid': 'demographic-and-tos-pp-checkbox',
                    }}
                    name="ppAccepted"
                    type="checkbox"
                />
            }
            label={
                <>
                    {getLabel('register.form.terms')}
                    {withLabelText && getLabel('register.form.termsLinkWords')}
                    {withLink && (
                        <WantentLink
                            component={RouterLink}
                            to="/privacy_policy"
                            target="_blank"
                            rel="noopener noreferrer"
                            dataAttrs={{
                                'data-testid': 'demographic-and-tos-pp-link',
                            }}
                        >
                            {getLabel('register.form.termsLinkWords')}
                        </WantentLink>
                    )}
                </>
            }
            value="ppAccepted"
        />
    );

    const getContentProtectionField = ({
        withLabelText,
        withLink,
    }: {
        withLabelText?: boolean;
        withLink?: boolean;
    }) => (
        <FormControlLabel
            isMobile={showMobileView}
            selected={
                values['contentProtectionAccepted'] &&
                values['contentProtectionAccepted'].includes('contentProtectionAccepted')
            }
            control={
                <Field
                    component={ParticipantFinalFormCheckbox}
                    dataAttrs={{
                        'data-testid': 'demographic-and-tos-content-protection-checkbox',
                    }}
                    name="contentProtectionAccepted"
                    type="checkbox"
                />
            }
            label={
                <div>
                    {getLabel('register.form.terms')}
                    {withLabelText && getLabel('register.form.contentProtectionRules')}
                    {withLink && (
                        <WantentLink
                            to="/content_protection_rules"
                            component={RouterLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            dataAttrs={{
                                'data-testid': 'demographic-and-tos-content-protection-link',
                            }}
                        >
                            {getLabel('register.form.contentProtectionRules')}
                        </WantentLink>
                    )}
                </div>
            }
            value="contentProtectionAccepted"
            sx={{
                marginTop: matchesMd ? theme.spacing(6) : 0,
            }}
        />
    );

    const desktopCheckboxes = (
        <>
            {getPrivacyPolicyField({ withLabelText: false, withLink: true })}
            {getContentProtectionField({ withLabelText: false, withLink: true })}
        </>
    );

    const mobileCheckboxes = (
        <>
            {getPrivacyPolicyField({ withLabelText: true, withLink: false })}
            <WantentLink
                component={RouterLink}
                to="/privacy_policy"
                target={'_blank'}
                rel="noopener noreferrer"
                sx={{
                    ...mobileAnchorStyles,
                }}
                dataAttrs={{
                    'data-testid': 'demographic-and-tos-pp-link',
                }}
            >
                {getLabel('register.form.termsLinkWords')}
            </WantentLink>
            {getContentProtectionField({ withLabelText: true, withLink: false })}
            <WantentLink
                component={RouterLink}
                target="_blank"
                rel="noopener noreferrer"
                to="/content_protection_rules"
                sx={{ ...mobileAnchorStyles }}
                dataAttrs={{
                    'data-testid': 'demographic-and-tos-content-protection-link',
                }}
            >
                {getLabel('register.form.contentProtectionRules')}
            </WantentLink>
        </>
    );
    return (
        <StyledForm onSubmit={onSubmit}>
            <Field
                name="sex"
                id="openGroupGender"
                component={ParticipantFinalFormSelect}
                label={getLabel('register.form.iDefineAs')}
                options={genderOptions}
                validate={required}
                placeholder={getLabel('register.form.selectOption')}
                dataAttrs={{
                    'data-testid': 'demographic-and-tos-select',
                }}
            />
            <Field
                name="age"
                id="openGroupAge"
                component={ParticipantFinalFormInput}
                validate={composeValidators(required, validateNumber, validateAge)}
                placeholder={getLabel('register.form.age')}
                label={getLabel('register.form.myAgeIs')}
                setFieldTouched={form.mutators.setFieldTouched}
                sx={{
                    container: { position: 'relative' },
                }}
                dataAttrs={{
                    'data-testid': 'demographic-and-tos-input',
                }}
            />
            <FormGroup>{showMobileView ? mobileCheckboxes : desktopCheckboxes}</FormGroup>
            <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={
                    invalid ||
                    !(values as any).ppAccepted?.length ||
                    !(values as any).contentProtectionAccepted?.length ||
                    isLoading
                }
                sx={{ marginTop: theme.spacing(6), alignSelf: 'flex-end' }}
                dataAttrs={{
                    'data-testid': 'registration-next-btn',
                }}
            >
                {getLabel('participant.common.next')}
            </Button>
        </StyledForm>
    );
};
