import * as React from 'react';
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { blueGrey, red, amber } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import './localizations/i18n';
import { configureStore } from './configureStore';
import { addEventId, updateUploadsStatus } from 'Features/participant/slice';
import { isDevelopment } from 'Utils/env';
import { apiClient, getOnRejected } from 'Utils/axios';
import './css/index.css';
import 'react-vis/dist/style.css';
import { wrap } from 'comlink';
import { SendWorker } from 'Shared/workers/reaction-recording.worker';
import { sendSentryFetchError } from 'Utils/fetch';
import { render } from 'react-dom';
import AppRouter from './AppRouter';
import '@watched-tech/wantent-ui/styles.css';

export const history = createBrowserHistory();
export const store = configureStore(history);
apiClient.interceptors.response.use((response) => response, getOnRejected(history, store));

const sendWorkerInstance = new Worker(new URL('./shared/workers/reaction-recording.worker.ts', import.meta.url));
export const sendWorker = wrap<SendWorker>(sendWorkerInstance);
sendWorkerInstance.onmessage = (event) => {
    if (event.data?.type === 'uploadsUpdated') {
        store.dispatch(updateUploadsStatus(event.data.uploads));
    }
    if (event.data?.type === 'sendSentryError') {
        sendSentryFetchError(JSON.parse(event.data.error));
    }
};

if (!isDevelopment()) {
    Sentry.init({
        dsn: 'https://4dc05004a51742ff9df7c5c50f30e607@o565159.ingest.sentry.io/5706438',
        integrations: [new Integrations.BrowserTracing()],
        environment: window.location.host,
        autoSessionTracking: true,
        beforeSend: (event) => {
            event.event_id && store.dispatch(addEventId(event.event_id));
            return event;
        },
        tracesSampleRate: 0.1,
    });
}

export type RootState = ReturnType<typeof store.getState>;

export const migrationColors = {
    blue: '#39519B',
    blueGrey: 'rgba(36, 44, 68, 1)',
    darkBlue: 'rgba(22, 36, 84, 1)',
    cyan: '#25B4C8',
    aqua: 'rgba(58, 232, 227, 1)',
    lightBlue: '#6574A2',
    darkerCoral: '#E5655A',
};

const theme = createTheme({
    palette: {
        primary: { main: migrationColors.blue, light: migrationColors.cyan },
        secondary: blueGrey,
        error: { main: red[300], light: red[100] },
        warning: { main: '#ffc107', light: amber[100] },
    },
    typography: {
        fontFamily: "'Manrope', sans-serif",
        fontWeightLight: 300,
        fontWeightMedium: 400,
        fontWeightRegular: 300,
    },
    shape: {
        borderRadius: 2,
    },
});

const queryClient = new QueryClient();

const container = document.getElementById('content');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
render(
    <QueryClientProvider client={queryClient}>
        <Provider store={store}>
            <StyledEngineProvider injectFirst>
                <MuiThemeProvider theme={theme}>
                    <AppRouter />
                </MuiThemeProvider>
            </StyledEngineProvider>
        </Provider>
    </QueryClientProvider>,
    container,
);
