import { Subject, Observable } from 'rxjs';
import { useEffect, useState } from 'react';

export const videoEvent$ = new Subject();

export const useObservable = (observable$: Observable<any>) => {
    const [state, setState] = useState<any>();

    useEffect(() => {
        const subscription = observable$.subscribe(setState);
        return () => subscription.unsubscribe();
    }, [observable$]);

    return { state, observable$ };
};

export const videoEventTypes = {
    onParticipantPlayerStart: 'onParticipantPlayerStart',
    onParticipantPlayerPlay: 'onParticipantPlayerPlay',
    onParticipantPlayerPause: 'onParticipantPlayerPause',
    onParticipantPlayerSeek: 'onParticipantPlayerSeek',
    onParticipantPlayerVideoProgress: 'onParticipantPlayerVideoProgress',
    onParticipantPlayerEnded: 'onEnded',
};
