import React, { useState, useMemo, useCallback } from 'react';
import { LinearProgress } from '@mui/material';
import { orderBy, pickBy, keys } from 'lodash';
import { ScenarioCard } from 'Features/participant/workspace/dashboard/components';
import { errorSnack } from 'Features/participant/shared/components/snackbars/Snackbar';
import { MobileFilters, DesktopFilters } from 'Features/participant/workspace/dashboard/components';
import { Filters, FlexContainer, GridContainer } from './styles';
import { ScenarioState, IScenario, ScenarioWithPercents } from 'Features/participant/workspace/types';
import {
    DASHBOARD_SORT_OPTIONS,
    SORTING_VALUES,
    chipStatuses,
} from 'Features/participant/workspace/dashboard/constants';
import {
    isScenarioReady,
    isScenarioUnfinished,
    isScenarioFinished,
    getChip,
    isScenarioExpired,
} from 'Features/participant/workspace/dashboard/utils';
import OnlyHeaderLayout from 'Features/participant/shared/components/layouts/OnlyHeaderLayout';
import { NoResultsFound } from 'Features/participant/workspace/dashboard/components';
import { queries } from 'Features/participant/workspace/dashboard/queries';
import { getMobileView } from 'Features/participant/slice';
import { useSelector } from 'react-redux';
import { NoActiveScenarios } from 'Src/features/participant/workspace/dashboard/components/NoActiveScenarios';
import { useAuthenticatedQuery } from 'Shared/hooks/queries';

const ParticipantDashboard = () => {
    const [searchValue, setSearchValue] = useState<string>('');
    const showMobileView = useSelector(getMobileView);
    const [sortValue, setSortValue] = useState<string>(DASHBOARD_SORT_OPTIONS[2].value);
    const [statusFilters, setStatusFilters] = useState({
        notStarted: false,
        inProgress: false,
        complete: false,
    });
    const {
        isLoading: areScenariosLoading,
        error: getScenariosError,
        data: scenarios,
    } = useAuthenticatedQuery<IScenario[]>('getDashboardScenarios', queries.getDashboardScenarios, {
        refetchOnMount: 'always',
    });

    const onSearchChange = useCallback((e: any) => {
        setSearchValue(e.target.value);
    }, []);

    const scenariosWithPercents = useMemo(
        () =>
            scenarios
                ? (scenarios as IScenario[])
                      .filter((s) => ![ScenarioState.loading, ScenarioState.expired].includes(s.state))
                      .map((scenario: IScenario) => {
                          let percents = 0;
                          if (isScenarioUnfinished(scenario)) {
                              percents = scenario.lastStepDone
                                  ? (100 * (scenario.lastStepDone.stepPosition - 1)) / scenario.steps.length
                                  : 0;
                          } else if (isScenarioFinished(scenario)) {
                              percents = 100;
                          }
                          return {
                              ...scenario,
                              percents,
                          };
                      })
                : [],
        [scenarios],
    );

    const onSortChange = useCallback(
        (value: string) => {
            setSortValue(value);
        },
        [setSortValue],
    );

    const cards = orderBy(
        scenariosWithPercents,
        [SORTING_VALUES[sortValue].field],
        [SORTING_VALUES[sortValue].direction],
    )
        .filter((scenario: ScenarioWithPercents) => {
            return scenario.name.includes(searchValue);
        })
        .filter((scenario: ScenarioWithPercents) => {
            const filterStatusKeys = keys(pickBy(statusFilters, (val) => val === true));
            return filterStatusKeys.length > 0 ? filterStatusKeys.includes(chipStatuses[scenario.state]) : true;
        })
        .map((scenario: IScenario, idx: number) => {
            const clickable = isScenarioReady(scenario) || isScenarioUnfinished(scenario);
            const Chip = getChip(scenario.state);
            const testId = `dashboard-project-card-${idx}`;
            return (
                <ScenarioCard
                    testId={testId}
                    key={scenario.id}
                    scenario={scenario}
                    clickable={clickable}
                    Chip={Chip}
                    dataAttrs={{
                        'data-testid': testId,
                    }}
                />
            );
        });

    const isAllScenariosExpired = useMemo(() => scenarios?.every(isScenarioExpired), [scenarios]);

    if (areScenariosLoading) {
        return (
            <OnlyHeaderLayout>
                <LinearProgress sx={{ position: 'absolute', top: 0, right: 0, left: 0, height: 2 }} />
            </OnlyHeaderLayout>
        );
    }
    if (getScenariosError instanceof Error) {
        return <OnlyHeaderLayout>{errorSnack(getScenariosError.message)}</OnlyHeaderLayout>;
    }
    return (
        <OnlyHeaderLayout>
            <FlexContainer showMobileView={showMobileView}>
                <Filters showMobileView={showMobileView}>
                    {showMobileView ? (
                        <MobileFilters
                            statusFilters={statusFilters}
                            setStatusFilters={setStatusFilters}
                            sortValue={sortValue}
                            onSortChange={onSortChange}
                        />
                    ) : (
                        <DesktopFilters
                            scenarios={scenariosWithPercents}
                            sortValue={sortValue}
                            onSortChange={onSortChange}
                            searchValue={searchValue}
                            onSearchChange={onSearchChange}
                        />
                    )}
                </Filters>
                {cards?.length ? (
                    <GridContainer showMobileView={showMobileView}>{cards}</GridContainer>
                ) : isAllScenariosExpired ? (
                    <NoActiveScenarios />
                ) : (
                    <NoResultsFound />
                )}
            </FlexContainer>
        </OnlyHeaderLayout>
    );
};

export default ParticipantDashboard;
