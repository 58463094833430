import React, { useCallback } from 'react';
import ParticipantHeader from 'Features/participant/shared/components/Header';
import { Footer, Paper, useWantentTheme, Typography, useFooterButtonsSize } from '@watched-tech/wantent-ui';
import {
    Container,
    DesktopBackground,
    DesktopImageWrapper,
    FixedHeightContent,
    Image,
    MobileBackground,
    MobileImageContainer,
} from './styles';
import { ReactComponent as Leave } from 'Assets/svg/leave.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IWrongDeviceScreenOutLayoutProps } from './types';
import { getMobileView } from 'Features/participant/slice';
import { useSelector } from 'react-redux';

// fixed desktop height
// footer with only 1 button

const WrongDeviceScreenOutLayout = ({
    title,
    description,
    imageSrc,
    imageAlt,
    dataAttrs,
}: IWrongDeviceScreenOutLayoutProps) => {
    const { t: getLabel } = useTranslation();
    const navigate = useNavigate();
    const { spacing } = useWantentTheme();
    const size = useFooterButtonsSize();
    const showMobileView = useSelector(getMobileView);
    const typographyStyles = {
        marginBottom: `${spacing(6)}`,
    };

    const leaveToMain = useCallback(() => {
        navigate('/me');
    }, [navigate]);

    const desktopPaper = (
        <Paper
            title={title}
            description={description}
            dataAttrs={dataAttrs}
            sx={{
                root: {
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            <DesktopImageWrapper>
                <Image src={imageSrc} alt={imageAlt} showMobileView={false} />
            </DesktopImageWrapper>
        </Paper>
    );
    const mobileContainer = (
        <Container {...dataAttrs}>
            <Typography
                variant="h4"
                color="text.disabled"
                sx={typographyStyles}
                dataAttrs={{
                    'data-testid': 'paper-title',
                }}
            >
                {title}
            </Typography>
            <Typography variant="p2" color="text.primary" sx={typographyStyles}>
                {description}
            </Typography>
            <MobileImageContainer>
                <Image src={imageSrc} alt={imageAlt} showMobileView={showMobileView} />
            </MobileImageContainer>
        </Container>
    );

    return showMobileView ? (
        <MobileBackground>
            <ParticipantHeader isAuthenticated />
            {mobileContainer}
        </MobileBackground>
    ) : (
        <DesktopBackground>
            <ParticipantHeader isAuthenticated />
            <FixedHeightContent>{desktopPaper}</FixedHeightContent>
            <Footer
                leftButtonsOptions={[
                    {
                        type: 'button',
                        startIcon: <Leave />,
                        variant: 'outlined',
                        color: 'primary',
                        onClick: leaveToMain,
                        size,
                        children: getLabel('participant.layout.leave'),
                        dataAttrs: {
                            'data-testid': 'leave-survey-btn',
                        },
                    },
                ]}
            />
        </DesktopBackground>
    );
};

export default WrongDeviceScreenOutLayout;
