import { styled } from '@mui/material/styles';

const getImageStyles = (spacing: (num: number) => string) => ({
    margin: `${spacing(6)} 0`,
    width: '100%',
    borderRadius: spacing(8),
});

export const Image = styled('img')(({ theme }) => ({
    ...getImageStyles(theme.spacing),
}));

export const Source = styled('source')(({ theme }) => ({
    ...getImageStyles(theme.spacing),
}));
