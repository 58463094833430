import { ScenarioSessionState } from 'Features/participant/workspace/scenario/types';

interface IScenarioStep {
    id: string;
    stepPosition: number;
}

interface IScenarioSession {
    id: string;
    state: ScenarioSessionState;
}

export enum ScenarioState {
    ready = 'ready',
    loading = 'loading',
    started = 'started',
    finished = 'finished',
    expired = 'expired',
}

export interface IScenario {
    id: string;
    name: string;
    state: ScenarioState;
    imageLink: string;
    steps: IScenarioStep[];
    session?: IScenarioSession;
    lastStepDone?: IScenarioStep;
}

export type ScenarioWithPercents = IScenario & { percents: number };
