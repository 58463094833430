import { styled } from '@mui/material/styles';

export const StyledFooter = styled('footer')(({ theme }) => ({
    padding: `${theme.spacing(6)} ${theme.spacing(12)}`,
    height: theme.spacing(36),
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: `2px solid ${theme.palette.gray.x6}`,
    background: theme.palette.gray.x3,
}));
