import React from 'react';
import { Button, Typography, useWantentTheme } from '@watched-tech/wantent-ui';
import { useNavigate } from 'react-router-dom';
import { Content, TypographyWrapper, Container, FourOFour, CatInBox } from './styles';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const NotFound = () => {
    const { t: getLabel } = useTranslation();
    const { breakpoints, spacing } = useWantentTheme();
    const matchesSm = useMediaQuery(breakpoints.down('sm'));
    const navigate = useNavigate();

    const goToMain = () => {
        navigate('/');
    };

    const typography = (
        <TypographyWrapper>
            <Typography variant={matchesSm ? 'h5' : 'h4'} color="text.primary" sx={{ marginBottom: 4 }}>
                {getLabel('participant.notFound.pageNotFound')}
            </Typography>
            <Typography
                variant={matchesSm ? 'p2' : 'p1'}
                component="li"
                sx={{ listStyle: 'none' }}
                color="text.secondary"
            >
                {getLabel('participant.notFound.copyLink')}
            </Typography>
            <Typography variant="p1" component="li" sx={{ listStyle: 'none' }} color="text.secondary">
                {getLabel('participant.notFound.contactManager')}
            </Typography>
        </TypographyWrapper>
    );
    return (
        <Container>
            <Content>
                <CatInBox />
                <FourOFour />
                {!matchesSm && typography}
            </Content>
            {matchesSm && typography}
            <Button
                variant="contained"
                color="primary"
                onClick={goToMain}
                sx={{ marginTop: spacing(16), alignSelf: 'center' }}
            >
                {getLabel('participant.notFound.toHomepage')}
            </Button>
        </Container>
    );
};
