import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';

export function SingleCheckboxInput(props: FieldRenderProps<boolean> & { label: string }) {
    const { input, label, imageUrl, ...rest } = props;
    return (
        <FormControl sx={{ paddingLeft: '0px' }} component="fieldset" fullWidth {...rest}>
            {imageUrl && <img src={imageUrl} alt="checkbox" style={{ marginBottom: '20px' }} />}
            <FormControlLabel
                control={<Checkbox {...input} checked={input.checked} sx={{ padding: '6px' }} />}
                label={label}
            />
        </FormControl>
    );
}
