import { styled } from '@mui/material';
export const Iframe = styled('iframe')({
    width: '100%',
    height: '100%',
    border: 'none',
});
export const Container = styled('div', {
    shouldForwardProp: (prop: string) => !['isFullscreen', 'matchesLg'].includes(prop),
})<{ isFullscreen: boolean; matchesLg: boolean }>(({ isFullscreen, matchesLg, theme }) => ({
    position: isFullscreen ? 'fixed' : 'static',
    top: 0,
    bottom: matchesLg ? theme.footerHeights.m : theme.footerHeights.l,
    right: 0,
    left: 0,
    width: '100%',
    minHeight: isFullscreen ? `calc(100vh - ${matchesLg ? theme.footerHeights.m : theme.footerHeights.l}px)` : 'auto',
    height: isFullscreen ? 'auto' : 442,
    borderRadius: isFullscreen ? 'none' : 12,
    overflow: 'hidden',
}));
