import { MutationFunction, useMutation, UseMutationOptions, UseMutationResult } from 'react-query';
import { useNavigate } from 'react-router-dom';

const useAuthedMutation = <TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
    mutationFn: MutationFunction<TData, TVariables>,
    options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>,
): UseMutationResult<TData, TError, TVariables, TContext> => {
    const mutation = useMutation(mutationFn, options);
    const navigate = useNavigate();
    const errorResponseStatus = (mutation.error as any)?.response?.status;
    if (errorResponseStatus === 401) {
        navigate('/login');
    }
    return mutation;
};

export default useAuthedMutation;
