import { styled } from '@mui/material/styles';

export const StyledForm = styled('form')(({ theme }) => ({
    marginBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    ' > div': {
        marginBottom: theme.spacing(6),
    },
}));
