import { Field } from 'react-final-form';
import { SelectInput } from 'Features/manager/shared/components/form-inputs/SelectInput';
import { composeValidators, required } from 'Utils/form';
import { UiLocale } from 'Src/localizations/i18n';
import { MenuItem } from '@mui/material';
import React from 'react';

export function LocaleField({ label = 'Locale', locales = Object.values(UiLocale), defaultLocale = UiLocale.uk }) {
    return (
        <Field
            name="preferredLocale"
            component={SelectInput}
            validate={composeValidators(required)}
            label={label}
            defaultValue={defaultLocale}
            id="preferred-locale"
            aria-describedby="preferred-locale-error-text"
        >
            {locales.map((locale) => (
                <MenuItem key={locale} value={locale}>
                    {locale}
                </MenuItem>
            ))}
        </Field>
    );
}
