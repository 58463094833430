import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './en.json';
import ruTranslations from './ru.json';
import ukTranslations from './uk.json';
import kkTranslations from './kk.json';
import nlTranslations from './nl.json';
import plTranslations from './pl.json';
import lvTranslations from './lv.json';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const localeDescription = {
    en: 'English',
    ru: 'Русский',
    nl: 'Dutch',
    uk: 'Українська',
    kk: 'Қазақ',
    pl: 'Polski',
    lv: 'Latvijas',
};

export enum UiLocale {
    'en' = 'en',
    'uk' = 'uk',
    'ru' = 'ru',
    'kk' = 'kk',
    'nl' = 'nl',
    'pl' = 'pl',
    'lv' = 'lv',
}

const resources = {
    en: enTranslations,
    ru: ruTranslations,
    uk: ukTranslations,
    kk: kkTranslations,
    nl: nlTranslations,
    pl: plTranslations,
    lv: lvTranslations,
};
i18n.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem('locale') || 'en',
    fallbackLng: 'en',
});

export default i18n;

export function useLocale(
    newLocale: UiLocale = i18n.language as UiLocale,
): [UiLocale, Dispatch<SetStateAction<UiLocale>>] {
    localStorage.setItem('locale', newLocale);
    const [locale, setLocale] = useState(newLocale);
    useEffect(() => {
        i18n.changeLanguage(locale);
    }, [locale]);
    return [locale, setLocale];
}
