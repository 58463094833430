export const colors = {
    cyan: '#3AE8E3',
    darkBlue: '#39519B',
    white: '#fff',
    yellow: '#FFD977',
    coral: '#FF7789',
    darkCyan: '#25B4C8',
    darkestBlue: '#0B1537',
    grayBlue: '#6574A2',
    lightGray: '#c4c4c4',
};

export const animationTypes = {
    movement: 'movement',
    gradient: 'gradient',
};

export enum Directions {
    Top = 'TOP',
    Bottom = 'BOTTOM',
    Right = 'RIGHT',
    Left = 'LEFT',
    TopRight = 'TOPRIGHT',
    TopLeft = 'TOPLEFT',
    BottomRight = 'BOTTOMRIGHT',
    BottomLeft = 'BOTTOMLEFT',
}

export const circleColors = [
    { outer: colors.grayBlue, inner: colors.darkBlue },
    { outer: colors.darkBlue, inner: colors.cyan },
    { outer: colors.cyan, inner: colors.white },
    { outer: colors.white, inner: colors.yellow },
    { outer: colors.yellow, inner: colors.coral },
    { outer: colors.coral, inner: colors.darkCyan },
    { outer: colors.darkCyan, inner: colors.grayBlue },
    { outer: colors.grayBlue, inner: colors.white },
    { outer: colors.white, inner: colors.grayBlue },
    { outer: colors.grayBlue, inner: colors.coral },
    { outer: colors.coral, inner: colors.darkBlue },
    { outer: colors.darkBlue, inner: colors.yellow },
    { outer: colors.yellow, inner: colors.lightGray },
];

export const animationDuration = 1500;
export const radius = 20;
export const padding = radius * 2;
export const tooCloseCoefficient = 0.2;
