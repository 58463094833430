import React, { useRef } from 'react';
import { FormControl, FormHelperText, Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Done as DoneIcon, Close } from '@mui/icons-material';
import { FieldRenderProps } from 'react-final-form';

const Input = styled('input')({
    display: 'none',
    color: 'lightgray !important',
});

const Label = styled('label')({
    display: 'flex',
});
const Status = styled('div')({
    display: 'flex',
    padding: '5px',
});

const getPromiseFromEvent = (element: HTMLElement, event: string): Promise<{ width: number; height: number }> => {
    return new Promise((resolve) => {
        const listener = function (this: any) {
            resolve({ width: this.videoWidth, height: this.videoHeight });
        };
        element.addEventListener(event, listener);
    });
};

const getVideoFileDimensions = async (file: File) => {
    const url = URL.createObjectURL(file);
    const video = document.createElement('video');
    video.src = url;
    return await getPromiseFromEvent(video, 'loadedmetadata');
};

const handleChange = (handler: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const isVideoFile = files ? files[0].type.includes('video') : false;
    files &&
        files.length &&
        isVideoFile &&
        getVideoFileDimensions(files[0]).then(({ width, height }) => {
            handler({ file: files[0], name: files[0].name, width, height });
        });
    handler(files && files.length ? { file: files[0], name: files[0].name } : undefined);
};

export const FileInput = (props: FieldRenderProps<any> & { label?: string; id?: string }) => {
    const {
        input: { onChange, onBlur, value: omitValue, ...inputProps },
        label,
        meta: { touched, error },
        onFileRemove,
        ...custom
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    if (!custom.id) {
        custom.id = 'file-field';
    }

    const removeFile = () => {
        onFileRemove();
        if (inputRef.current) {
            inputRef.current.value = '';
        }
    };

    return (
        <FormControl
            margin="normal"
            required
            fullWidth
            error={touched && error}
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
            <Input
                {...inputProps}
                {...custom}
                type="file"
                multiple
                onChange={handleChange(onChange)}
                onBlur={handleChange(onBlur)}
                ref={inputRef}
            />
            <Label htmlFor={custom.id}>
                <Button
                    variant="outlined"
                    sx={{ color: custom.disabled ? 'rgba(0, 0, 0, 0.23)' : '#000' }}
                    component="span"
                >
                    {label}
                </Button>
                {omitValue && (
                    <Status>
                        <FormHelperText id={custom['aria-describedby']}>{omitValue.name}</FormHelperText>
                        <DoneIcon color="primary" />
                    </Status>
                )}
            </Label>
            {omitValue && typeof onFileRemove === 'function' && (
                <IconButton onClick={removeFile} size="large">
                    <Close />
                </IconButton>
            )}
            {custom['aria-describedby'] && <FormHelperText id={custom['aria-describedby']}>{error}</FormHelperText>}
        </FormControl>
    );
};
