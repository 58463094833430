import React, { useCallback, FC, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { Mutator } from 'final-form';
import { composeValidators, number, requiredValidator, requiredSliderValidator } from 'Utils/form';
import {
    ParticipantFinalFormInput,
    ParticipantFinalFormSelect,
    ParticipantFinalFormRadioCheckboxGroup,
    ParticipantFinalFormTextarea,
    ParticipantFinalFormRating,
    ParticipantFinalFormSlider,
} from 'Features/participant/shared/components/final-form';
import { requiredCheckboxRadioGroupValidator } from 'Utils/form';
import { shuffle } from 'lodash';
import { VirtualKeyboardContext } from 'Features/participant/workspace/scenario/context/virtualKeyboardContext/VirtualKeyboardProvider';
import { getMobileView } from 'Features/participant/slice';
import { useSelector } from 'react-redux';
import { ElementType, IQuestionOption, IQuestionWithConfig, CommonQuestion, SurveyElement } from 'Shared/types';
import { optionQuestionTypes } from 'Shared/constants';
import { AbstractQuestion, OptionsQuestion, ScreenerQuestion } from 'Features/manager/shared/queries';
import { IPickQuestion } from 'Features/manager/shared/types';
import { addPrefixToObject } from '@watched-tech/wantent-ui';
import { DataAttrs } from '@watched-tech/wantent-ui/utils';

interface IParticipantQuestionFieldProps {
    question: CommonQuestion;
    values: Record<string, unknown>;
    errors: Record<string, unknown>;
    touched: Record<string, unknown>;
    setFieldTouched: Mutator;
}

export function shuffleExceptAnchored(array: IQuestionOption[]): IQuestionOption[] {
    const toShuffle = array.map((item, index) => ({ ...item, originalIndex: index }));
    const anchoredItems = toShuffle.filter((item) => item.isAnchored);
    const itemsToShuffle = toShuffle.filter((item) => !item.isAnchored);

    const shuffledItems = shuffle(itemsToShuffle);

    for (const anchoredItem of anchoredItems) {
        shuffledItems.splice(anchoredItem.originalIndex, 0, anchoredItem);
    }

    return shuffledItems.map((item) => {
        const { originalIndex, ...rest } = item;
        return rest;
    });
}

function setupAnswers(question: CommonQuestion) {
    if (
        question.type !== 'pickManyQuestion' &&
        question.type !== 'pickOneQuestion' &&
        question.type !== 'radioPickOneQuestion'
    ) {
        return undefined;
    }
    const options = (question as IPickQuestion).options.slice();
    options.sort((a, b) => a.position - b.position);
    return question.isOptionsRandomized ? shuffleExceptAnchored(options) : options;
}

function useValidator(validator: (error: string) => any, labelPath: string) {
    const { t: getLabel } = useTranslation();
    return useCallback(validator(getLabel(labelPath)), [getLabel(labelPath)]);
}

export const ParticipantQuestionField: FC<IParticipantQuestionFieldProps> = ({
    question,
    values,
    errors,
    touched,
    setFieldTouched,
}) => {
    console.log('question', question, values);
    const { setIsKeyboardShown } = useContext(VirtualKeyboardContext);
    const [answers, setAnswers] = useState(setupAnswers(question));
    useEffect(() => {
        const answers = setupAnswers(question);
        setAnswers(answers);
    }, [question.id]);
    const showMobileView = useSelector(getMobileView);
    const requiredStars = useValidator(requiredValidator, 'validation.ratingRequired');
    const requiredCheckbox = useValidator(requiredCheckboxRadioGroupValidator, 'validation.checkboxRequired');
    const requiredRadio = useValidator(requiredCheckboxRadioGroupValidator, 'validation.radioRequired');
    const requiredRating = useValidator(requiredSliderValidator, 'validation.ratingRequired');
    const requiredTextarea = useValidator(requiredValidator, 'validation.plainRequired');
    const requiredSelect = useValidator(requiredValidator, 'validation.selectRequired');

    const onFocus = useCallback(() => {
        setIsKeyboardShown(true);
    }, [setIsKeyboardShown]);
    const onBlur = useCallback(() => {
        setIsKeyboardShown(false);
    }, [setIsKeyboardShown]);

    const getField = () => {
        switch (question.type) {
            case 'numberQuestion':
                return (
                    <Field
                        name={question.id}
                        component={ParticipantFinalFormInput}
                        validate={composeValidators(number, requiredTextarea)}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                );
            case 'pickOneQuestion':
                return (
                    <Field
                        name={question.id}
                        component={ParticipantFinalFormSelect}
                        validate={requiredSelect}
                        setFieldTouched={setFieldTouched}
                        options={answers?.map((option: IQuestionOption, idx: number) => ({
                            value: option.id.toString(),
                            label: option.text,
                            idx,
                        }))}
                        dataAttrs={{
                            'data-testid': 'select',
                        }}
                    />
                );

            case 'pickManyQuestion':
                return (
                    <ParticipantFinalFormRadioCheckboxGroup
                        values={values}
                        options={answers?.map((option: IQuestionOption) => ({
                            name: option.text,
                            value: option.id.toString(),
                            label: option.text,
                        }))}
                        componentType="checkbox"
                        groupName={question.id}
                        errors={errors}
                        touched={touched}
                        showMobileView={showMobileView}
                        validate={requiredCheckbox}
                    />
                );
            case 'radioPickOneQuestion':
                return (
                    <ParticipantFinalFormRadioCheckboxGroup
                        values={values}
                        options={answers?.map((option: IQuestionOption) => ({
                            name: option.text,
                            value: option.id.toString(),
                            label: option.text,
                        }))}
                        componentType="radio"
                        groupName={question.id}
                        errors={errors}
                        touched={touched}
                        validate={requiredRadio}
                        showMobileView={showMobileView}
                    />
                );
            case 'starsQuestion':
                return (
                    <Field
                        name={question.id}
                        component={ParticipantFinalFormRating}
                        validate={requiredStars}
                        error={touched[question.id] && errors[question.id] ? errors[question.id] : undefined}
                        max={question.config.maxValue || 5}
                        showMobileView={showMobileView}
                        dataAttrs={{
                            'data-testid': 'rating',
                        }}
                    />
                );
            case 'ratingSliderQuestion':
                return (
                    <Field
                        name={question.id}
                        component={ParticipantFinalFormSlider}
                        validate={requiredRating}
                        error={touched[question.id] && errors[question.id] ? errors[question.id] : undefined}
                        setFieldTouched={setFieldTouched}
                        min={question.config.minValue ?? 1}
                        max={question.config.maxValue || 10}
                        showMobileView={showMobileView}
                        dataAttrs={{
                            'data-testid': 'slider',
                        }}
                    />
                );
            case 'plainTextQuestion':
            default:
                return (
                    <Field
                        name={question.id}
                        component={ParticipantFinalFormTextarea}
                        validate={requiredTextarea}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        dataAttrs={{
                            'data-testid': 'textarea',
                        }}
                    />
                );
        }
    };

    return getField();
};
