import React from 'react';
import { AppBar, Button, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';

import { ReactComponent as WantentLogo } from 'Assets/svg/wantent_round_logo_white_48_48.svg';
import { styled } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuthContext } from 'Shared/auth/context';
import { Roles } from 'Shared/auth/common';

const Root = styled('div')({
    flexGrow: 1,
});

const TopMenuButton = (props: any) => {
    return (
        <Button size="large">
            <span style={{ color: 'rgb(255, 255, 255)' }}>{props.text}</span>
        </Button>
    );
};
const TopRightControls = (props: { isAuthenticated: boolean }) => {
    const { isAuthenticated } = props;
    const auth0 = useAuth0();
    const auth = useAuthContext();
    if (!isAuthenticated) {
        return (
            <div>
                <p
                    onClick={() =>
                        auth0.loginWithRedirect({
                            appState: {
                                returnTo: '/login_from_home_callback',
                            },
                        })
                    }
                    color="inherit"
                >
                    <TopMenuButton text="Login" />
                </p>
            </div>
        );
    } else {
        switch (auth.role) {
            case Roles.FocusGroupManager:
            case Roles.TenantAdmin:
            case Roles.Admin:
                return (
                    <Link to="/manager/scenarios">
                        <TopMenuButton text="Open app" />
                    </Link>
                );
            case Roles.FocusGroupParticipant:
            default:
                return (
                    <Link to="/me">
                        <TopMenuButton text="Open app" />
                    </Link>
                );
        }
    }
};

export const NavBar: React.FC = (props) => {
    const toolbar = (
        <Toolbar
            sx={{
                '& .MuiToolbar-reqular': {
                    minHeight: '80px',
                },
            }}
        >
            <WantentLogo />
            <div style={{ marginLeft: 'auto' }}>{props.children}</div>
        </Toolbar>
    );

    return (
        <Root>
            <AppBar position="static">{toolbar}</AppBar>
        </Root>
    );
};

const IndexNavBar = () => {
    const auth = useAuthContext();
    const isAuthenticated = !!auth.userId;
    return (
        <NavBar>
            <TopRightControls isAuthenticated={isAuthenticated} />
        </NavBar>
    );
};

export default IndexNavBar;
