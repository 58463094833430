import { Chip } from '@watched-tech/wantent-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const InProgressChip: React.FC<any> = (props: any) => {
    const { t: getLabel } = useTranslation();
    return (
        <Chip
            {...props}
            label={getLabel('participant.scenarios.status.inProgress')}
            color={'success'}
            clickable={false}
            dataAttrs={{
                'data-testid': 'in-progress-card-chip',
            }}
        />
    );
};
