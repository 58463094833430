interface ScreenshotDimensions {
    width: number;
    height: number;
}

export class Webcam {
    private ctx: CanvasRenderingContext2D | null = null;
    private canvas: HTMLCanvasElement | null = null;

    constructor(public readonly stream: MediaStream | null, private video: HTMLVideoElement | null = null) {}

    getScreenshot = (mirrored?: boolean): string | undefined => {
        const canvas = this.getCanvas(undefined, mirrored);
        return (canvas && canvas.toDataURL('image/jpeg', 0.92)) || undefined;
    };

    getCanvas(screenshotDimensions?: ScreenshotDimensions, mirrored?: boolean) {
        if (!this.stream) return;
        if (!this.ctx) {
            const { height: canvasHeight, width: canvasWidth } = this.stream.getVideoTracks()[0].getSettings();
            this.canvas = document.createElement('canvas');
            this.canvas.width = screenshotDimensions?.width || canvasWidth || 1280;
            this.canvas.height = screenshotDimensions?.height || canvasHeight || 720;
            this.ctx = this.canvas.getContext('2d');
        }

        const { ctx, canvas } = this;

        if (ctx && canvas && this.video) {
            // adjust the height and width of the canvas to the given dimensions
            canvas.width = screenshotDimensions?.width || canvas.width;
            canvas.height = screenshotDimensions?.height || canvas.height;

            // mirror the screenshot
            if (mirrored) {
                ctx.translate(canvas.width, 0);
                ctx.scale(-1, 1);
            }
            ctx.drawImage(
                this.video,
                0,
                0,
                screenshotDimensions?.width || canvas.width,
                screenshotDimensions?.height || canvas.height,
            );

            // invert mirroring
            if (mirrored) {
                ctx.scale(-1, 1);
                ctx.translate(-canvas.width, 0);
            }
        }

        return canvas;
    }
}
