import { useTranslation } from 'react-i18next';
import { Modal } from '@watched-tech/wantent-ui';
import React, { useCallback } from 'react';
import { useMutation } from 'react-query';
import { setFieldTouchedMutator } from '@watched-tech/wantent-ui';
import { registerOpenGroupUser } from 'Features/participant/register/queries/mutations';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import { REGISTRATION_MODAL_WIDTH } from 'Features/participant/register/constants';
import { DemographicsAndTOSForm } from 'Features/participant/register/components/shared/DemographicsAndTOSForm';
import { useSelector } from 'react-redux';
import { getMobileView } from 'Features/participant/slice';
import { useAuthContext } from 'Shared/auth/context';
import { removeAuthToken, signInWntUserWithTokens } from 'Shared/auth/schemes/wnt';

interface IOpenGroupRegisterFormProps {
    openGroupId: string;
}

export const OpenGroupRegisterForm = ({ openGroupId }: IOpenGroupRegisterFormProps) => {
    const showMobileView = useSelector(getMobileView);
    const auth = useAuthContext();
    const { t: getLabel } = useTranslation();
    const navigate = useNavigate();

    const { isLoading, mutateAsync } = useMutation(registerOpenGroupUser, {
        onSuccess: (tokens: { accessToken: string; refreshToken: string }) => {
            const redirectUrl = showMobileView ? '/me' : '/survey-disclaimer';

            signInWntUserWithTokens(tokens.accessToken, tokens.refreshToken, auth);
            auth.updateScheme('wnt');
            navigate(redirectUrl);
        },
    });

    const handleRegisterFormSubmit = useCallback(
        (values: any) => {
            removeAuthToken();
            return mutateAsync({
                body: {
                    age: values.age,
                    sex: values.sex,
                    openGroupId,
                },
            });
        },
        [mutateAsync, openGroupId],
    );

    const renderForm = useCallback(
        ({ handleSubmit, invalid, form, errors, values }) => {
            return (
                <DemographicsAndTOSForm
                    onSubmit={handleSubmit}
                    form={form}
                    errors={errors}
                    values={values}
                    invalid={invalid}
                    isLoading={isLoading}
                />
            );
        },
        [isLoading],
    );

    return (
        <Modal
            open
            onClose={() => {}}
            title={getLabel('register.heading')}
            // subTitle={getLabel('register.subtitle')}
            width={REGISTRATION_MODAL_WIDTH}
            withCloseIcon={false}
            scroll="body"
            sx={{
                modal: {
                    '& .MuiBackdrop-root': {
                        backgroundColor: 'transparent',
                        backdropFilter: 'none',
                    },
                },
            }}
            dataAttrs={{
                'data-testid': 'og-registration',
            }}
        >
            <Form onSubmit={handleRegisterFormSubmit} mutators={{ setFieldTouched: setFieldTouchedMutator }}>
                {renderForm}
            </Form>
        </Modal>
    );
};
