import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'Src/reducers';
import { isEqual } from 'lodash';
import { VideoType } from 'Features/manager/shared/types/videos/VideoType';
import { DataCollectionMethod, DataCollectionMethodType } from 'Pages/manager/scenarios/types/DataCollectionMethod';
import { UiLocale } from 'Src/localizations/i18n';

export enum StepType {
    survey = 'survey',
    profiling = 'profiling',
    presentation = 'presentation',
    landing = 'landing',
    video = 'video',
    calibration = 'calibration',
    website = 'website',
}

interface IStep {
    isWebcamCheckRequired: boolean;
    stepPosition: number;
}

export interface IVideoStep extends IStep {
    id: number;
    stepType: StepType.video;
    name: string;
    isGazeRequired: boolean;
    requiredWatchedPercentage: number;
    videoReactionSource: {
        videoType: VideoType;
        name: string;
        duration: string;
        edl?: File;
        file: File;
        link?: string;
    };
}

interface IVideoReactionSource {
    videoType: VideoType;
    name: string;
    edl?: File;
}

export interface IYoutubeReactionSource extends IVideoReactionSource {
    link: string;
}

export interface ICustomWatermarkedVideoReactionSource extends IVideoReactionSource {
    duration: string;
    file: File;
    width: number;
    height: number;
}

export type VideoReactionSource = ICustomWatermarkedVideoReactionSource | IYoutubeReactionSource;

export interface ICalibrationStep extends IStep {
    stepType: StepType.calibration;
    name: string;
    isAdaptive: boolean;
}

export interface ISurveyStep extends IStep {
    stepType: StepType.survey;
    isRecordingRequired: boolean;
    surveyId: string;
    name: string;
}

export enum ProfilingType {
    socDem = 'socDem',
    interests = 'interests',
}
export interface IProfilingStep extends IStep {
    stepType: StepType.profiling;
    id: string;
    isRecordingRequired: boolean;
    profilingType: ProfilingType;
    locale: UiLocale;
    name: string;
}

export interface IPresentationStep extends IStep {
    stepType: StepType.presentation;
    isWebcamCheckRequired: boolean;
    file: File;
    name: string;
}

export interface ILandingStep extends IStep {
    stepType: StepType.landing;
    file: File;
    name: string;
}

export interface IWebsiteStep extends IStep {
    stepType: StepType.website;
    url: string;
    name: string;
}

export type CreateScenarioStep =
    | ISurveyStep
    | IProfilingStep
    | IPresentationStep
    | ILandingStep
    | IWebsiteStep
    | IVideoStep
    | ICalibrationStep;

interface IStepsListReducerState {
    dataCollectionMethods: DataCollectionMethod[];
    steps: CreateScenarioStep[];
}

const initialState: IStepsListReducerState = {
    steps: [],
    dataCollectionMethods: [],
};

export const managerCreateScenarioSlice = createSlice({
    name: 'createScenario',
    initialState,
    reducers: {
        addStep(state, action: PayloadAction<CreateScenarioStep>) {
            state.steps = [...state.steps, action.payload];
        },
        removeStep(state, action: PayloadAction<CreateScenarioStep>) {
            let newSteps;
            switch (action.payload.stepType) {
                case StepType.survey:
                    newSteps = state.steps.filter(
                        (step: CreateScenarioStep) =>
                            (step as ISurveyStep).surveyId !== (action.payload as ISurveyStep).surveyId,
                    );
                    break;
                case StepType.profiling:
                    newSteps = state.steps.filter(
                        (step: CreateScenarioStep) =>
                            (step as IProfilingStep).id !== (action.payload as IProfilingStep).id,
                    );
                    break;
                case StepType.video:
                    newSteps = state.steps.filter(
                        (step: CreateScenarioStep) => (step as IVideoStep).id !== (action.payload as IVideoStep).id,
                    );
                    break;
                case StepType.presentation:
                    newSteps = state.steps.filter(
                        (step: CreateScenarioStep) =>
                            !isEqual((step as IPresentationStep).file, (action.payload as IPresentationStep).file),
                    );
                    break;
                case StepType.calibration:
                    newSteps = state.steps.filter(
                        (step: CreateScenarioStep) =>
                            (step as ICalibrationStep).name !== (action.payload as ICalibrationStep).name,
                    );
                    break;
                case StepType.website:
                    newSteps = state.steps.filter(
                        (step: CreateScenarioStep) =>
                            (step as IWebsiteStep).name !== (action.payload as IWebsiteStep).name,
                    );
                    break;
                case StepType.landing:
                    newSteps = state.steps.filter(
                        (step: CreateScenarioStep) =>
                            !isEqual((step as ILandingStep).file, (action.payload as ILandingStep).file),
                    );
                    break;
            }
            state.steps = newSteps.map((step, idx) => {
                step.stepPosition = idx + 1;
                return step;
            });
        },
        overwriteSteps(state, action: PayloadAction<CreateScenarioStep[]>) {
            state.steps = [...action.payload];
        },
        addDataCollectionMethod(state, action: PayloadAction<DataCollectionMethod>) {
            state.dataCollectionMethods = [...state.dataCollectionMethods, action.payload];
        },
        removeDataCollectionMethod(state, action: PayloadAction<DataCollectionMethod>) {
            state.dataCollectionMethods = state.dataCollectionMethods.filter(
                (dcm: DataCollectionMethod) => !isEqual(dcm, action.payload),
            );
        },
        resetScenario(state) {
            state.steps = [];
            state.dataCollectionMethods = [];
        },
    },
});

export const getScenarioSteps = (state: AppState) => state.managerCreateScenario.steps;
export const getSurveySteps = (state: AppState): ISurveyStep[] =>
    state.managerCreateScenario.steps.filter(
        (step: CreateScenarioStep) => step.stepType === StepType.survey,
    ) as ISurveyStep[];
export const getDataCollectionMethods = (state: AppState): DataCollectionMethod[] =>
    state.managerCreateScenario.dataCollectionMethods;
export const getFocusGroups = (state: AppState) =>
    state.managerCreateScenario.dataCollectionMethods.filter(
        (dcm: DataCollectionMethod) => dcm.type === DataCollectionMethodType.focusGroup,
    );

export const {
    actions: {
        addStep,
        removeStep,
        overwriteSteps,
        addDataCollectionMethod,
        removeDataCollectionMethod,
        resetScenario,
    },
    reducer,
} = managerCreateScenarioSlice;
