import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'Src/reducers';
import { IUploadStatus } from 'Shared/workers/reaction-recording.worker';

type Locale = 'en' | 'ru' | 'ua';
interface IParticipantReducerState {
    error: string;
    eventId: string;
    locale: Locale;
    uploads: IUploadStatus[];
    showMobileView: boolean;
}

const initialState: IParticipantReducerState = {
    error: '',
    eventId: '',
    locale: 'ru',
    uploads: [],
    showMobileView: false,
};

export const participantSlice = createSlice({
    name: 'participant',
    initialState,
    reducers: {
        addError(state, action: PayloadAction<string>) {
            state.error = action.payload;
        },
        addEventId(state, action: PayloadAction<string>) {
            state.eventId = action.payload;
        },
        updateUploadsStatus(state, action: PayloadAction<IUploadStatus[]>) {
            state.uploads = action.payload;
        },
        setLocale(state, action: PayloadAction<Locale>) {
            state.locale = action.payload;
        },
        setMobileView(state, action: PayloadAction<boolean>) {
            state.showMobileView = action.payload;
        },
    },
});

export const { actions, reducer } = participantSlice;
export const { addError, addEventId, updateUploadsStatus, setLocale, setMobileView } = actions;
export const getParticipantError = (state: AppState) => state.participant.error;
export const getEventId = (state: AppState) => state.participant.eventId;
export const getVideoStates = (state: AppState) => state.participant.uploads;
export const getLocale = (state: AppState) => state.participant.locale;
export const getMobileView = (state: AppState) => state.participant.showMobileView;
