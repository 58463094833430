import { styled } from '@mui/material/styles';
import { LinearProgress } from '@mui/material';

export const StyledProgress = styled(LinearProgress)(({ theme }) => ({
    gridArea: 'progress',
    borderRadius: 4,

    '&.MuiLinearProgress-determinate': {
        background: theme.palette.gray.x5,
    },
    '& .MuiLinearProgress-bar1Determinate': {
        background: theme.palette.primary.dark,
    },
}));

export const ProgressWrapper = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateAreas: '"name percents" "progress progress"',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: '20px 4px',
    gridGap: theme.spacing(2),
}));
