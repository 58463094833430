import { getMutationCallback } from 'Utils/query';
import { Sex } from 'Shared/types';
import { LoginTokens, PanelRespondentLogin, PanelRespondentRegister } from 'Features/participant/register/types';

export const registerWithToken = getMutationCallback<undefined, FormData, LoginTokens>({
    url: () => '/api/users/register_token',
    method: 'post',
    config: {
        headers: { 'Content-Type': 'multipart/form-data' },
    },
});

export const registerOpenGroupUser = getMutationCallback<
    undefined,
    { age: number; sex: Sex; openGroupId: string },
    LoginTokens
>({
    url: () => '/api/users/register_og',
    method: 'post',
});

export const linkDetails = getMutationCallback<
    undefined,
    { id: string },
    { isUsed: boolean; hasPresetPassword: boolean; email: string }
>({
    url: () => '/api/users/link_details',
    method: 'post',
});

export const panelRespondentLogin = getMutationCallback<undefined, PanelRespondentLogin, any>({
    url: () => '/api/users/panel_login',
    method: 'post',
});

export const panelRespondentRegister = getMutationCallback<undefined, PanelRespondentRegister, any>({
    url: () => '/api/users/panel_register',
    method: 'post',
});
