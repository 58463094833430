import {
    IFaceCheckResult,
    ILandingEvent,
    IParticipantSurveyResponse,
} from 'Features/participant/workspace/scenario/types';
import { ScenarioSessionState, StepType } from 'Features/participant/workspace/scenario/types';
import { IParticipantScreenerResponse, FollowingAction } from 'Features/participant/workspace/scenario/types';
import { IPresentationResponseBody } from 'Features/participant/workspace/scenario/types';
import { AuthenticatedMutationOptions, useAuthenticatedMutation } from 'Src/shared/hooks/queries';
import { IWebsiteSessionEvent } from 'Features/participant/workspace/scenario/components/reaction-recorders/WebsiteRecorder/types';

interface ScenarioSessionCreationResult {
    session: {
        id: string;
    };
    followingAction: FollowingAction;
}

export const participantMutations = {
    useFaceCheck: (options?: AuthenticatedMutationOptions) =>
        useAuthenticatedMutation<undefined, ArrayBufferLike, IFaceCheckResult>(
            ['participant.faceCheck'],
            {
                url: () => '/api/me/face_check',
                method: 'post',
                config: {
                    headers: {
                        'Content-Type': 'image/jpeg',
                    },
                },
            },
            options,
        ),
    useSendCalibrationPoints: (options?: AuthenticatedMutationOptions<{ id: string }>) =>
        useAuthenticatedMutation(
            ['participant.sendCalibrationPoints'],
            {
                url: (params) => `/api/Calibrationsessions/${params.id}/points`,
                method: 'put',
            },
            options,
        ),
    usePostParticipantFeedback: (options?: AuthenticatedMutationOptions) =>
        useAuthenticatedMutation<undefined, { scenarioId: string; comment: string; rating?: number }, undefined>(
            ['participant.postParticipantFeedback'],
            {
                url: () => '/api/me/feedback',
                method: 'post',
            },
            options,
        ),
    usePostSurveyResponse: (options?: AuthenticatedMutationOptions) =>
        useAuthenticatedMutation<undefined, IParticipantSurveyResponse, ScenarioSessionState>(
            ['participant.postSurveyResponse'],
            {
                url: () => '/api/me/survey_response',
                method: 'post',
            },
            options,
        ),
    usePutScreenerResponse: (options?: AuthenticatedMutationOptions) =>
        useAuthenticatedMutation<undefined, IParticipantScreenerResponse, ScenarioSessionState>(
            ['participant.putScreenerResponse'],
            {
                url: () => '/api/me/screener_response',
                method: 'put',
            },
            options,
        ),
    useFinishScenarioStepSession: (options?: AuthenticatedMutationOptions<{ id: string }>) =>
        useAuthenticatedMutation<{ id: string }, undefined, undefined>(
            ['participant.finishScenarioStepSession'],
            {
                url: (params) => `/api/scenariostepsessions/${params.id}/finish`,
                method: 'post',
            },
            options,
        ),
    useFinishScenarioSession: (options?: AuthenticatedMutationOptions<{ id: string }, undefined, FollowingAction>) =>
        useAuthenticatedMutation<{ id: string }, undefined, FollowingAction>(
            ['participant.finishScenarioSession'],
            {
                url: (params) => `/api/scenariosessions/${params.id}/finish`,
                method: 'post',
            },
            options,
        ),
    usePostStepSession: (options?: AuthenticatedMutationOptions) =>
        useAuthenticatedMutation<
            undefined,
            { scenarioStepId: string; scenarioSessionId: string; stepType: StepType },
            any
        >(
            ['participant.postStepSession'],
            {
                url: () => '/api/scenariostepsessions',
                method: 'post',
            },
            options,
        ),
    usePostScenarioSession: (options?: AuthenticatedMutationOptions) =>
        useAuthenticatedMutation<undefined, { scenarioId: string }, ScenarioSessionCreationResult>(
            ['participant.postScenarioSession'],
            {
                url: () => '/api/scenariosessions',
                method: 'post',
            },
            options,
        ),
    useFinishPresentationSession: (options?: AuthenticatedMutationOptions<{ id: string }>) =>
        useAuthenticatedMutation<{ id: string }, undefined, undefined>(
            ['participant.finishPresentationSession'],
            {
                url: (params) => `/api/presentationsessions/${params.id}/finish`,
                method: 'post',
                config: {
                    headers: {
                        Accept: 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                    },
                },
            },
            options,
        ),
    useSendPresentationEvent: (options?: AuthenticatedMutationOptions<{ id: string }>) =>
        useAuthenticatedMutation<{ id: string }, IPresentationResponseBody, undefined>(
            ['participant.sendPresentationEvent'],
            {
                url: (params) => `/api/presentationsessions/${params.id}/event`,
                method: 'post',
                config: {
                    withCredentials: true,
                },
            },
            options,
        ),
    useSendWebsiteSessionEvents: (options?: AuthenticatedMutationOptions<{ id: string }>) =>
        useAuthenticatedMutation<{ id: string }, any, { events: IWebsiteSessionEvent[] }>(
            ['participant.sendPresentationEvent'],
            {
                url: (params) => `/api/websitestepsessions/${params.id}/events`,
                method: 'post',
                config: {
                    withCredentials: true,
                },
            },
            options,
        ),
    useSendLangingSessionEvent: (options?: AuthenticatedMutationOptions<{ id: string }>) =>
        useAuthenticatedMutation<{ id: string }, { event: ILandingEvent }, undefined>(
            ['participant.sendLandingSessionEvent'],
            {
                url: (params) => `/api/landingsessions/${params.id}/event`,
                method: 'post',
                config: {
                    withCredentials: true,
                },
            },
            options,
        ),
    useFinishLandingSession: (options?: AuthenticatedMutationOptions<{ id: string }>) =>
        useAuthenticatedMutation<{ id: string }, undefined, undefined>(
            ['participant.finishLandingSessionSession'],
            {
                url: (params) => `/api/landingsessions/${params.id}/finish`,
                method: 'post',
                config: {
                    headers: {
                        Accept: 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                    },
                },
            },
            options,
        ),
    usePostLeaveScenario: (options?: AuthenticatedMutationOptions<{ id: string }>) =>
        useAuthenticatedMutation<{ id: string }, { state: object }, undefined>(
            ['participant.postLeaveScenario'],
            {
                url: (params) => `/api/scenariosessions/${params.id}/leave`,
                method: 'post',
            },
            options,
        ),
};
