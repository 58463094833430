import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { router } from 'Src/AppRouter';

interface IPasswordCredentials {
    email: string;
    password: string;
}

interface IFaceCredentials {
    email: string;
    face: string;
}

export type UserCredentials = IPasswordCredentials | IFaceCredentials;

export type LoginMode = 'password' | 'face';

interface ILoginReducerState {
    isFetching: boolean;
    isAuthenticated: boolean;
    errorMessage?: string;
    creds?: UserCredentials;
}

export const initialState: ILoginReducerState = {
    isFetching: false,
    isAuthenticated: false,
    errorMessage: undefined,
};

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        requestLogin(state) {
            state.isFetching = true;
            state.isAuthenticated = false;
        },
        loginSuccess(state) {
            state.isFetching = false;
            state.isAuthenticated = true;
        },
        loginFailure(state, action: PayloadAction<string>) {
            state.isFetching = false;
            state.isAuthenticated = false;
            state.errorMessage = action.payload;
        },
    },
});

export const { actions, reducer } = loginSlice;
export const { requestLogin, loginSuccess, loginFailure } = actions;
