import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'Src/reducers';
import { VideoType } from 'Features/manager/shared/types/videos/VideoType';
import { IVideoUpload, VideoUploadStatus } from 'Pages/manager/scenarios/types';

export enum StepType {
    survey = 'survey',
    presentation = 'presentation',
    landing = 'landing',
    video = 'video',
    calibration = 'calibration',
    website = 'website',
}

interface IVideoReactionSource {
    videoType: VideoType;
    name: string;
    edl?: File;
}
export interface IYoutubeReactionSource extends IVideoReactionSource {
    link: string;
}
export interface ICustomWatermarkedVideoReactionSource extends IVideoReactionSource {
    duration: string;
    file: File;
}

export type VideoReactionSource = ICustomWatermarkedVideoReactionSource | IYoutubeReactionSource;

interface IScenarioUploadsState {
    uploads: IVideoUpload[];
}

const initialState: IScenarioUploadsState = {
    uploads: [],
};

export const managerUploadsSlice = createSlice({
    name: 'scenarioUploads',
    initialState,
    reducers: {
        addProgress(state, action: PayloadAction<{ progress: number; id: string }>) {
            const { progress, id } = action.payload;
            const uploads = state.uploads.filter((upload) => {
                if (upload.id === id) {
                    upload.percentsDone += progress;
                }
                return upload;
            });
            state.uploads = uploads;
        },
        startUpload(state, action: PayloadAction<{ id: string; name: string }>) {
            const newUpload = {
                status: VideoUploadStatus.uploading,
                percentsDone: 0,
                id: action.payload.id,
                name: action.payload.name,
            };
            state.uploads.push(newUpload);
        },
        finishUpload(state, action: PayloadAction<{ id: string }>) {
            const uploads = state.uploads.filter((upload) => {
                if (upload.id === action.payload.id) {
                    upload.status = VideoUploadStatus.done;
                }
                return upload;
            });
            state.uploads = uploads;
        },
        resetUpload(state) {
            state.uploads = [];
        },
    },
});

export const getVideoUploads = (state: AppState) => state.managerScenarioUploads.uploads;

export const {
    actions: { addProgress, startUpload, finishUpload, resetUpload },
    reducer,
} = managerUploadsSlice;
