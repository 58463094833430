import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Rating } from '@mui/material';

export function StarInput(
    props: FieldRenderProps<string> & { label: string; max: number; options: { name: string; id: any }[] },
) {
    const { input } = props;
    return (
        <Rating
            max={props.max}
            name={input.name}
            sx={{
                padding: '0px',
                margin: '0px',
                marginLeft: '40px',
            }}
            value={parseInt(input.value)}
            onChange={(event) => {
                input.onChange(event);
            }}
        />
    );
}
