import React from 'react';
import { Typography, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function TermsAcceptedCheckbox(props: {
    accepted: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
    const { accepted, onChange } = props;
    const { t: getLabel } = useTranslation();
    return (
        <FormGroup row>
            <FormControlLabel
                control={
                    <Checkbox
                        id="terms-accepted-checkbox"
                        checked={accepted}
                        onChange={onChange}
                        value="areTermsAccepted"
                        inputProps={{
                            'aria-label': 'primary checkbox',
                        }}
                    />
                }
                label={
                    <>
                        <Typography display="inline">{getLabel('register.form.terms')}</Typography>
                        <a key={3} href="/privacy_policy" target="_blank" rel="noreferrer">
                            {getLabel('register.form.termsLinkWords')}
                        </a>
                    </>
                }
            />
        </FormGroup>
    );
}
