import React, { ReactNode } from 'react';
import { RouteObject } from 'react-router-dom';

export const applyRoutePage = (
    childrenRoutes: RouteObject[],
    WrapperComponent: React.FC<{ children: ReactNode }>,
    excludeRoutes: string[] = [],
): RouteObject[] =>
    childrenRoutes.map((route: RouteObject) => {
        if (!route.children) {
            if (excludeRoutes.includes(route.path as string)) {
                return route;
            }
            return {
                ...route,
                element: <WrapperComponent>{route.element}</WrapperComponent>,
            };
        }
        return {
            path: route.path,
            children: applyRoutePage(route.children, WrapperComponent, excludeRoutes),
        };
    });
