import { styled, Theme } from '@mui/material/styles';
import { getScrollbarStyles } from '@watched-tech/wantent-ui';

export const Background = styled('div')(({ theme }) => ({
    height: '100%',
    background: theme.palette.gradient.background,
    color: theme.palette.common.white,
}));

const getFixedContentMdHeight = (theme: Theme, isVirtualKeyboardShown: boolean) =>
    `calc(100% - ${theme.headerHeights.m}px - ${isVirtualKeyboardShown ? 0 : theme.footerHeights.mobile}px)`;

export const FixedHeightContent = styled('div', {
    shouldForwardProp: (prop: string) => !['showMobileView', 'isVirtualKeyboardShown'].includes(prop),
})<{
    isVirtualKeyboardShown: boolean;
    showMobileView: boolean;
}>(({ theme, isVirtualKeyboardShown, showMobileView }) => {
    const mdHeight = getFixedContentMdHeight(theme, isVirtualKeyboardShown);
    return {
        height: `calc(100% - ${theme.headerHeights.l}px - ${theme.footerHeights.l}px)`,
        padding: `0 ${theme.spacing(12)}`,
        overflow: 'auto',
        ...getScrollbarStyles(theme, 'transparent', theme.palette.gray.x7),
        [theme.breakpoints.down('lg')]: {
            height: showMobileView ? mdHeight : `calc(100% - ${theme.headerHeights.m}px - ${theme.footerHeights.m}px)`,
        },
        [theme.breakpoints.down('md')]: {
            height: mdHeight,
        },
    };
});
