import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { History } from 'history';
import { reducer as managerCreateScenarioReducer } from 'Pages/manager/scenarios/ManagerCreateScenarioPage/slice';
import { reducer as managerEditScenarioReducer } from 'Pages/manager/scenarios/ManagerEditScenarioPage/slice';
import { reducer as managerScenarioUploadsReducer } from 'Pages/manager/scenarios/slice';
import { reducer as managerTileRendererReducer } from 'Pages/manager/tile/slice';
import { stepperStatusSlice } from 'Features/participant/workspace/scenario/slice';
import { reducer as participantReducer } from 'Features/participant/slice';

export const createRootReducer = (history: History<any>) =>
    combineReducers({
        form: formReducer,
        stepperStatus: stepperStatusSlice.reducer,
        managerCreateScenario: managerCreateScenarioReducer,
        managerEditScenario: managerEditScenarioReducer,
        managerScenarioUploads: managerScenarioUploadsReducer,
        managerTileRenderer: managerTileRendererReducer,
        participant: participantReducer,
    });

export type AppState = ReturnType<ReturnType<typeof createRootReducer>>;
