import { styled } from '@mui/material';
import { SIDEBAR_WIDTH } from 'Features/participant/workspace/scenario/constants';

export const SidebarWrapper = styled('div')(({ theme }) => ({
    position: 'fixed',
    right: 0,
    left: 0,
    background: 'transparent',
    margin: 'auto',
    height: '100%',
    width: `calc(${theme.paperWidths['l']}px + ${SIDEBAR_WIDTH}px)`,
    pointerEvents: 'none',
    [theme.breakpoints.down('lg')]: {
        width: `calc(${theme.paperWidths['m']}px + ${SIDEBAR_WIDTH}px)`,
    },
}));

export const FlexWrapper = styled('div')(({ theme }) => ({
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
    },
}));
