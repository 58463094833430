import { styled } from '@mui/material';

export const VideoContainer = styled('div')<{ height?: number }>(({ theme: { breakpoints }, height }) => ({
    width: 1016,
    height,
    [breakpoints.down('lg')]: {
        width: 650,
    },
    [breakpoints.down('md')]: {
        width: '100%',
    },
}));
