import React, { useEffect, useRef, useState, useCallback, useImperativeHandle, FC } from 'react';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    getDirection,
    getCoords,
    fitToFullscreen,
    getDistance,
    getDirectionSign,
} from './utils/movePointAnimationUtils';
import { animationTypes, animationDuration, radius, padding, colors, circleColors } from './constants';
import { hhmmssms } from 'Utils/time';
import { ReactComponent as ExitFullscreen } from 'Assets/svg/exitFullscreen.svg';
import { FakeVideo } from '../FakeVideo';

const Wrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    background: '#000',
});

const Canvas = styled('canvas')({
    background: colors.darkestBlue,
});

const StyledExitFullscreen = styled(ExitFullscreen)(({ theme }) => ({
    width: 24,
    height: 24,
    color: theme.palette.gray.x9,
}));

enum CalibrationState {
    idle = 'idle',
    ended = 'ended',
    active = 'active',
}

interface ICoords {
    x: number;
    y: number;
}

export interface ICalibrationRequestData {
    x: number;
    y: number;
    timestamp: string;
    timestampMs: number;
    isStatic: boolean;
}

interface ICalibrationProps {
    onCalibrationStart?: () => void;
    onCalibrationInterrupted?: () => void;
    onCalibrationEnd?: (current: ICalibrationRequestData[], screen: { height: number; width: number }) => void;
    screen: { height: number; width: number };
    isAdaptive: boolean;
    nextVideoWidth?: number;
    nextVideoHeight?: number;
}

export interface CalibrationRefType {
    startCalibration: () => void;
}

export const Calibration: FC<ICalibrationProps> = ({
    onCalibrationStart,
    onCalibrationEnd,
    screen,
    isAdaptive,
    nextVideoWidth,
    nextVideoHeight,
    onCalibrationInterrupted,
}) => {
    const [isFakeVideoShown, setIsFakeVideoShown] = useState(true);
    const [calibrationState, setCalibrationState] = useState(CalibrationState.idle);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const adaptiveWidth = useRef<number | undefined>();

    const [ctx, setCtx] = useState<CanvasRenderingContext2D>();
    const [coords, setCoords] = useState<ICoords[]>([]);
    const beCoords = useRef<ICalibrationRequestData[]>([]);
    const coordsToCompare = useRef<ICoords[]>([]);

    const key = useRef<boolean>(false);
    const startTs = useRef(0);
    const beStartTs = useRef(0);
    const progress = useRef(0);
    const animationType = useRef(animationTypes.gradient);
    const currentIdx = useRef(0);
    const currentDistance = useRef({ x: 0, y: 0 });
    const animationFrameId = useRef<number>(0);

    useEffect(() => {
        if (nextVideoWidth && nextVideoHeight) {
            const nextVideoRatio = +(nextVideoWidth / nextVideoHeight).toFixed(2);
            adaptiveWidth.current = nextVideoRatio * screen.height;
        }
    }, [nextVideoWidth, nextVideoHeight, screen]);

    const handleFullScreenChange = useCallback(
        (e) => {
            if (calibrationState === CalibrationState.idle && canvasRef.current && document.fullscreenElement) {
                const { width, height } =
                    isAdaptive && nextVideoWidth && nextVideoHeight && adaptiveWidth.current
                        ? fitToFullscreen(canvasRef, { width: adaptiveWidth.current, height: screen.height })
                        : fitToFullscreen(canvasRef, screen);
                const coords = getCoords(width, height, padding);
                setCoords(coords);
                const ctx = canvasRef.current.getContext('2d');
                ctx && setCtx(ctx);
                setCalibrationState(CalibrationState.active);
                typeof onCalibrationStart === 'function' && onCalibrationStart();
            } else if (calibrationState == CalibrationState.active) {
                typeof onCalibrationInterrupted === 'function' && onCalibrationInterrupted();
            } else if (calibrationState == CalibrationState.ended) {
                if (isAdaptive && nextVideoWidth && nextVideoHeight) {
                    onCalibrationEnd &&
                        onCalibrationEnd(beCoords.current, {
                            width: adaptiveWidth.current!,
                            height: screen.height,
                        });
                    return;
                }
                typeof onCalibrationEnd === 'function' &&
                    onCalibrationEnd(beCoords.current, { width: screen.width, height: screen.height });
            }
        },
        [
            calibrationState,
            nextVideoWidth,
            nextVideoHeight,
            isAdaptive,
            screen,
            onCalibrationEnd,
            onCalibrationStart,
            coords,
        ],
    );

    const draw = useCallback(
        (x: number, y: number, progress: number) => {
            if (ctx && canvasRef.current) {
                ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                ctx.beginPath();
                ctx.arc(x, y, radius, 0, Math.PI * 2, false);
                if (animationType.current === animationTypes.gradient) {
                    const radialGradient = ctx.createRadialGradient(x, y, progress * radius, x, y, radius);
                    radialGradient.addColorStop(0.0, circleColors[currentIdx.current].inner);
                    radialGradient.addColorStop(1, circleColors[currentIdx.current].outer);
                    ctx.fillStyle = radialGradient;
                    ctx.fill();
                } else {
                    const radialGradient = ctx.createRadialGradient(x, y, 0, x, y, radius);
                    radialGradient.addColorStop(0.0, circleColors[currentIdx.current + 1].inner);
                    radialGradient.addColorStop(1, circleColors[currentIdx.current + 1].outer);
                    ctx.fillStyle = radialGradient;
                    ctx.fill();
                }
            }
        },
        [ctx],
    );

    const step = useCallback(
        (timestamp) => {
            if (!document.fullscreenElement || calibrationState !== CalibrationState.active) {
                return;
            }
            if (!beStartTs.current) {
                beStartTs.current = timestamp;
            }

            if (!startTs.current || progress.current > 1) {
                startTs.current = timestamp;
                if (parseInt(`${progress.current}`) === 1) {
                    const type =
                        animationType.current === animationTypes.movement
                            ? animationTypes.gradient
                            : animationTypes.movement;
                    animationType.current = type;
                    animationType.current = type;
                }
                if (progress.current > 1 && animationType.current === animationTypes.gradient) {
                    currentIdx.current += 1;
                    key.current = true;
                } else {
                    // key.current = false;
                }
            }

            if (coords[currentIdx.current + 1]) {
                currentDistance.current = getDistance(coords[currentIdx.current], coords[currentIdx.current + 1]);
                progress.current = (timestamp - startTs.current) / animationDuration;
                const direction = getDirection(coords[currentIdx.current], coords[currentIdx.current + 1]);
                const signs = getDirectionSign(direction);
                let x;
                let y;
                if (animationType.current === animationTypes.movement) {
                    x = coords[currentIdx.current].x + signs.x * progress.current * currentDistance.current.x;
                    y = coords[currentIdx.current].y + signs.y * progress.current * currentDistance.current.y;
                } else {
                    x = coords[currentIdx.current].x;
                    y = coords[currentIdx.current].y;
                }
                const currentTimestamp = timestamp - beStartTs.current;

                if (
                    currentTimestamp === 0 ||
                    Math.abs(currentTimestamp - beCoords.current[beCoords.current.length - 1]?.timestampMs) >= 100
                ) {
                    const isStatic = animationType.current === animationTypes.gradient;
                    const newBeCoord = {
                        x,
                        y,
                        timestamp: hhmmssms(currentTimestamp),
                        timestampMs: currentTimestamp,
                        isStatic: animationType.current === animationTypes.gradient,
                    };
                    // TODO: temporary solution. Investigate why x and y may be < 0
                    if (newBeCoord.x > 0 && newBeCoord.y > 0) beCoords.current = [...beCoords.current, newBeCoord];
                    const newCoordToCompare = { x, y };
                    if (key.current) {
                        coordsToCompare.current = [...coordsToCompare.current, newCoordToCompare];
                        key.current = false;
                    }
                }
                draw(x, y, progress.current);
                animationFrameId.current = requestAnimationFrame(step);
            } else {
                setCalibrationState(CalibrationState.ended);
                typeof onCalibrationEnd === 'function' &&
                    onCalibrationEnd(beCoords.current, { width: screen.width, height: screen.height });
                cancelAnimationFrame(animationFrameId.current);
            }
        },
        [coords, draw, onCalibrationEnd, screen.height, screen.width, calibrationState],
    );

    const startAnimation = useCallback(() => {
        requestAnimationFrame(step);
    }, [step]);

    useEffect(() => {
        if (ctx) {
            draw(coords[currentIdx.current].x, coords[currentIdx.current].y, 0);
            startAnimation();
        }
    }, [ctx, startAnimation, coords, draw]);
    useEffect(() => {
        if (coords.length) {
            currentDistance.current = getDistance(coords[currentIdx.current], coords[currentIdx.current + 1]);
        }
    }, [coords]);
    useEffect(() => {
        containerRef.current?.addEventListener('fullscreenchange', handleFullScreenChange);
    }, [handleFullScreenChange]);

    const requestFullscreen = () => {
        containerRef.current
            ?.requestFullscreen()
            .then(() => setIsFakeVideoShown(false))
            .catch((e) => {
                throw new Error(e);
            });
    };
    return (
        <>
            <Wrapper ref={containerRef}>
                {isFakeVideoShown ? (
                    <FakeVideo
                        isAdaptive={isAdaptive}
                        nextVideoWidth={nextVideoWidth}
                        nextVideoHeight={nextVideoHeight}
                        onClick={requestFullscreen}
                    />
                ) : (
                    <>
                        <Canvas ref={canvasRef}></Canvas>
                        <IconButton
                            onClick={() => document.exitFullscreen()}
                            size="small"
                            sx={{
                                position: 'fixed',
                                right: 0,
                                bottom: 0,
                                zIndex: 2,
                            }}
                        >
                            <StyledExitFullscreen />
                        </IconButton>
                    </>
                )}
            </Wrapper>
        </>
    );
};
Calibration.displayName = 'Calibration';
