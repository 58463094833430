import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as Logo } from 'Assets/svg/logo_long.svg';
import { useTranslation } from 'react-i18next';
import IndexNavBar from 'Features/manager/shared/components/layout/NavBar';

const ImageWrapper = styled('div')({
    position: 'relative',
});

const HomeWrapper = styled('div')({
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#1A1A25',
});
const Root = styled('div')({
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
});
const App = () => {
    const { t: getLabel } = useTranslation();
    return (
        <HomeWrapper>
            <Root>
                <IndexNavBar />
            </Root>
            <ImageWrapper>
                {/* eslint-disable */}
                <Logo width={600} />
                <Typography
                    variant="h5"
                    sx={{ position: 'absolute', bottom: 0, right: 0, fontWeight: 500, color: '#fff' }}
                >
                    {getLabel('general.testingPartner')}
                </Typography>
            </ImageWrapper>
        </HomeWrapper>
    );
};

export default App;
