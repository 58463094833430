import { apiClient } from 'Utils/axios';
import { QueryOptions, useUnauthenticatedQuery } from 'Shared/hooks/queries';

export const getIsPanelRespondentLoginAvailable = async (params: any) => {
    const url = `/api/users/panel_login?panelGroupId=${params.queryKey[1].panelGroupId}&panelRespondentId=${params.queryKey[1].panelRespondentId}`;
    await apiClient.get(url);
};

export const isPanelRespondentLoginAvailableQueryKey = 'isPanelRespondentLoginAvailable';

interface TemplateUrlPanelGroupRequirements {
    isUserExists: boolean;
    stateQueryParams: string[];
}

export const participantQueries = {
    useTemplateUrlPanelGroup: (
        id: string,
        panelRespondentId: string,
        options?: QueryOptions<TemplateUrlPanelGroupRequirements>,
    ) =>
        useUnauthenticatedQuery<TemplateUrlPanelGroupRequirements>(
            ['participant.getTemplateUrlPanelGroup', { id, panelRespondentId }],
            {
                url: (params) =>
                    `/api/users/tpg_requirements?panelGroupId=${params.queryKey[1].id}&panelParticipantId=${params.queryKey[1].panelRespondentId}`,
            },
            options,
        ),
};
