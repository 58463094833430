import { QuestionElementType } from 'Features/manager/shared/types';
import { SurveyQuestion } from 'Features/manager/shared/types';
import { ScreenerQuestion } from 'Features/manager/shared/queries';

export enum VideoType {
    youtube = 'youtube',
    videoContent = 'videoContent',
    watermarkedContent = 'watermarkedContent',
}

export interface ImageDetails {
    url: string;
    height: number;
    width: number;
}

export enum ElementType {
    info = 'info',
    plainTextQuestion = 'plainTextQuestion',
    pickOneQuestion = 'pickOneQuestion',
    numberQuestion = 'numberQuestion',
    pickManyQuestion = 'pickManyQuestion',
    radioPickOneQuestion = 'radioPickOneQuestion',
    ratingSliderQuestion = 'ratingSliderQuestion',
    starsQuestion = 'starsQuestion',
}
export interface ISurveyElement {
    id: string;
    position: number;
    type: ElementType;
    text: string;
    image?: ImageDetails;
}

export type CommonQuestion = ScreenerQuestion | SurveyElement;

export interface IQuestion extends ISurveyElement {
    type: QuestionElementType;
    imageUrl?: string;
}
export interface IQuestionWithConfig<TConfig> extends IQuestion {
    type: QuestionElementType;
    config: TConfig;
}

export interface IQuestionOption {
    id: number;
    position: number;
    isAnchored: boolean;
    text: string;
}

export interface IInfo extends ISurveyElement {
    type: ElementType.info;
}

export type SurveyElement = SurveyQuestion | IInfo;

export interface IMultipartUploadPart {
    partId: string;
    partNumber: number;
}
export interface IMultipartUpload {
    uploadId: string;
    filePath: string;
}

export enum Sex {
    // API-acceptable sex id -> Sex string description
    // NotKnown = "Unknown",
    Male = 'male',
    Female = 'female',
    NonBinary = 'nonBinary',
}
