import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'Src/reducers';
import { ScenarioStep, StepType } from 'Features/participant/workspace/scenario/types';
import { IScenarioResponse } from 'Features/participant/workspace/scenario/types';
import { ScenarioSessionState } from 'Features/participant/workspace/scenario/types';
import { Payload } from 'echarts';

export enum StepperStatus {
    idle = 'idle',
    webcamCheck = 'webcamCheck',
    loading = 'loading',
    createScenarioSession = 'createScenarioSession',
    createStepSession = 'createStepSession',
    ready = 'ready',
    nextStep = 'nextStep',
}
interface Ids {
    stepSessionId: string;
    scenarioSessionId: string;
    scenarioId: string;
}

type SidebarStepType = StepType & ('reactionUpload' | 'website' | 'feedback' | 'music');
export interface ISidebarStep {
    type: SidebarStepType;
    id: number;
    scenarioStep?: ScenarioStep;
}

interface IActionButton {
    label?: string;
    disabled?: boolean;
    onClick?: (props?: any) => void;
    onClicked?: (props?: any) => void;
}
export enum ScreenshareStatus {
    notStarted = 'notStarted',
    finished = 'finished',
}
interface IStepperState {
    currentStepIdx?: number;
    currentStep?: ISidebarStep;
    ids?: Ids;
    status: StepperStatus;
    scenario?: IScenarioResponse;
    surveyStep?: any;
    surveyErrorsCount: number;
    sidebarSteps: ISidebarStep[];
    preScenarioStepsCount: number;
    actionButton: IActionButton;
    screenshareStatus?: ScreenshareStatus;
}

const initialState: IStepperState = {
    status: StepperStatus.idle,
    surveyErrorsCount: 0,
    sidebarSteps: [],
    preScenarioStepsCount: 0,
    actionButton: {
        label: undefined,
        disabled: false,
        onClick: undefined,
        onClicked: undefined,
    },
};
export const stepperStatusSlice = createSlice({
    name: 'stepperStatus',
    initialState,
    reducers: {
        reset(state) {
            for (const key in state) {
                state[key] = initialState[key];
            }
        },
        startScenarioLoading(state) {
            state.status = StepperStatus.loading;
        },
        startScenarioSessionCreation(state) {
            state.status = StepperStatus.createScenarioSession;
        },
        startStepSessionCreation(state) {
            state.status = StepperStatus.createStepSession;
        },
        setIds(
            state,
            action: PayloadAction<{
                currentStepIdx: number;
                currentStep: ISidebarStep;
                scenario: IScenarioResponse;
                ids: Ids;
            }>,
        ) {
            state.ids = action.payload.ids;
            state.currentStep = action.payload.currentStep;
            state.currentStepIdx = action.payload.currentStepIdx;
            state.scenario = action.payload.scenario;
            state.status = StepperStatus.ready;
        },
        finishStep(state, action: PayloadAction<string | undefined>) {
            if (state.currentStepIdx === undefined) {
                throw new Error('current step is not defined');
            }
            if (action.payload === ScenarioSessionState.screenOut) {
                return;
            }
            state.currentStepIdx += 1;
            state.status = StepperStatus.nextStep;
            state.currentStep = undefined;
        },
        setWebcamCheck(state) {
            state.status = StepperStatus.webcamCheck;
        },
        setSurveyStep(state, action: PayloadAction<any[]>) {
            state.surveyStep = action.payload;
        },
        setSurveyErrorsCount(state, action: PayloadAction<number>) {
            state.surveyErrorsCount = action.payload;
        },
        setSidebarSteps(state, action: PayloadAction<{ sidebarSteps: ISidebarStep[]; preScenarioStepsCount: number }>) {
            state.sidebarSteps = action.payload.sidebarSteps;
            state.preScenarioStepsCount = action.payload.preScenarioStepsCount;
        },
        setActionButton(state, action: PayloadAction<IActionButton>) {
            state.actionButton = { ...state.actionButton, ...action.payload };
        },
        setScreenshareStatus(state, action: PayloadAction<ScreenshareStatus>) {
            state.screenshareStatus = action.payload;
        },
    },
});

export const selectStepperState = (state: AppState) => state.stepperStatus;
export const getStepperStatus = (state: AppState) => state.stepperStatus.status;
export const getCurrentStep = (state: AppState) => state.stepperStatus.currentStep;
export const getStepperIds = (state: AppState) => state.stepperStatus.ids;
export const getSurveyStep = (state: AppState) => state.stepperStatus.surveyStep;
export const getSurveyErrorsCount = (state: AppState) => state.stepperStatus.surveyErrorsCount;
export const getSidebarSteps = (state: AppState) => state.stepperStatus.sidebarSteps;
export const getScenario = (state: AppState) => state.stepperStatus.scenario;
export const getActionButton = (state: AppState) => state.stepperStatus.actionButton;
export const getScenarioSessionId = (state: AppState) => state.stepperStatus.ids?.scenarioSessionId;
export const getScreenshareStatus = (state: AppState) => state.stepperStatus.screenshareStatus;
export const { actions, reducer } = stepperStatusSlice;
export const {
    startScenarioLoading,
    startScenarioSessionCreation,
    startStepSessionCreation,
    setIds,
    finishStep,
    reset,
    setSurveyStep,
    setSurveyErrorsCount,
    setSidebarSteps,
    setActionButton,
    setScreenshareStatus,
    setWebcamCheck,
} = actions;
