export interface IDoNothingAction {
    actionType: 'doNothing';
}
export interface IIternalRedirectAction {
    actionType: 'redirectOverQuota' | 'redirectScreenOut';
}

export interface IRedirectToPanelAction {
    actionType: 'redirectToPanel';
    redirectUrl: string;
}

export type FollowingAction = IDoNothingAction | IRedirectToPanelAction | IIternalRedirectAction;

export function handleFollowingAction(res: FollowingAction) {
    switch (res.actionType) {
        case 'redirectOverQuota':
            return '/me/quota_full';
        case 'redirectScreenOut':
            return '/me/different_audience';
        case 'redirectToPanel':
            return `/me/redir?url=${encodeURIComponent(res.redirectUrl)}`;
        case 'doNothing':
            return '/me';
    }
}
