import React from 'react';
import { RouteObject } from 'react-router-dom';
import { AdminRoute, PageWithSuspence } from 'Shared/components/router';
import { AdminCreateUserPage, AdminManagersPage } from '../lazyPages';

const children = [
    {
        path: '',
        element: <AdminManagersPage />,
    },
    {
        path: 'create',
        element: <AdminCreateUserPage />,
    },
].map((route: RouteObject) => {
    return {
        ...route,
        element: (
            <AdminRoute>
                <PageWithSuspence>{route.element}</PageWithSuspence>
            </AdminRoute>
        ),
    };
});

export const adminRoutes = {
    path: 'admin/managers',
    children,
};
