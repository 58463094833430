import { AuthStrategy, setAuthScheme } from '../common';
import { Roles } from 'Shared/auth/common';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { getJwtPayload } from 'Shared/auth/common';
import { sendWorker } from 'Src';
import * as Sentry from '@sentry/react';

export class Auth0Strategy implements AuthStrategy {
    public role?: keyof typeof Roles;
    public userId?: string;
    public isLoading: boolean;

    constructor(private auth0: Auth0ContextInterface) {
        this.role = this.auth0.user?.role;
        this.userId = this.auth0.user?.sub;
        this.isLoading = this.auth0.isLoading;
    }

    getToken = async () => {
        return await this.auth0.getAccessTokenSilently();
    };

    login = (loc: string) => {
        this.auth0.loginWithRedirect({ appState: { returnTo: loc } });
    };

    logout = (loc: string) => {
        this.auth0.logout({ logoutParams: { returnTo: window.location.origin + loc } });
        setAuthScheme(undefined);
    };

    refreshTokenAndUpdateWorker = () => {
        function updateTokenInWorker(token: string) {
            const { sub } = getJwtPayload(token);
            sub && Sentry.setUser({ id: sub.replace('auth0|', '') });
            sendWorker.updateAccessToken(token);
        }

        const willExpireIn15Minutes = (token: any) => {
            const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
            return token.exp - currentTime <= 900; // 900 seconds = 15 minutes
        };

        try {
            this.auth0.getAccessTokenSilently().then((token) => {
                if (willExpireIn15Minutes(getJwtPayload(token))) {
                    this.auth0.getAccessTokenSilently({ cacheMode: 'off' }).then((newToken) => {
                        updateTokenInWorker(newToken);
                    });
                } else {
                    updateTokenInWorker(token);
                }
            });
        } catch (e) {
            console.error(e);
        }
    };
}
