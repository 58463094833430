import React, { FC, SyntheticEvent, useCallback } from 'react';
import { Typography, SearchInput, useWantentTheme } from '@watched-tech/wantent-ui';
import { Select } from '@watched-tech/wantent-ui';
import { Filters } from './styles';
import { IDesktopFiltersProps } from './types';
import { DASHBOARD_SORT_OPTIONS } from 'Features/participant/workspace/dashboard/constants';
import { useTranslation } from 'react-i18next';

export const DesktopFilters: FC<IDesktopFiltersProps> = ({
    scenarios,
    searchValue,
    onSearchChange,
    sortValue,
    onSortChange,
}) => {
    const theme = useWantentTheme();
    const { t: getLabel } = useTranslation();

    const onSelectChange = useCallback(
        (event: SyntheticEvent, value: string | number) => {
            onSortChange(value as string);
        },
        [onSortChange],
    );
    return (
        <>
            <Typography
                variant="b1"
                color="textPrimary"
                sx={{
                    padding: `${theme.spacing(4)} 0`,
                }}
                dataAttrs={{ 'data-testid': 'dashboard-all-projects' }}
            >
                {getLabel('participant.scenarios.allProjects')}
            </Typography>
            {scenarios ? (
                <Filters>
                    <div data-testid="dashboard-desktop-search-and-sort">
                        <SearchInput
                            label={getLabel('participant.scenarios.search.label')}
                            placeholder={getLabel('participant.scenarios.search.placeholder')}
                            value={searchValue}
                            onChange={onSearchChange}
                            withAnimation
                        />
                    </div>
                    <Select
                        label={getLabel('participant.scenarios.sort.by')}
                        value={sortValue}
                        onChange={onSelectChange}
                        id="scenarios-list-sort"
                        selectType="secondary"
                        options={DASHBOARD_SORT_OPTIONS.map((option) => ({
                            ...option,
                            label: getLabel(`participant.scenarios.sort.options.${option.value}`),
                        }))}
                    />
                </Filters>
            ) : null}
        </>
    );
};
