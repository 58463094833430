import React, { useEffect } from 'react';
import {
    IScenarioResponse,
    QuestionSelectedOption,
    ScenarioSessionState,
} from 'Features/participant/workspace/scenario/types';
import {
    ParticipantSurveyFormHandles,
    ParticipantSurveyForm,
} from 'Features/participant/workspace/scenario/components/Survey/ParticipantSurveyForm';
import { Typography } from '@watched-tech/wantent-ui';
import { ScenarioPaper } from 'Src/features/participant/workspace/scenario/components/shared/ScenarioPaper';
import { useActionButton } from 'Features/participant/workspace/scenario/hooks';
import { sleep } from 'Utils/promise';
import { participantMutations } from 'Features/participant/workspace/scenario/queries';
import { QuestionOptions } from 'Shared/models/QuestionOptions';
import { useDispatch, useSelector } from 'react-redux';
import { finishStep, reset as resetStepper, selectStepperState } from 'Features/participant/workspace/scenario/slice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ScreenerProps {
    scenario: IScenarioResponse;
}

export const Screener = (props: ScreenerProps) => {
    const actionButton = useActionButton();
    const state = useSelector(selectStepperState);
    const surveyFormRef = React.useRef<ParticipantSurveyFormHandles>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t: getLabel } = useTranslation();
    const { mutateAsync: putResponse } = participantMutations.usePutScreenerResponse();
    const { mutate: finishSession } = participantMutations.useFinishScenarioSession({
        onSuccess: (res) => {
            switch (res.actionType) {
                case 'redirectToPanel':
                    navigate(`/me/redir?url=${encodeURIComponent(res.redirectUrl)}`);
                    break;
                case 'doNothing':
                    break;
            }
            dispatch(resetStepper());
        },
    });
    const handleSubmittingChange = (isSubmitting: boolean) => {
        actionButton.setDisabled(isSubmitting);
    };

    const handleSubmit = async (values: Record<string, string> | Record<string, string[]>) => {
        if (!state.ids?.scenarioSessionId) {
            throw new Error('Scenario session is not properly initialized');
        }
        const valuesKeys = Object.keys(values);
        const questionAnswers = valuesKeys.filter((value) => value !== 'surveyId');

        const body = {
            scenarioSessionId: state.ids.scenarioSessionId,
            screenerAnswers: questionAnswers.reduce((acc, questionId) => {
                const optionIds = values[questionId];
                if (Array.isArray(optionIds)) {
                    optionIds.forEach((answer) => {
                        acc.push({
                            screenerQuestionId: questionId,
                            optionId: parseInt(answer),
                        });
                    });
                } else {
                    acc.push({
                        screenerQuestionId: questionId,
                        optionId: parseInt(optionIds),
                    });
                }
                return acc;
            }, [] as QuestionSelectedOption[]),
        };
        const sessionState = await putResponse({ body });
        dispatch(finishStep(sessionState));
        switch (sessionState) {
            case ScenarioSessionState.started:
            case ScenarioSessionState.finished:
                return;
            case ScenarioSessionState.quotaFull:
                finishSession({ urlParams: { id: state.ids.scenarioSessionId } });
                navigate('/me/quota_full');
                return;
            case ScenarioSessionState.screenOut:
                finishSession({ urlParams: { id: state.ids.scenarioSessionId } });
                navigate('/me/different-audience');
                return;
        }
    };

    useEffect(() => {
        actionButton.setOnClick(() => {
            surveyFormRef.current?.scrollToActiveError();
        });
    }, [surveyFormRef.current]);
    return (
        <ScenarioPaper title={getLabel('participant.survey.title')}>
            <ParticipantSurveyForm
                ref={surveyFormRef}
                surveyElements={props.scenario.screenerQuestions}
                onSubmittingChange={handleSubmittingChange}
                onSubmit={handleSubmit}
            />
        </ScenarioPaper>
    );
};
