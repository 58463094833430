import { UiLocale } from 'Src/localizations/i18n';

export enum DataCollectionMethodType {
    focusGroup = 'focusGroup',
    tiburonPanelGroup = 'tiburonPanelGroup',
    cintPanelGroup = 'cintPanelGroup',
    openGroup = 'openGroup',
    templateUrlPanelGroup = 'templateUrlPanelGroup',
}

export interface IDataCollectionMethod {
    id?: string;
    preferredLocale: UiLocale;
    title: string;
    type: DataCollectionMethodType;
}

export interface IFocusGroupDcm extends IDataCollectionMethod {
    type: DataCollectionMethodType.focusGroup;
    assignedFocusGroupId: string;
}
export interface ITemplateUrlPanelGroupDcm extends IDataCollectionMethod {
    type: DataCollectionMethodType.templateUrlPanelGroup;
    panelConfig: {
        stateQueryParams?: { incomingKey: string; placeholderKey: string }[];
        overQuotaUrl: string;
        screenOutUrl: string;
        completedUrl: string;
    };
}

export interface ITiburonPanelGroupDcm extends IDataCollectionMethod {
    type: DataCollectionMethodType.tiburonPanelGroup;
    panelConfig: {
        projectId: string;
        stateQueryParams?: { incomingKey: string; placeholderKey: string }[];
        overQuotaStatus: string;
        screenOutStatus: string;
        completedStatus: string;
    };
}

export interface ICintPanelGroupDcm extends IDataCollectionMethod {
    type: DataCollectionMethodType.cintPanelGroup;
    participantCount: number;
}
export interface IOpenGroupDcm extends IDataCollectionMethod {
    type: DataCollectionMethodType.openGroup;
    participantCount: number;
}

export type DataCollectionMethod =
    | IFocusGroupDcm
    | ITemplateUrlPanelGroupDcm
    | IOpenGroupDcm
    | ITiburonPanelGroupDcm
    | ICintPanelGroupDcm;
